<template>
  

<TheHeader/>

  <router-view></router-view>
  

<TheFooter/>
</template>

<script>
import TheHeader from '../components/layout/TheHeader'
import TheFooter from '../components/layout/TheFooter'

export default {
  components:{
    TheHeader,
    TheFooter,
  }
}

</script>
