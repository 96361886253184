<template>
  <div v-if="homeContent != null" class="content pt-0 mt-0 pb-0">
    <div
      class="bg"
      :style="
        'background: url(' +
        homeContent.banner.photo +
        ');background-size: cover;background-position:top ;'
      "
    >
      <img
        src="/static/images/HeaderShape.png"
        style="position: absolute; margin-top: -100px"
      />

      <div class="container-xxl header-cont">
        <div class="Header-content">
          <span class="home-header-text" style="font-size: 32px">{{
            homeContent.banner.title
          }}</span
          ><br /><br />
          <span class="home-header-text-sm" style="font-size: 22px">
            {{ homeContent.banner.subtitle }}<br /><br
          /></span>
          <div style="display: flex; padding: 6px">
            <button
              type="button "
              class="Btn-2 btn order-btn"
              @click="navigateto('/quote')"
            >
              <img src="/static/images/gq2.png" class="btnico" />
              <div class="Btnicotxt">{{ content.getQuote }}</div>
            </button>
            <button
              type="button "
              class="Btn-2 btn order-btn"
              data-toggle="modal"
              data-target="#PlaceOrder"
            >
              <img src="/static/images/po.png" class="btnico" />
              <div class="Btnicotxt">{{ content.placeOrder }}</div>
            </button>
          </div>
        </div>
      </div>
    </div>

    <section class="container-xxl" v-if="servicesContent != null">
      <h3
        class="pt-5 pb-4 mt-2 page-heading"
        style="text-align: center; color: #666"
      >
        {{ content.ourServices }}
      </h3>
      <div class="services-grid">
        <router-link to="/services/1">
          <div class="service">
            <img src="/static/images/Doct.png" />
            <h4>{{ content.translation }}</h4>
            <p class="service-btn-text">
              {{ servicesContent[0].summary }}
            </p>
          </div>
        </router-link>
        <router-link to="/services/2">
          <div class="service">
            <img src="/static/images/inter1.png" />
            <h4>{{ content.interpretation }}</h4>
            <p class="service-btn-text">
              {{ servicesContent[1].summary }}
            </p>
          </div>
        </router-link>
        <router-link to="/services/3">
          <div class="service">
            <img src="/static/images/ProofIco.png" />
            <h4>{{ content.proofreading }}</h4>
            <p class="service-btn-text">
              {{ servicesContent[2].summary }}
            </p>
          </div>
        </router-link>
      </div>
    </section>

    <div
      class="home-pricing-image"
      :style="
        'background-image: url(' +
        homeContent.pricing.photo +
        '); min-height: 500px;width: 100%;background-size: cover;background-position: left;margin-top:70px;background-attachment: fixed;'
      "
    >
      <div class="container-xxl">
        <div class="row">
          <div class="col-lg-6 white-bg">
            <h2
              class="color-light-dark"
              style="color: #fff; padding-top: 120px"
            >
              {{ homeContent.pricing.title }}
            </h2>
            <p class="color-light-dark" style="color: #fff; padding-top: 25px">
              {{ homeContent.pricing.content }}
            </p>
            <button
              class="color-light-dark btn btn-outline-light dark-border"
              style="margin-top: 20px; height: 40px"
              @click="navigateto('/pricing')"
            >
              {{ content.viewPricing }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div style="background: #2a6049; overflow: hidden; margin-bottom: 20px">
      <div
        class="container-xxl mobile-alignment"
        style="
          display: flex;
          align-items: baseline;
          justify-content: space-between;
          flex-wrap: wrap;
        "
      >
        <h5 style="color: #fff; line-height: 100px">
          {{ content.planAndPrice }}
        </h5>
        <button
          class="btn btn-light large-btn"
          style="float: right; align-items: center; margin: 10px"
          @click="navigateto('/quote')"
        >
          {{ content.getInstantQuote }}
        </button>
      </div>
    </div>

    <div class="container" style="padding: 40px">
      <div class="row">
        <div class="col-12 mb-5">
          <h1 style="text-align: center">{{ content.ourQualities }}</h1>
        </div>
        <div class="col-sm-12 col-lg-4 mb-5 d-flex">
          <div style="font-size: 24px">
            <img :src="homeContent.qualities[0].photo" class="qualityIcons" />
          </div>
          <div>
            <span class="clearfix" style="font-size: 24px; margin-bottom: 10px"
              >{{ homeContent.qualities[0].title }}
            </span>
            {{ homeContent.qualities[0].content }}
          </div>
        </div>

        <div class="col-sm-12 col-lg-4 mb-5 d-flex">
          <div style="font-size: 24px">
            <img :src="homeContent.qualities[1].photo" class="qualityIcons" />
          </div>
          <div>
            <span class="clearfix" style="font-size: 24px; margin-bottom: 10px"
              >{{ homeContent.qualities[1].title }}
            </span>
            {{ homeContent.qualities[1].content }}
          </div>
        </div>

        <div class="col-sm-12 col-lg-4 mb-5 d-flex">
          <div style="font-size: 24px">
            <img :src="homeContent.qualities[2].photo" class="qualityIcons" />
          </div>
          <div>
            <span class="clearfix" style="font-size: 24px; margin-bottom: 10px"
              >{{ homeContent.qualities[2].title }}
            </span>
            {{ homeContent.qualities[2].content }}
          </div>
        </div>
      </div>
    </div>

    <div
      :style="
        'background-image: url(' +
        homeContent.join.photo +
        '); min-height: 600px;width: 100%;background-size: cover;background-position: left;margin-top:0;background-attachment: fixed;'
      "
    >
      <div class="container-xxl">
        <div class="row">
          <div class="col-lg-7"></div>
          <div class="col-lg-4 white-bg">
            <h1 style="padding-top: 150px">{{ homeContent.join.title }}</h1>
            <p style="padding-top: 25px">
              {{ homeContent.join.content }}
            </p>
            <button
              class="btn btn-success mt-4 large-btn"
              @click="navigateto('/joinus')"
            >
              {{ content.submitApplication }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <loader v-else></loader>
</template>

<script>
import Loader from "../../components/ui/Loading.vue";
export default {
  components: {
    Loader,
  },

  data() {
    return {
      homeContent: null,
      servicesContent: null,
    };
  },
  methods: {
    navigateto(path) {
      this.$router.push(path);
    },
    async loadData() {
      await this.$store.dispatch("getHomeData");
      this.homeContent = await this.$store.getters.homeContent;
      await this.$store.dispatch("getServicesData");
      this.servicesContent = await this.$store.getters.servicesContent.services;
    },
  },
  created() {
    this.loadData();
  },
  computed: {
    content() {
      this.loadData();
      return this.$store.getters.content;
    },
  },
};
</script>

<style scoped>
.header-cont {
  margin-top: 120px;
  vertical-align: middle;
  position: relative;
}
@media (max-width: 992px) {
  .white-bg {
    background: #fff !important;
    margin-top: 300px;
    padding-bottom: 50px;
  }
  .white-bg h1 {
    padding-top: 50px !important;
  }
  .header-cont {
    margin-top: 80px;
  }
}
.service-btn-text {
  max-width: 350px;
  font-size: 12px;
  height: 60px;
  overflow: hidden;
  white-space: pre-wrap;
  text-overflow: ellipsis;
}
</style>
