<template>
  <div
    style="
      margin: 150px 0px 0px 0px !important;
      color: #2a6049;
      font-size: 40px;
      font-weight: 600;
    "
  >
    {{ title }}
  </div>
</template>

<script>
export default {
  props: ["title", "desc"],
};
</script>

<style scoped>
div {
  text-transform: uppercase;
}

@media screen and (max-width: 760px) {
  div {
    font-size: 32px !important;
  }
}
</style>