<template>
  <PaymentResult
    :main="content.paymentSuccessPrimary"
    :text-one="secondaryText"
  />
</template>

<script>
import PaymentResult from "../../components/layout/PaymentResult.vue";

export default {
  components: { PaymentResult },
  props: ["type"],
  computed: {
    content() {
      return this.$store.getters.content;
    },
    secondaryText() {
      return this.type == "bundle"
        ? this.content.bundlePurchaseSuccess
        : this.type == "order"
        ? this.content.orderPaymentSuccess
        : "";
    },
  },
};
</script>