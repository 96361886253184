<template>
    <div class="container shadow" >
        
    <form class="row" @submit.prevent="submitForm">
           <div class="d-block w-100">
         <div v-if="updateSuccess == false" class="well well-failed">
        <div>
          <h3>{{ content.failed }}</h3>
          <p>
            {{ content.accountUpdateFailed }}
          </p>
        </div>
      </div>
      <div v-if="updateSuccess == true" class="well well-success">
        <div>
          <h3>{{ content.success }}</h3>
          <p>
            {{ content.accountUpdateSuccess }}
            <button class="btn link" @click="dismiss">Dismiss</button>
          </p>
        </div>
      </div>
           </div>
    <h3 class="mb-3" style="padding:20px 15px">{{content.updateProfile}}</h3>
     <div class="col-12 col-sm-6">
             <div class="input-group mb-3">
              <label class="small d-block"  >{{content.firstName}}</label> <div class="clearfix"></div>
              <input type="text"  class="form-control d-block"   autocomplete="off" v-model.trim="firstname">
             <div class="clearfix m-0"></div>
            <p v-if="firstNameError" class="error-text">{{ firstNameError }}</p>
             </div>
          </div>  
              <div class="col-12 col-sm-6">
             <div class="input-group mb-3">
              <label class="small d-block"  >{{content.lastName}}</label> <div class="clearfix"></div>
              <input type="text"  class="form-control d-block"   autocomplete="off" v-model.trim="lastname">
             <div class="clearfix m-0"></div>
            <p v-if="lastNameError" class="error-text">{{ lastNameError }}</p>
             </div>
          </div>  
              <div class="col-12 col-sm-6">
             <div class="input-group mb-3">
              <label class="small d-block"  >{{content.companyName}}</label> <div class="clearfix"></div>
              <input type="text"  class="form-control d-block"   autocomplete="off" v-model.trim="company">
            
             </div>
          </div>  
              <div class="col-12 col-sm-6">
             <div class="input-group mb-3">
              <label class="small d-block"  >{{content.email}}</label> <div class="clearfix"></div>
              <input type="email"  class="form-control d-block"   autocomplete="off" v-model.trim="email">
            <div class="clearfix m-0"></div>
            <p v-if="emailError" class="error-text">{{ emailError }}</p>
             </div>
          </div>  
              <div class="col-12 col-sm-6">
             <div class="input-group mb-3">
              <label class="small d-block"  >{{content.country}}</label> <div class="clearfix"></div>
              <input type="text"  class="form-control d-block"   autocomplete="off" v-model.trim="country">
              <div class="clearfix m-0"></div>
            <p v-if="countryError" class="error-text">{{ countryError }}</p>
             </div>
          </div>  
              <div class="col-12 col-sm-6">
             <div class="input-group mb-3">
              <label class="small d-block"  >{{content.city}}</label> <div class="clearfix"></div>
              <input type="text"  class="form-control d-block"   autocomplete="off" v-model.trim="city">
              <div class="clearfix m-0"></div>
            <p v-if="cityError" class="error-text">{{ cityError }}</p>
             </div>
          </div>  
           <div class="col-12 col-sm-6">
             <div class="input-group mb-3">
              <label class="small d-block"  >{{content.phone}}</label> <div class="clearfix"></div>
              <input type="number"  class="form-control d-block"   autocomplete="off" v-model.trim="phone">
              <div class="clearfix m-0"></div>
            <p v-if="phoneError" class="error-text">{{ phoneError }}</p>
             </div>
          </div> 
           <div class="col-12 col-sm-6">
             <div class="input-group mb-3">
              <label class="small d-block"  >{{content.address}}</label> <div class="clearfix"></div>
              <input type="text"  class="form-control d-block"   autocomplete="off" v-model="address">
             
            
             </div>
          </div> 
          
                <div style="text-align: right;padding: 40px 0px;display:flex;justify-content: flex-end;">
                    <button class="form-green-btn " type="submit">
                        <Spinner v-if="submitted" />
              <span v-else>
                        {{content.saveChanges}}
              </span>
                        </button>
            </div>
    </form>
    </div>
    
</template>
<script>
import Spinner from "../../components/ui/Spinner.vue";

export default {
    components:{
        Spinner
    },
    data(){
        return{
    
      firstname: null,
      lastname: null,
      company: null,
      email: null,
      country: null,
      city: null,
      phone: null,
      firstNameError: null,
      lastNameError: null,
      emailError: null,
      phoneError: null,
      countryError: null,
      cityError: null,
    
      submitted: false,
      response: null,
      updateSuccess: null,
      user:null,
        }
    },
    methods:{
 validateEmail() {
      this.emailError = null;
      if (this.email === "" || this.email == null) {
        this.emailError = this.content.required;
      } else if (!this.email.includes("@")) {
        this.emailError = this.content.emailFormat;
      }
    },
    validatePhone() {
      this.phoneError = null;
      if (this.phone === "" || this.phone == null) {
        this.phoneError = this.content.required;
      } else if (this.phone.length < 11) {
        this.phoneError = this.content.phoneFormat;
      }
    },
     validateRequired(inputed) {
      if (inputed === "" || inputed == null) {
        return this.content.required;
      } else {
        return null;
      }
    },
     async submitForm() {
      this.submitted = true;
      this.validateEmail();
      this.validatePhone();
      this.firstNameError = this.validateRequired(this.firstname);
      this.lastNameError = this.validateRequired(this.lastname);
      this.cityError = this.validateRequired(this.city);
      this.countryError = this.validateRequired(this.country);

      if (
        !this.firstNameError &&
        !this.lastNameError &&
        !this.phoneError &&
        !this.emailError &&
        !this.cityError &&
        !this.countryError
      ) {
        this.response = await this.$store.dispatch("updateProfile", {
          first_name: this.firstname,
          last_name: this.lastname,
          phone: this.phone,
          email: this.email,
          city: this.city,
          country: this.country,
          address: this.address,
          company:this.company??' ',
        });
        

        if (this.response.user) {
          this.submitted = false;
          this.updateSuccess = true;
             this.$store.dispatch('checkToken');


        } else if (this.response.errors) {
          window.scrollTo(0, 0);
          this.updateSuccess = false;
          this.submitted = false;

          if (this.response.errors.phone) {
            this.phoneError = this.response.errors.phone[0];
          }
          if (this.response.errors.email) {
            this.emailError = this.response.errors.email[0];
          }
        }
      } else {
        this.submitted = false;
      }
    },
     dismiss(){
this.updateSuccess= null;
    },
    },
     computed:{
  content(){
    return this.$store.getters.content;
  },
  

},
  beforeMount(){
    this.user= this.$store.getters.user;
   this.firstname =this.user.firstName;
   this.lastname = this.user.lastName;
   this.phone = this.user.phone;
   this.email = this.user.email;
   this.country = this.user.country;
   this.city = this.user.city;
   this.address = this.user.address;
   this.company = this.user.company;
  
  },
  


}
</script>
<style scoped>
.container{
    border-radius: 15px;
    background: #fffd;
}
</style>