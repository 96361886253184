<template>
     <span class="sppinner">
              <div class="spp spp-3balls"></div>
              </span>
</template>
<style  scoped>


.spp {
	width: 32px;
	height: 32px;
	clear: both;
	margin: 15px 0 0 0 ;
 
}
.sppinner{
  padding:0 25px;
  overflow: hidden;
  position: relative;
}
/* Spinner 3Balls Scale */
.spp-3balls, .spp-3balls:before, .spp-3balls:after {
  border-radius: 50%;
  background-color: #198754  !important;
  width: 10px;
  height: 10px;
  transform-origin: center center;
  display: inline-block;
}

.spp-3balls {
  position: relative;
  background-color: #fff;
  opacity: 1;
  -webkit-animation: spScaleAlpha 1s infinite linear;
  animation: spScaleAlpha 1s infinite linear;
  
}

.spp-3balls:before, .spp-3balls:after {
  content: "";
  position: relative;
  opacity: 0.25;
}

.spp-3balls:before {
  left: 20px;
  top: -10px;
  -webkit-animation: spScaleAlphaBefore 1s infinite linear;
  animation: spScaleAlphaBefore 1s infinite linear;
}

.spp-3balls:after {
  left: -20px;
  top: -40px;
  -webkit-animation: sppScaleAlphaAfter 1s infinite linear;
  animation: sppScaleAlphaAfter 1s infinite linear;
}

@-webkit-keyframes sppScaleAlpha {
  0% {
    opacity: 1;
  }
  33% {
    opacity: 0.25;
  }
  66% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
  }
}
@keyframes sppScaleAlpha {
  0% {
    opacity: 1;
  }
  33% {
    opacity: 0.25;
  }
  66% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes sppScaleAlphaBefore {
  0% {
    opacity: 0.25;
  }
  33% {
    opacity: 1;
  }
  66% {
    opacity: 0.25;
  }
}
@keyframes sppScaleAlphaBefore {
  0% {
    opacity: 0.25;
  }
  33% {
    opacity: 1;
  }
  66% {
    opacity: 0.25;
  }
}
@-webkit-keyframes sppScaleAlphaAfter {
  33% {
    opacity: 0.25;
  }
  66% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
}
@keyframes sppScaleAlphaAfter {
  33% {
    opacity: 0.25;
  }
  66% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
}
</style>