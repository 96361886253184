<template>
  <div
    class="pb-5 header-background"
    style="background: url(/static/images/order-bg.jpg)"
  >
    <div class="container" style="max-width: 1000px; padding: 0">
      <form @submit.prevent="">
        <div class="row flex-center">
          <page-title
            title="Place New Order"
            class="d-flex justify-content-center"
          ></page-title>
          <div
            class="page-heading"
            style="font-size: 24px; margin-top: 20px; margin-bottom: 80px"
          >
            {{ content.legalTranslation }}
          </div>

          <div class="shadow qoute-form mb-5 p-0 pb-5">
            <div style="display: flex">
              <button
                type="button"
                class="stepper"
                style="border-radius: 25px 0px 0px 0px"
              >
                <div :class="step1 ? 'active stepper-icon' : 'stepper-icon'">
                  1
                </div>
                {{ content.imageScanDetails }}
              </button>

              <button
                type="button"
                class="stepper"
                style="border-radius: 0px 25px 0px 0px"
              >
                <div :class="step3 ? 'active stepper-icon' : 'stepper-icon'">
                  2
                </div>
                {{ content.payment }}
              </button>
            </div>
            <div v-if="isAuth">
              <div class="container d-block mt-4" v-if="step1">
                <div class="prices p-3" @click="$router.push('/pricing/2')">
                  {{ content.viewPrices }}
                </div>
                <div
                  class="col-md-12 mb-5 p-3"
                  style="color: #363636; font-size: 20px"
                >
                  {{ content.step1 }} {{ content.addImageScanDetails }}
                </div>

                <div class="row m-0">
                  <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                    <div class="input-group mb-3">
                      <div
                        class="d-flex w-100"
                        style="justify-content: space-between"
                      >
                        <label class="small d-block" style="float: right">{{
                          content.chooseImagesScans
                        }}</label>
                        <label
                          class="small d-block"
                          style="
                            color: #2a6049;
                            font-weight: 500;
                            cursor: pointer;
                          "
                          >{{ content.supportedFiles }}( Image, Scan )</label
                        >
                      </div>
                      <div class="clearfix"></div>
                      <div class="d-block w-100">
                        <input
                          type="file"
                          class="form-control d-block"
                          style="font-size: 14px; padding: 2px 5px"
                          accept=".png ,.jpg ,.pdf"
                          id="x"
                          @change="getFile"
                        />
                        <p v-if="file" class="alert alert-success mt-3">
                          {{ content.uploadedFile }}<b>{{ file.name }}</b>
                        </p>

                        <div class="error-text">
                          {{ fileError }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                    <div class="input-group mb-3">
                      <label class="small d-block">{{
                        content.chooseLegalDocumentType
                      }}</label>
                      <div class="clearfix"></div>
                      <select class="form-select" v-model="documentType">
                        <option
                          v-for="documentType in legalTranslationPrices"
                          :key="documentType.id"
                          :value="documentType"
                        >
                          {{ documentType.type }} ( ${{ documentType.price }} )
                        </option>
                      </select>
                      <div class="clearfix"></div>

                      <div class="error-text">{{ documentTypeError }}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                    <div class="input-group mb-3">
                      <label class="small d-block">{{
                        content.sourceLanguage
                      }}</label>
                      <div class="clearfix"></div>
                      <select class="form-select" v-model="source" required>
                        <option value="1">{{ content.english }}</option>
                        <option value="2">{{ content.arabic }}</option>
                        <option value="3">{{ content.kurdish }}</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                    <div class="input-group mb-3">
                      <label class="small d-block">{{
                        content.targetLanguage
                      }}</label>
                      <div class="clearfix"></div>
                      <select class="form-select" v-model="target" required>
                        <option value="1">{{ content.english }}</option>
                        <option value="2">{{ content.arabic }}</option>
                        <option value="3">{{ content.kurdish }}</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                    <div class="input-group mb-3">
                      <label class="small d-block">
                        {{ content.YourDeadline }}</label
                      >
                      <div class="clearfix"></div>
                      <input
                        type="date"
                        class="form-control"
                        v-model="date"
                        :min="minDate"
                        required
                      />
                      <div class="clearfix"></div>
                      <div class="error-text">{{ deadlineError }}</div>
                    </div>
                  </div>

                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                    <div class="input-group mb-3">
                      <div class="input-group mb-3">
                        <label class="small d-block">{{
                          content.needStamped
                        }}</label>
                        <div class="clearfix"></div>
                        <select class="form-select" v-model="stamp">
                          <option value="1">{{ content.yes }}</option>
                          <option value="0">{{ content.no }}</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div v-if="stamp == 1" class="col-md-6 col-sm-12 col-12">
                    <div class="input-group mb-3">
                      <label class="small d-block">
                        {{ content.physicalDelivery }}</label
                      >
                      <div class="clearfix"></div>
                      <select class="form-select" v-model="delivery">
                        <option value="1">{{ content.yes }}</option>
                        <option value="0">{{ content.no }}</option>
                      </select>
                    </div>
                  </div>

                  <div v-if="delivery == 1 && stamp == 1" class="col-12 p-0">
                    <hr class="mt-3" />
                    <h6 class="mb-4 d-none">
                      {{ content.physicalCollection }}
                    </h6>
                    <div
                      class="row mt-1 pr-0"
                      style="padding-right: 0"
                      v-if="delivery == 1 && stamp == 1"
                    >
                      <div class="col-md-6 col-sm-12">
                        <div class="input-group mb-3">
                          <label class="small d-block">
                            {{ content.contactName }}</label
                          >
                          <div class="clearfix"></div>
                          <input
                            type="text"
                            class="form-control"
                            v-model="contactName"
                          />
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <div class="input-group mb-3">
                          <label class="small d-block">
                            {{ content.phone }}
                          </label>
                          <div class="clearfix"></div>
                          <input
                            type="text"
                            class="form-control"
                            v-model="contactPhone"
                          />
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <div class="input-group mb-3">
                          <label class="small d-block">
                            {{ content.email }}
                          </label>
                          <div class="clearfix"></div>
                          <input
                            type="text"
                            class="form-control"
                            v-model="contactEmail"
                          />
                        </div>
                      </div>

                      <div class="col-md-6 col-sm-12">
                        <label class="small d-block">
                          {{ content.city }}
                        </label>
                        <select class="form-select" v-model="city">
                          <option
                            v-for="price in deliveryPrice"
                            :key="price"
                            :value="price"
                          >
                            {{ price.name }} ( $ {{ price.price }} )
                          </option>
                        </select>
                        <div class="error-text">
                          {{ cityError }}
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <div class="input-group mb-3">
                          <label class="small d-block">
                            {{ content.address }}
                          </label>
                          <div class="clearfix"></div>
                          <input
                            type="text"
                            class="form-control"
                            v-model="contactAddress"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12">
                    <button
                      type="button"
                      class="btn green-btn mt-4"
                      @click.prevent="toStep3"
                    >
                      {{ content.next }}
                    </button>
                  </div>
                </div>
              </div>

              <div class="container d-block mt-4" v-if="step3">
                <div class="row">
                  <div
                    class="col mb-5 mt-1"
                    style="color: #363636; font-size: 20px"
                  >
                    {{ content.step3 }} {{ content.orderSummery }}
                  </div>
                  <div class="col" style="text-align: right">
                    <button
                      type="button"
                      class="btn btn-outline-success"
                      @click="print()"
                    >
                      {{ content.printInvoice }}
                    </button>
                  </div>

                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="col-12 col-md-4">
                      <label class="small d-block">
                        {{ content.LegalDocumentType }}
                      </label>
                      <div class="clearfix"></div>
                      <p>{{ documentType.type }}</p>
                    </div>

                    <div
                      class="col-12 col-md-4 left-in-sm"
                      style="text-align: center"
                    >
                      <label class="small d-block">{{
                        content.languages
                      }}</label>
                      <div class="clearfix"></div>
                      <p>{{ language(source) }} into {{ language(target) }}</p>
                    </div>

                    <div class="col-12 col-md-4 end-align left-in-sm">
                      <label class="small d-block">{{
                        content.totalPrice
                      }}</label>
                      <div class="clearfix"></div>

                      <p>$ {{ documentTypePrice }}</p>
                    </div>
                    <div class="col-12 col-md-9 mt-3" v-if="delivery">
                      <label class="small d-block">{{
                        content.delivery
                      }}</label>
                      <div class="clearfix"></div>
                      <p>{{ address }}</p>
                    </div>
                    <div
                      v-if="delivery"
                      class="col-6 col-md-3 left-in-sm mt-3 end-align"
                    >
                      <label class="small d-block">{{
                        content.deliveryCost
                      }}</label>
                      <div class="clearfix"></div>
                      <p>$ {{ deliveryCost }}</p>
                    </div>
                  </div>
                  <div class="col-12 text-align-center end-align left-in-sm">
                    <h5 class="text-success mt-3">
                      {{ content.totalUSD }}

                      {{ invoiceTotal }}
                    </h5>
                  </div>

                  <div class="col-lg-12 mt-3 mb-5" style="text-align: right">
                    <hr />
                  </div>

                  <div class="col-lg-12 mb-5">
                    <h5>{{ content.choosePaymentMethod }}</h5>
                  </div>

                  <!--done-->
                  <div class="col-lg-4">
                    <div class="form-check">
                      <input
                        id="radio-cash"
                        class="form-check-input"
                        type="radio"
                        name="payment"
                        value="cash"
                        v-model="payment"
                      />
                      <label class="form-check-label pt-0" for="radio-cash">
                        {{ content.invoiceDirectly }}
                      </label>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-check">
                      <input
                        id="radio-online"
                        class="form-check-input"
                        type="radio"
                        name="payment"
                        value="online"
                        v-model="payment"
                      />
                      <label class="form-check-label pt-0" for="radio-online">
                        {{ content.payOnline }}
                      </label>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-check">
                      <input
                        id="radio-credit"
                        class="form-check-input"
                        type="radio"
                        name="payment"
                        value="credit"
                        v-model="payment"
                      />
                      <label class="form-check-label pt-0" for="radio-credit">
                        {{ content.useAroCredit
                        }}<a class="btn link p-0">{{ content.buyCredits }}</a>
                      </label>
                    </div>
                  </div>

                  <div class="col-lg-12 mt-3 mb-3 p-0">
                    <hr />

                    <div class="row mb-5 p-0" v-if="payment == 'cash'">
                      <div class="col-lg-4">
                        <label class="small d-block">
                          {{ content.delivery }}
                        </label>
                        <select class="form-select" v-model="invoiceDelivery">
                          <option value="personal">
                            {{ content.personalDelivery }}
                          </option>
                          <option value="physical">
                            {{ content.physicalCollection }}
                          </option>
                        </select>
                      </div>

                      <div
                        class="col-lg-8"
                        v-if="invoiceDelivery == 'physical'"
                      >
                        <div class="input-group mb-3">
                          <label class="small d-block">
                            {{ content.address }}
                          </label>
                          <div class="clearfix"></div>
                          <textarea
                            class="form-control"
                            v-model="invoiceAddress"
                          ></textarea>
                          <div class="clearfix"></div>
                          <p class="error-text">
                            {{ invoiceDeliveryError }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <p v-if="submitError" class="alert alert-warning mt-3">
                      {{ submitError }}
                    </p>

                    <div class="clearfix w-100"></div>
                    <button
                      type="button"
                      class="btn btn-light mt-4 m-l-4"
                      @click.prevent="toStep1"
                    >
                      {{ content.back }}
                    </button>
                    &nbsp;
                    <button class="btn green-btn mt-4" @click="submitOrder">
                      {{ content.submitOrder }}</button
                    >&nbsp;&nbsp;&nbsp;&nbsp;
                  </div>
                </div>
              </div>

              <div class="container-xl mt-5" v-if="submit">
                <div v-if="!success" style="text-align: center">
                  <h5>
                    {{ content.processingOrder }}
                    <br />
                  </h5>
                  <GreenSpinner />
                </div>
                <div v-if="success" class="w-100 alert alert-success">
                  <h3>
                    {{ content.orderWasSubmitted }}
                  </h3>
                  <p class="mb-0">
                    {{ content.weReviewOrder }}
                    <router-link to="/" class="alert-link">{{
                      content.done
                    }}</router-link>
                  </p>
                </div>
                <div v-if="success == false" class="w-100 alert alert-danger">
                  <h3>{{ content.failedRequest }}</h3>
                  <p class="mb-0">
                    {{ content.weReviewOrder }}
                    <router-link to="/" class="alert-link">{{
                      content.back
                    }}</router-link>
                  </p>
                </div>
              </div>
            </div>
            <div v-else class="alert alert-warning m-3 mt-5">
              {{ content.youNeedTo }}
              <router-link class="alert-link" to="/signin">{{
                content.signIn
              }}</router-link>
              {{ content.toContinue }}
            </div>
          </div>
        </div>
      </form>
    </div>
    <div ref="invoice" :style="'display:' + hide">
      <div
        style="
          margin: 0px 0x !important;
          font-size: 32px;
          text-align: left;
          width: 100%;
          color: #fff;
          background: #2a6049 !important;
          padding: 10px 20px !important;
        "
      ></div>
      <div style="display: flex; justify-content: space-between">
        <img
          style="margin-top: 20px; height: 100px; padding: 20px !important"
          src="/static/images/logo.png"
        />
        <div
          class="aro"
          style="margin-top: 20px; padding: 20px !important; text-align: right"
        >
          <h1>Invoice</h1>

          <div style="margin-bottom: 10px">
            Invoice date : {{ todayDate() }}
          </div>
          <div style="margin-bottom: 10px">Deadline : {{ date }}</div>
          <div style="margin-bottom: 10px">Payment Method : {{ payment }}</div>
        </div>
      </div>
      <div
        style="
          margin-top: 50px;
          padding: 10px 20px !important;
          display: flex;
          justify-content: space-between;
        "
      >
        <div class="aro" style="width: 300px">
          <div style="margin-bottom: 10px; font-weight: 700">
            FullStop
          </div>

          <div style="margin-bottom: 10px">Address: Erbil, Iraq</div>
          <div style="margin-bottom: 10px">Phone: 0770 000 0000</div>
          <div style="margin-bottom: 10px">Email: info@arotranslation.com</div>
        </div>
        <div class="client" style="margin-left: 100px; text-align: right">
          <div style="margin-bottom: 10px; font-weight: 700">Bill To</div>
          <div style="margin-bottom: 10px">
            {{ user?.firstName }} {{ user?.lastName }},
            {{ user?.company }}
          </div>

          <div style="margin-bottom: 10px">
            Address:
            <span
              >{{ user?.city }}, {{ user?.country }}<br />
              {{ user?.address }}</span
            >
          </div>
          <div style="margin-bottom: 10px">Phone: {{ user?.phone }}</div>
        </div>
      </div>
      <table style="width: 100%">
        <tr style="color: #fff; background: #2a6049">
          <th style="padding: 20px; width: 200">Item</th>
          <th style="padding: 20px; width: 600">Description</th>
          <th style="padding: 20px; width: 200">Price</th>
        </tr>
        <tr>
          <td
            style="
              padding: 20px;
              text-align: center;
              border-right: solid #ccc !important;
            "
          >
            {{ content.legalTranslation }}
            <div v-if="documentType != null">{{ documentType.type }}</div>
          </td>
          <td
            style="
              padding: 20px;
              text-align: center;
              border-right: solid #ccc !important;
            "
          >
            <div style="line-height: 30px">
              Source language : {{ language(source) }}
              <div v-if="!!target">
                Target language : {{ language(target) }}
              </div>
            </div>
          </td>
          <td style="padding: 20px; text-align: center">
            translation cost : $ {{ subtotal }}
            <div v-if="delivery == 1">delivery Cost: $ {{ deliveryCost }}</div>
          </td>
        </tr>
      </table>
      <h3 style="float: right; margin-top: 20px; padding: 20px !important">
        Total Price : $ {{ invoiceTotal }}
      </h3>
      <div></div>
    </div>
  </div>
</template>

<script>
import GreenSpinner from "../../components/ui/GreenSpinner.vue";
export default {
  computed: {
    user() {
      return this.$store.getters.user;
    },
    content() {
      return this.$store.getters.content;
    },
    isAuth() {
      return this.$store.getters.isAuth;
    },
    documentTypeId() {
      return this.documentType.typeId;
    },
    documentTypePrice() {
      if (this.documentType != null) {
        return this.documentType.price;
      } else {
        return false;
      }
    },
    deliveryCost() {
      return Number(this.city.price ?? 0);
    },

    address() {
      return (
        "Contact Name : " +
        this.contactName +
        ",  Phone: " +
        this.contactPhone +
        ",  Email : " +
        this.contactEmail +
        ",  City : " +
        this.cityName +
        ", Contact Address Line: " +
        this.contactAddress
      );
    },
    cityId() {
      return this.city.id ?? 0;
    },
    cityName() {
      return this.city.name ?? "empty";
    },
    invoiceTotal() {
      return Number(this.deliveryCost) + Number(this.documentTypePrice);
    },

    subtotal() {
      return Number(this.documentTypePrice);
    },
  },
  data() {
    return {
      serviceId: 1,
      legalTranslationPrices: null,
      deliveryPrice: 0,
      minDate: this.getMinDate(),

      documentType: null,

      source: 1,
      target: 1,
      date: null,
      file: null,

      //ui
      step1: true,
      step3: false,

      //payment
      payment: "cash",
      invoiceAddress: "...",
      invoiceDelivery: "personal",
      invoice: "",
      submit: false,

      //delivery and stamp
      stamp: 0,
      delivery: 0,
      city: {},
      contactName: "",
      contactPhone: "",
      contactEmail: "",
      contactAddress: "",

      //errors
      fileError: null,
      documentTypeError: null,
      deadlineError: null,
      cityError: null,
      invoiceDeliveryError: null,
      submitError: null,
      //submission
      success: null,
      response: null,
      hide: "none",
      res: [],
    };
  },

  mounted() {
    this.getMinDate();
  },
  methods: {
    getMinDate() {
      var today = new Date();
      var dd = today.getDate();

      var mm = today.getMonth() + 1;
      var yyyy = today.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }

      if (mm < 10) {
        mm = "0" + mm;
      }
      return (today = yyyy + "-" + mm + "-" + dd);
    },
    todayDate() {
      var today = new Date();
      var dd = today.getDate();

      var mm = today.getMonth() + 1;
      var yyyy = today.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }

      if (mm < 10) {
        mm = "0" + mm;
      }
      return (today = yyyy + "/" + mm + "/" + dd);
    },
    print() {
      const contentHtml = this.$refs.invoice.innerHTML;
      var myWindow = window.open("", "", "width=992,height=1080");
      myWindow.document.write(contentHtml);
      myWindow.print();
    },
    language(id) {
      if (id == 1) {
        return this.content.english;
      } else if (id == 2) {
        return this.content.arabic;
      } else if (id == 3) {
        return this.content.kurdish;
      }
    },
    setCity(cityName) {
      this.cityName = cityName;
    },
    getFile(e) {
      this.file = e.target.files[0];
    },

    toStep1() {
      this.step1 = true;
      this.step3 = false;
      this.submit = false;
    },

    toStep3() {
      if (this.file == null) {
        this.fileError = "File is required";
      } else {
        this.fileError = null;
      }

      if (
        this.documentType == null ||
        this.documentType == "" ||
        this.documentType == 1
      ) {
        this.documentTypeError = "Document Type is required.";
      } else {
        this.documentTypeError = null;
      }

      if (this.date == null) {
        this.deadlineError = "Date is required.";
      } else {
        this.deadlineError = null;
      }
      if (this.stamp == 1 && this.delivery == 1 && this.cityId == 0) {
        this.cityError = "Delivery city is required.";
      } else {
        this.cityError = null;
      }

      if (
        this.fileError == null &&
        this.documentTypeError == null &&
        this.deadlineError == null &&
        this.cityError == null
      ) {
        this.step1 = false;
        this.step3 = true;
        this.submit = false;
        window.scrollTo(0, 250);
      }
    },

    inputIsValid() {
      let validinputs = false;
      let validAddress = false;

      if (
        this.fileError == null &&
        this.documentTypeError == null &&
        this.deadlineError == null &&
        this.cityError == null
      ) {
        validinputs = true;
      }
      if (this.invoiceDelivery == "physical") {
        if (this.invoiceAddress == "..." || this.invoiceAddress == "") {
          this.invoiceDeliveryError =
            "please provide delivery address for your invoice  to continue";
        } else {
          this.invoiceDeliveryError = null;
        }
      } else {
        this.invoiceDeliveryError = null;
      }

      if (this.invoiceDeliveryError == null) {
        validAddress = true;
      }

      console.log(validAddress + "" + validinputs);
      return validAddress && validinputs;
    },

    async submitOrder() {
      if (this.inputIsValid()) {
        this.step1 = false;
        this.step3 = false;
        this.submit = true;
        window.scrollTo(0, 250);
        this.submitError = null;

        this.response = await this.$store.dispatch(
          "orderLegalTranslation",
          this.delivery == 0
            ? {
                service_id: this.serviceId,
                source: this.source,
                target: this.target,
                date: this.date,
                delivery: this.delivery,
                payment: this.payment,
                subtotal: this.subtotal,
                total: this.invoiceTotal,
                invoice_address: this.invoiceAddress,
              }
            : {
                service_id: this.serviceId,
                source: this.source,
                target: this.target,
                date: this.date,
                delivery: this.delivery,
                payment: this.payment,
                subtotal: this.subtotal,
                total: this.invoiceTotal,
                city: this.cityId,
                address: this.address,
                invoice_address: this.invoiceAddress,
              }
        );
        console.log(this.response);
        if (this.response.id) {
          let form = new FormData();
          // console.log(this.file);

          // console.log(this.response.sub.id);
          // console.log(this.documentTypeId);
          // console.log(this.documentTypePrice);
          // console.log(this.stamp);

          form.append("file", this.file);
          form.append("request", this.response.sub.id.toString());
          form.append("document_type", this.documentTypeId.toString());
          form.append("stamp", this.stamp.toString());
          form.append("total", this.documentTypePrice.toString());
          form.append("price", "1");

          let res = await new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open(
              "POST",
              `${
                this.$store.state.postURL + this.$store.state.apiURL
              }services/request/legal-translation/file`,
              true
            );

            //  xhr.setRequestHeader("Content-Type", "multipart/form-data" );
            xhr.setRequestHeader("Accept", "application/json");
            xhr.setRequestHeader(
              "Authorization",
              "Bearer " + this.$store.getters.token
            );
            xhr.send(form);
            xhr.onloadend = () => {
              if (xhr.status >= 200 && xhr.status < 300) {
                resolve(xhr.response);
                this.success = true;
              } else {
                reject(
                  JSON.stringify({ status: xhr.status, response: xhr.response })
                );
                this.success = false;
              }
            };
          });
          console.log(await res);

          if (this.response.url) {
            window.open(this.response.url, "_self");
          }
        }
      } else {
        this.step1 = false;
        this.step3 = true;
        this.submit = false;
        this.submitError =
          "Unable to submit, please check all the required inputs";
      }
    },
    async loadData() {
      //legal trans
      await this.$store.dispatch("getLegalTranslationPrices");
      this.legalTranslationPrices = await this.$store.getters
        .legalTranslationPrices;
      //delivery price
      await this.$store.dispatch("getDeliveryPrices");
      this.deliveryPrice = await this.$store.getters.deliveryPrices;
    },
  },
  created() {
    this.loadData();
  },
  components: {
    GreenSpinner,
  },
};
</script>

<style scoped>
.card {
  border-radius: 15px;
}

.packcard h5 {
  text-align: left;
}

.packprice {
  text-align: left;
}
.card p,
.card h5,
.link {
  color: #2a6049;
}

.link {
  color: #2a6049;
  font-size: 12px;
  margin-left: 10px;
  font-weight: bold;
}

p {
  margin-top: 5px;
  font-size: 15px;
}

.form-check-label {
  font-size: 15px !important;
}

.row {
  margin: 0px !important;
}

.end-align {
  text-align: end;
}
@media (max-width: 767px) {
  .left-in-sm {
    text-align: start !important;
  }
}
</style>
