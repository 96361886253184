import http from '../../../httpRequests.js'

export default {
    state() {
        return {
            url: 'services',
            servicesContent: {}
        }
    },
    
    mutations: {
        setServicesContent(state, payload) {
            state.servicesContent = payload;
        }
    },

    actions: {
        async getServicesData(context) {
            const response = await http.get(context.state.url, context.rootState.lang);
            context.commit('setServicesContent', response);
        }
    },

    getters: {
        servicesContent(state) {
            return state.servicesContent;
        }
    }
}