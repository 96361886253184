<template>
  <form @submit.prevent="">
    <div class="container d-block mt-4" v-if="step1">
      <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <div class="input-group mb-3">
            <label class="small d-block">{{ content.sourceLanguage }}</label>
            <div class="clearfix"></div>
            <select class="form-select" v-model="source" required>
              <option value="1">{{ content.english }}</option>
              <option value="2">{{ content.arabic }}</option>
              <option value="3">{{ content.kurdish }}</option>
            </select>
          </div>
        </div>

        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <div class="input-group mb-3">
            <label class="small d-block">{{ content.targetLanguage }}</label>
            <div class="clearfix"></div>
            <select class="form-select" v-model="target" required>
              <option value="1">{{ content.english }}</option>
              <option value="2">{{ content.arabic }}</option>
              <option value="3">{{ content.kurdish }}</option>
            </select>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
          <div class="input-group mb-3">
            <label class="small d-block">{{
              content.chooseLegalDocumentType
            }}</label>
            <div class="clearfix"></div>
            <select class="form-select" v-model="documentType">
              <option
                v-for="documentType in legalTranslationPrices"
                :key="documentType.id"
                :value="documentType"
              >
                {{ documentType.type }} ( ${{ documentType.price }} )
              </option>
            </select>
            <div class="clearfix"></div>

            <div class="error-text">{{ documentTypeError }}</div>
          </div>
        </div>

        <div class="col-md-6 col-sm-12">
          <div class="input-group mb-3">
            <label class="small d-block"> {{ content.fullName }}</label>
            <div class="clearfix"></div>
            <input type="text" class="form-control" v-model="contactName" />
          </div>
        </div>

        <div class="col-md-6 col-sm-12">
          <div class="input-group mb-3">
            <label class="small d-block">
              {{ content.phone }}
            </label>
            <div class="clearfix"></div>
            <input type="text" class="form-control" v-model="contactPhone" />
          </div>
        </div>
        <div class="col-md-6 col-sm-12">
          <div class="input-group mb-3">
            <label class="small d-block">
              {{ content.email }}
            </label>
            <div class="clearfix"></div>
            <input type="text" class="form-control" v-model="contactEmail" />
          </div>
        </div>

        <div class="col-12">
          <button
            v-on:click.prevent="toStep3"
            type="submit"
            class="btn green-btn mt-4"
          >
            {{ content.getQuote }}
          </button>
        </div>
      </div>
    </div>

    <div class="container d-block mt-4" v-if="step3">
      <div class="row">
        <div
          class="col-8 mb-5 mt-1"
          style="color: #363636; font-size: 20px; text-align: start"
        >
          {{ content.orderSummery }}
        </div>
        <div class="col-4" style="text-align: right">
          <button
            type="button"
            class="btn btn-outline-success print-btn"
            @click="print()"
          >
            PRINT
          </button>
        </div>

        <div class="d-flex justify-content-between flex-wrap">
          <div class="col-6 col-sm-3" style="text-align: start">
            <label class="small d-block">{{ content.documentType }}</label>
            <div class="clearfix"></div>
            <p>{{ documentType.type }}</p>
          </div>
          <div class="col-6 col-sm-3 left-in-sm" style="text-align: center">
            <label class="small d-block">{{ content?.price }}</label>
            <div class="clearfix"></div>

            <p>$ {{ packagePrice }}</p>
          </div>
          <div class="col-6 col-sm-3 end-align left-in-sm">
            <label class="small d-block">{{ content.languages }}</label>
            <div class="clearfix"></div>
            <p>{{ language(source) }} into {{ language(target) }}</p>
          </div>
          <div class="col-6 col-sm-9 mt-3" style="text-align: start">
            <label class="small d-block">{{
              content.contactInformation
            }}</label>
            <div class="clearfix"></div>
            <p>{{ address }}</p>
          </div>
        </div>

        <div class="col-12 text-align-center end-align left-in-sm">
          <h5 class="text-success mt-3">
            {{ content.totalUSD }}

            {{ packagePrice }}
          </h5>
          <div class="col-12 mt-3">
            <hr />
            <button
              v-on:click.prevent="toStep1"
              type="button"
              class="btn btn-light mt-4"
            >
              {{ content.back }}
            </button>
            <button
              class="btn btn-success mt-4 m-l-4"
              v-on:click.prevent="$router.push('/OrderLegalTranslation')"
            >
              {{ content.order }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div ref="invoice" :style="'display:' + hide">
    <div
      style="
        margin: 0px 0x !important;
        font-size: 32px;
        text-align: left;
        width: 100%;
        color: #fff;
        background: #2a6049 !important;
        padding: 10px 20px !important;
      "
    ></div>
    <div style="display: flex; justify-content: space-between">
      <img
        style="margin-top: 20px; height: 100px; padding: 20px !important"
        src="/static/images/logo.png"
      />
      <div
        class="aro"
        style="margin-top: 20px; padding: 20px !important; text-align: right"
      >
        <h1>Quote</h1>

        <div style="margin-bottom: 10px">Quote date : {{ todayDate() }}</div>
      </div>
    </div>
    <div
      style="
        margin-top: 50px;
        padding: 10px 20px !important;
        display: flex;
        justify-content: space-between;
      "
    >
      <div class="aro" style="width: 300px">
        <div style="margin-bottom: 10px; font-weight: 700">FullStop</div>

        <div style="margin-bottom: 10px">Address: Erbil, Iraq</div>
        <div style="margin-bottom: 10px">Phone: 0770 000 0000</div>
        <div style="margin-bottom: 10px">Email: info@arotranslation.com</div>
      </div>
      <div class="client" style="margin-left: 100px; text-align: right">
        <div style="margin-bottom: 10px; font-weight: 700">Bill To</div>
        <div style="margin-bottom: 10px">
          {{ user?.firstName }} {{ user?.lastName }},
          {{ user?.company }}
        </div>

        <div style="margin-bottom: 10px">
          Address:
          <span
            >{{ user?.city }}, {{ user?.country }}<br />
            {{ user?.address }}</span
          >
        </div>
        <div style="margin-bottom: 10px">Phone: {{ user?.phone }}</div>
      </div>
    </div>
    <table style="width: 100%">
      <tr style="color: #fff; background: #2a6049">
        <th style="padding: 20px; width: 200">Item</th>
        <th style="padding: 20px; width: 600">Description</th>
        <th style="padding: 20px; width: 200">Price</th>
      </tr>
      <tr>
        <td
          style="
            padding: 20px;
            text-align: center;
            border-right: solid #ccc !important;
          "
        >
          {{ content.legalTranslation }}
          <div v-if="documentType != null">{{ documentType.type }}</div>
        </td>
        <td
          style="
            padding: 20px;
            text-align: center;
            border-right: solid #ccc !important;
          "
        >
          <div style="line-height: 30px">
            Source language: {{ language(source) }}
            <div v-if="!!target">Target language: {{ language(target) }}</div>
          </div>
        </td>
        <td style="padding: 20px; text-align: center">$ {{ packagePrice }}</td>
      </tr>
    </table>
    <h3 style="float: right; margin-top: 20px; padding: 20px !important">
      Total Price : $ {{ packagePrice }}
    </h3>
    <div></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //main request variables
      serviceId: 1,
      source: 1,
      target: 1,
      legalTranslationPrices: null,
      documentType: null,
      documentTypeError: null,

      total: 0, //suntotal + delivery
      setPackage: null,

      // Delivery and stamp Info
      contactName: "",
      contactPhone: "",
      contactEmail: "",

      //ui variables
      step1: true,

      step3: false,
      submit: false,
      attaching: false,

      submitError: null,

      hide: "none",
      res: [],
    };
  },

  methods: {
    todayDate() {
      var today = new Date();
      var dd = today.getDate();

      var mm = today.getMonth() + 1;
      var yyyy = today.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }

      if (mm < 10) {
        mm = "0" + mm;
      }
      return (today = yyyy + "/" + mm + "/" + dd);
    },
    print() {
      const contentHtml = this.$refs.invoice.innerHTML;
      var myWindow = window.open("", "", "width=992,height=1080");
      myWindow.document.write(contentHtml);
      myWindow.print();
    },

    toStep1() {
      this.step1 = true;
      this.step3 = false;
    },
    toStep3() {
      if (this.documentType == null) {
        this.documentTypeError = this.content.required;
      } else {
        this.step1 = false;

        this.step3 = true;
      }
    },

    async loadData() {
      //legal trans
      await this.$store.dispatch("getLegalTranslationPrices");
      this.legalTranslationPrices = await this.$store.getters
        .legalTranslationPrices;
    },

    language(id) {
      if (id == 1) {
        return this.content.english;
      } else if (id == 2) {
        return this.content.arabic;
      } else if (id == 3) {
        return this.content.kurdish;
      }
    },
  },
  computed: {
    content() {
      this.loadData();

      return this.$store.getters.content;
    },

    address() {
      return (
        "Contact Name : " +
        this.contactName +
        ",  Phone: " +
        this.contactPhone +
        ",  Email : " +
        this.contactEmail
      );
    },

    packagePrice() {
      return this.documentType?.price;
    },
    user() {
      return this.$store.getters.user;
    },

    invoiceTotal() {
      let total = 0;

      return total;
    },

    subtotal() {
      let total = 0;

      return total;
    },
  },
  created() {
    this.loadData();
  },
};
</script>

<style scoped>
.card {
  border-radius: 15px;
}

.packcard h5 {
  text-align: left;
}

.packprice {
  text-align: left;
}
.card p,
.card h5,
.link {
  color: #2a6049;
}

.link {
  color: #2a6049;
  font-size: 12px;
  margin-left: 10px;
  font-weight: bold;
}

p {
  margin-top: 5px;
  font-size: 15px;
}

.form-check-label {
  font-size: 15px !important;
}

.row {
  margin: 0px !important;
}

.end-align {
  text-align: end;
}
@media (max-width: 767px) {
  .left-in-sm {
    text-align: start !important;
  }
}
</style>
