<template>
  <div class="top-header">
    <div class="container-xxl">
      <div class="dropdown top-header-left">
        <a
          class="dropdown-toggle"
          type="button"
          id="dropdownMenu2"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          style="padding-left: 0px !important"
        >
          {{ content.language }}
        </a>

        <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
          <li>
            <a
              class="dropdown-item dark"
              type="button"
              @click="updateLanguage('ar')"
              >العربی</a
            >
          </li>
          <li>
            <a
              class="dropdown-item dark"
              type="button"
              @click="updateLanguage('ku')"
              >کوردی</a
            >
          </li>
          <li>
            <a
              class="dropdown-item dark"
              type="button"
              @click="updateLanguage('en')"
              >English</a
            >
          </li>
        </ul>
      </div>
      <div class="top-header-right">
        <a href="/contact" style="padding-right: 0px !important">
          {{ content.contactUsU }}
        </a>
        <a href="/terms"> {{ content.terms }} </a>
        <a href="/faq"> {{ content.faq }} </a>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="PlaceOrder"
    tabindex="-1"
    role="dialog"
    aria-labelledby="PlaceOrderLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content" style="border-radius: 15px">
        <div class="modal-header justify-content-center align-items-center">
          <h5 class="modal-title" id="PlaceOrderLabel">
            {{ content.placeOrder }}
          </h5>
        </div>
        <div
          class="
            modal-body
            justify-content-center
            align-items-center
            d-flex
            flex-column
            pb-5
          "
        >
          <div class="input-group mb-3" style="100%;border-radius:15px;">
            <div class="accordion w-100" id="accordionExample">
              <div class="card" style="border-radius: 15px">
                <div
                  class="card-header"
                  id="headingOne"
                  style="
                    background-color: #fff;
                    border-radius: 15px;
                    border: none;
                  "
                >
                  <h2 class="mb-0" style="display: flex">
                    <button
                      class="btn-link"
                      style="
                        text-decoration: none;
                        text-align: center;
                        color: #363636;
                      "
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      {{ content.translationServices
                      }}<button
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        style="width: 20px; padding-right: 0px"
                      >
                        <img
                          src="/static/images/faq-down.png"
                          style="width: 20px"
                        />
                      </button>
                    </button>
                  </h2>
                </div>

                <div
                  id="collapseOne"
                  class="collapse px-3"
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                >
                  <div class="card-body p-0 mb-4 mt-2">
                    <button
                      style="
                        text-align: center;
                        border: 1px solid #d2d2d2 !important;
                        border-radius: 15px;
                        color: #363636;
                        margin-bottom: 20px;
                      "
                      @click="$router.push('/orderdocumenttranslation')"
                      data-dismiss="modal"
                    >
                      {{ content.documentTranslation }}
                    </button>
                    <button
                      style="
                        text-align: center;
                        border: 1px solid #d2d2d2 !important;
                        border-radius: 15px;
                        color: #363636;
                      "
                      @click="$router.push('/orderlegaltranslation')"
                      data-dismiss="modal"
                    >
                      {{ content.legalTranslation }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <button
            class="place-order-btn btn"
            @click="$router.push('/orderinterpretation')"
            data-dismiss="modal"
          >
            {{ content.interpretation }}
          </button>
          <button
            class="place-order-btn btn"
            @click="$router.push('/orderproofreading')"
            data-dismiss="modal"
          >
            {{ content.proofreading }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="main-header">
    <div class="container-xxl">
      <div class="d-flex justify-content-between">
        <div >
          <a href="/"
            ><img src="/static/images/fullstop-Logo.png"  class="brand-image" 
          /></a>
        </div>
        <div class="nav">
          <a href="/" class="main-header-btn"
            ><img src="/static/images/homeIco.png" class="main-header-ico" />
            <div class="main-header-txt">{{ content.home }}</div>
          </a>
          <router-link to="/services" class="main-header-btn"
            ><img src="/static/images/ServiceIco.png" class="main-header-ico" />
            <div class="main-header-txt">{{ content.services }}</div>
          </router-link>
          <a href="/pricing" class="main-header-btn"
            ><img
              src="/static/images/PricingIco1.png"
              class="main-header-ico"
            />
            <div class="main-header-txt">{{ content.pricing }}</div>
          </a>
          <a href="/about" class="main-header-btn"
            ><img src="/static/images/aboutIco.png" class="main-header-ico" />
            <div class="main-header-txt">{{ content.about }}</div>
          </a>
          <a v-if="isAuth" href="/account/profile" class="main-header-btn"
            ><img src="/static/images/profileIcon.png" class="main-header-ico" />
            <div class="main-header-txt">{{ content.account }}</div>
          </a>
        </div>

        <div class="nav">
          <router-link
            v-if="!isAuth"
            to="/signin"
            class="btn btn-signin order-btn"
            >{{ content.signIn }}</router-link
          >
          <router-link to="/quote" class="green-btn btn order-btn">{{
            content.getQuoteU
          }}</router-link>
          <!-- Modal -->
          <button
            type="button"
            class="PlaceOrderBtn btn order-btn"
            data-toggle="modal"
            data-target="#PlaceOrder"
          >
            {{ content.placeOrder }}
          </button>

          <!-- Modal-end -->
        </div>
      </div>
    </div>
  </div>

  <div class="mobile-header d-flex align-items-center">
    <div class="container-xxl d-flex justify-content-between">
      <div class="d-flex align-items-center" style="margin: 20px">
        <router-link to="/"
          ><img src="/static/images/fullstopwhite-logo.png" class="mobile-logo" />
        </router-link>
      </div>

      <div class="d-flex m-0 p-0 justify-content-between align-items-center">
        <router-link
          v-if="!isAuth"
          to="/signin"
          class=""
          style="color: #fff; line-height: 90px; padding: 0 30px"
        >
          <span class="signin-mobile">{{ content.signIn }}</span>
          <div class="d-flex m-0 p-0 align-items-center">
            <img
              src="/static/images/signin.png"
              class="signin-mobile-ico"
            /></div
        ></router-link>

        <div class="dropdown top-header-left d-flex align-items-center">
          <a
            class="dropdown-toggle"
            type="button"
            id="dropdownMenu2"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style="
              padding-left: 0px !important;
              text-decoration: none;
              margin-inline-end: 20px;
            "
          >
            <global></global>
          </a>

          <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
            <li>
              <a
                class="dropdown-item dark"
                type="button"
                @click="updateLanguage('ar')"
                >العربی</a
              >
            </li>
            <li>
              <a
                class="dropdown-item dark"
                type="button"
                @click="updateLanguage('ku')"
                >کوردی</a
              >
            </li>
            <li>
              <a
                class="dropdown-item dark"
                type="button"
                @click="updateLanguage('en')"
                >English</a
              >
            </li>
          </ul>
        </div>
        <div
          class="align-items-center justify-content-center d-flex flex-column"
          @click="toggle"
          style="cursor: pointer"
        >
          <div
            class="close-line"
            style="height: 2px; background: #fff; margin: 3px"
            :class="opened ? 'close-rotate-45' : ''"
          ></div>
          <div
            class="close-line"
            style="width: 32px; height: 2px; background: #fff; margin: 3px"
            :class="opened ? 'close' : ''"
          ></div>
          <div
            class="close-line"
            style="height: 2px; background: #fff; margin: 3px"
            :class="opened ? 'close-rotate' : ''"
          ></div>
        </div>
      </div>
    </div>
  </div>
  <div :class="opened ? 'show-mobile-nav' : ''" class="menu">
    <ul>
      <li>
        <router-link @click="toggle" to="/">{{ content.home }}</router-link>
      </li>
      <li>
        <router-link @click="toggle" to="/Services">{{
          content.services
        }}</router-link>
      </li>
      <li>
        <router-link @click="toggle" to="/pricing">{{
          content.pricing
        }}</router-link>
      </li>
      <li>
        <router-link @click="toggle" to="/about">{{
          content.about
        }}</router-link>
      </li>
      <li>
        <router-link @click="toggle" to="/quote">{{
          content.getQuoteU
        }}</router-link>
      </li>
      <li>
        <router-link @click="toggle" to="/faq">{{ content.faq }}</router-link>
      </li>
      <li>
        <router-link @click="toggle" to="/terms">{{
          content.terms
        }}</router-link>
      </li>
      <li>
        <router-link @click="toggle" to="/contact">{{
          content.contactUsU
        }}</router-link>
      </li>
      <li v-if="isAuth">
        <router-link @click="toggle" to="/account/profile">{{
          content.account
        }}</router-link>
      </li>
    </ul>
    <div class="d-block text-center">
      <button
        v-if="isAuth"
        @click="signOut"
        class="btn btn-outline-light mt-5 w-75"
      >
        {{ content.logout }}
      </button>
    </div>
  </div>
</template>

<script>
import Global from "../../components/Icons/Global.vue";

export default {
  components: {
    Global,
  },
  data() {
    return {
      Order: "TranslationServices",
      opened: false,
    };
  },
  methods: {
    toggle() {
      this.opened = !this.opened;
    },
    updateLanguage(language) {
      this.$store.dispatch("updateLanguage", language);
      this.$store.dispatch("getData");
    },
    async signOut() {
      await this.$store.dispatch("signOut");
      this.$store.commit("unsetAuth");
      if (!this.isAuth) {
        this.$router.replace("/");
        this.toggle();
      }
    },
  },
  watch: {
    Order() {
      if (this.Order === "document") {
        this.$router.push("/orderdocumenttranslation");
      } else if (this.Order === "legal") {
        this.$router.push("/orderlegaltranslation");
      }
    },
  },

  computed: {
    content() {
      return this.$store.getters.content;
    },
    isAuth() {
      return this.$store.getters.isAuth;
    },
  },
};
</script>


<style scoped>
@media (max-width: 1199px) {
  .top-header {
    display: none;
  }
  .main-header {
    display: none;
  }
}
@media screen and (min-width: 1200px) {
  .mobile-header {
    display: none !important;
  }
  .menu {
    display: none;
  }
}
.mobile-header {
  height: 80px;
  background: #2a6049;
}
.mobile-logo {
  width: 100px;
}
.menu {
  width: 0vw;
  overflow: hidden;
  z-index: 999;
  height: 100vh;
  opacity: 0;
  right: -100vw;
  background: #2a6049;
  position: absolute;
  transition: 0.5s all ease-out;
}
.menu ul {
  text-align: center;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
}
.menu ul li {
  padding: 10px;
  border-bottom: 1px solid #fff6;
  text-transform: uppercase;
}
.show-mobile-nav {
  right: 0px;
  opacity: 1;
  width: 100vw;
}

.close {
  visibility: hidden;
}
.close-line:first-child {
  transform: rotate(0deg);
  transition: 0.4s all ease;
  transform-origin: right;
  width: 32px;
}
.close-line:last-child {
  transform: rotate(0deg);
  transition: 0.4s all ease;
  transform-origin: right;
  width: 32px;
}
.close-rotate {
  transform: rotate(45deg) !important;
  width: 23px !important;
}
.close-rotate-45 {
  transform: rotate(-45deg) !important;
  width: 23px !important;
}
.dropdown-item {
  color: #363636;
}

li a {
  text-decoration: none;
  color: #fff;
}
.signin-mobile-ico {
  width: 25px;
  height: 25px;
  display: none;
}
.modal-content {
  width: 400px;
}
.accordion button {
  font-size: 15px;
  font-weight: 500;
}
</style>