<template>
    <svg version="1.1" class="global" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 512 512">
<g>
</g>
	<path d="M402.565 255.99c0 113.889-65.802 206.551-146.565 206.551-80.825 0-146.535-92.662-146.535-206.551 0-113.849 65.71-206.52 146.535-206.52 80.763 0 146.565 92.672 146.565 206.52zM255.98 32.942c-89.897 0-163.093 100.055-163.093 223.048 0 123.034 73.185 223.068 163.093 223.068 89.917 0 163.082-100.045 163.082-223.068 0-122.993-73.165-223.048-163.082-223.048z" fill="#fff"/>
	<path d="M318.536 255.99c0 121.712-32.983 206.551-62.556 206.551-29.614 0-62.546-84.839-62.546-206.551 0-121.723 32.942-206.52 62.546-206.52 29.584 0 62.556 84.797 62.556 206.52zM255.98 32.942c-51.374 0-79.104 114.923-79.104 223.048 0 108.155 27.73 223.068 79.104 223.068 51.344 0 79.125-114.923 79.125-223.068 0-108.135-27.781-223.048-79.125-223.048z" fill="#fff"/>
	<path d="M462.541 255.99c0 113.889-92.662 206.551-206.561 206.551-113.869 0-206.53-92.662-206.53-206.551 0-113.849 92.662-206.52 206.53-206.52 113.9 0 206.561 92.672 206.561 206.52zM255.98 32.942c-122.993 0-223.027 100.055-223.027 223.048 0 123.034 100.045 223.068 223.027 223.068 123.023 0 223.058-100.045 223.058-223.068 0-122.993-100.034-223.048-223.058-223.048z" fill="#fff"/>
	<path d="M43.377 254.403h425.195v16.537h-425.195v-16.537z" fill="#fff"/>
	<path d="M70.769 142.889h370.452v16.548h-370.452v-16.548z" fill="#fff"/>
	<path d="M70.769 365.957h370.452v16.497h-370.452v-16.497z" fill="#fff"/>
</svg>
</template>

<style scoped>
.rtl .global{
	margin-left: 5px !important;
}
</style>