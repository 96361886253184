import { createStore } from 'vuex'
import language from '../../src/language'

import aboutModule from './modules/main/about'
import homeModule from './modules/main/home'
import termsModule from './modules/main/terms'
import faqModule from './modules/main/faq'
import servicesModule from './modules/services/services'
import contactModule from './modules/main/contact'
import pricingModule from './modules/main/pricing'
import accountModule from './modules/account/account'
import billingModule from './modules/account/billing'
import quoteModule from './modules/services/requestQuote'
import signUpModule from './modules/authentication/signup'
import signInModule from './modules/authentication/signin'
import forgetModule from './modules/authentication/forget'

import signOutModule from './modules/authentication/signout'
import profileModule from './modules/account/profile'
import passwordModule from './modules/account/passwordsettings'
import ordersModule from './modules/account/orders'
import orderDocumentTranslationModule from './modules/services/orderDocumentTranslation'
import orderLegalTranslationModule from './modules/services/orderLegaltTranslation'
import orderInterpretationModule from './modules/services/orderInterpretation'
import proofReadingMocule from './modules/services/orderProofreading'
import joinUsModule from './modules/authentication/joinus'
import verifyModule from './modules/authentication/verify'
import PaymentModule from './modules/services/payment'


const store = createStore({
    modules: {
        about: aboutModule,
        home: homeModule,
        terms: termsModule,
        faq: faqModule,
        services: servicesModule,
        contact: contactModule,
        pricing: pricingModule,
        account: accountModule,
        billing: billingModule,
        quote: quoteModule,
        signup: signUpModule,
        signin: signInModule,
        forget: forgetModule,
        signout: signOutModule,
        profile: profileModule,
        password: passwordModule,
        orders: ordersModule,
        orderDocumentTranslation: orderDocumentTranslationModule,
        orderLegalTranslation: orderLegalTranslationModule,
        orderInterpretation: orderInterpretationModule,
        proofReading: proofReadingMocule,
        joinus: joinUsModule,
        verify: verifyModule,
        payment: PaymentModule
    },
    state() {
        return {

            user: localStorage.getItem('user') ?? null,
            token: localStorage.getItem('token') ?? null,
            lang: localStorage.getItem('lang') ?? 'en',
            content: language,
            // apiURL: 'https://aro-new.blackace.tech/api/',
            // postURL: "https://calm-falls-29246.herokuapp.com/",
            apiURL: 'http://192.168.1.9/aro-translation/public/api/',
            postURL: "",

        }
    },
    mutations: {
        setDocumentDirection(state) {
            if (state.lang === 'ku') {
                document.body.classList.add('rtl')
                document.body.classList.remove('ar')
                document.body.classList.add('ku')

            }
            else if (state.lang === 'ar') {
                document.body.classList.add('rtl')
                document.body.classList.remove('ku')
                document.body.classList.add('ar')
            }
            else {
                document.body.classList.remove('rtl')
                document.body.classList.remove('ku')
                document.body.classList.remove('ar')
            }
        },

        updateLanguage(state, payload) {
            state.lang = payload
            localStorage.setItem('lang', state.lang)


        },
        setAuth(state, payload) {
            // console.log("inside payload setAuth"+payload.user);
            state.user = payload.user
            state.token = payload.token
            localStorage.setItem('user', state.user)
            localStorage.setItem('token', state.token)

        },
        unsetAuth(state) {
            state.user = null
            state.token = null
            localStorage.clear()
            window.localStorage.clear()

        },
        setUser(state, payload) {
            state.user = payload.user
        },


    },
    actions: {
        updateLanguage(context, payload) {
            context.commit('updateLanguage', payload ?? localStorage.getItem('lang') ?? 'en')
            context.commit('setDocumentDirection')

        },

        setAuth(context, payload) {

            if (!payload) {

                if (localStorage.getItem('user') != null && localStorage.getItem('token') != null) {

                    context.commit('setAuth', {
                        user: localStorage.getItem('user'),
                        token: localStorage.getItem('token'),

                    })
                }
                else {
                    context.commit('unsetAuth')
                }

            }
            else {
                context.commit('setAuth', payload)

            }


        },





    },
    getters: {
        content(state) {
            return state.content[state.lang]

        },
        lang(state) {
            state.lang
        },
        isAuth(state) {
            if (state.token != null && state.user != null) {
                return true
            }
            return false
        },

        token(state) {
            return state.token
        },
        user(state) {
            return state.user
        }

    }


})

export default store