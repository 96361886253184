import http from '../../../httpRequests.js';

export default {
    state(){
        return{
          
            url:'user/login',
            userurl:'user/info',
            
           

        }
    }, 
  

    actions:{
        async signIn(context, payload){
            const res = 
         await http.post(context.state.url, context.rootState.lang,payload);
         console.log(res);
        return res;
        },
       async checkToken(context){
           if(context.getters.isAuth){
        const response= await http.get(context.state.userurl, context.rootState.lang, context.rootState.token);
       if(!response.user){
        context.commit('unsetAuth');
        console.log('no user');

       }  
       else{
           context.commit('setUser', response);
           console.log(context.getters.user);
       }
    }
        }
    }
,
   
}