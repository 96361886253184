<template>
  <div class="col-6 col-md-4 col-sm-6 col-lg-3">
    <div class="card bundle" style="border-radius: 15px; text-align: center">
      <div class="card-body">
        <div
          class="mb- mt-2 UpperCase"
          style="text-align: center; font-size: 28px"
        >
          {{ title }}
        </div>
        <div class="mb-4 mt-4" style="font-size: 42px">${{ price }}</div>
        <div class="card-text mb-2 capitalize">
          {{ content.getExtra }}<br />
          <h2>${{ extra }}</h2>
          {{ content.forFree }}
        </div>
        <button class="btn offer-btn mt-3 p-3 capitalize">
          {{ content.claimOffer }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "price", "extra"],
  computed: {
    content() {
      return this.$store.getters.content;
    },
  },
};
</script>

<style scoped>
.UpperCase {
  text-transform: uppercase;
}
.capitalize {
  text-transform: capitalize;
}
.bundle {
  min-width: 200px;
  margin-bottom: 20px;
  background: #2a6049;
  border: none;
  color: #fff;
  font-weight: 400;
  cursor: pointer;
  height: 270px;
  transition: 0.5s all;
}

.offer-btn {
  opacity: 0;
  position: absolute;
  transition: 0.5s all;
  box-shadow: none;
  left: 0;
  bottom: -100px;
  color: transparent;
  width: 100%;
  border-radius: 0px 0px 15px 15px;
}
.bundle:hover .offer-btn {
  opacity: 1;
  position: absolute;
  color: #fff;
  bottom: 0px;
  background: #afa68a;
  border-radius: 0px 0px 15px 15px;
}
.bundle:hover {
  height: 320px;
}
@media screen and (max-width: 1024px) {
  .bundle {
    height: 320px;
  }
  .bundle .offer-btn {
    opacity: 1;
    position: absolute;
    color: #fff;
    bottom: 0px;
    background: #afa68a;
    border-radius: 0px 0px 15px 15px;
  }
}
@media (max-width: 450px) {
  .bundle {
    min-width: auto;
  }
}
@media (max-width: 350px) {
  .bundle {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>