import http from '../../../httpRequests.js'

export default {
    state(){
        return{
            url:  'pages/home',
            homeContent: {}
        }
    }, 
    mutations:{
        setHomeContent(state, payload){
            state.homeContent= payload;

        }
    }, 

    actions:{
        async getHomeData(context){
        const response= await http.get(context.state.url, context.rootState.lang);
        context.commit('setHomeContent',response);
        }
    },
    getters:{
        homeContent(state ){
            return  state.homeContent;
        }
    }
}