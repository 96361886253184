<template>
  <Loader v-if="loading" />
  <div v-else>
    <div
      class="header-background"
      :style="'background: url(' + bannerData.photo + ');'"
    >
      <div class="container d-flex flex-column">
        <page-title
          :title="bannerData.title"
          style="margin-bottom: 30px; text-align: center"
        ></page-title>
        <div
          class="row justify-content-center"
          style="text-align: center; padding: 10px 40px"
        >
          <div class="mb-3 mt-3 col-3" style="width: 300px">
            <label class="d-block" style="font-size: 16px; color: #2a6049">{{
              bannerData.subtitle
            }}</label>
            <div class="clearfix"></div>
            <select class="form-select" v-model="service" required>
              <option value="documentTranslation" class="align-right">
                {{ content.documentTranslation }}
              </option>
              <option value="legalTranslation">
                {{ content.legalTranslation }}
              </option>
              <option value="interpretation">
                {{ content.interpretation }}
              </option>
              <option value="proofreading">{{ content.proofreading }}</option>
            </select>
          </div>
        </div>
      </div>
      <div
        class="container card pb-5"
        style="
          background: #ffffffdd;
          border-radius: 15px;
          border: 1px solid #e9e9e9;
          max-width: 1000px;
        "
      >
        <div>
          <div v-if="service == 'documentTranslation'" class="row">
            <div class="col-4 mb-3 mt-3">
              <label class="small d-block">{{ content.sourceLanguage }}</label>
              <div class="clearfix"></div>
              <select class="form-select" v-model="source" required>
                <option value="kurdish">Kurdish</option>
                <option value="english">English</option>
                <option value="arabic">Arabic</option>
              </select>
            </div>

            <div class="col-4 mb-3 mt-3">
              <label class="small d-block">{{ content.targetLanguage }}</label>
              <div class="clearfix"></div>
              <select class="form-select" v-model="target" required>
                <option value="kurdish">Kurdish</option>
                <option value="english">English</option>
                <option value="arabic">Arabic</option>
              </select>
            </div>

            <div class="col-4 mt-3 mb-3">
              <label class="small d-block">{{ content.specifyIndustry }}</label>
              <div class="clearfix"></div>
              <select
                class="form-select"
                v-model="industry"
                required
                @change="changeIndustry"
              >
                <option
                  v-for="type in documentTranslationPrices"
                  :key="type.id"
                  :value="type.id"
                >
                  {{ type.name }}
                </option>
              </select>
            </div>
          </div>
          <div v-if="service == 'legalTranslation'" class="row">
            <div class="col-4 mb-3 mt-3">
              <label class="small d-block">{{ content.sourceLanguage }}</label>
              <div class="clearfix"></div>
              <select class="form-select" v-model="source" required>
                <option value="kurdish">Kurdish</option>
                <option value="english">English</option>
                <option value="arabic">Arabic</option>
              </select>
            </div>

            <div class="col-4 mt-3 mb-3">
              <label class="small d-block">{{ content.targetLanguage }}</label>
              <div class="clearfix"></div>
              <select class="form-select" v-model="target" required>
                <option value="kurdish">Kurdish</option>
                <option value="english">English</option>
                <option value="arabic">Arabic</option>
              </select>
            </div>
            <div class="col-4 mb-3 mt-3">
              <label class="small d-block">{{ content.documentType }}</label>
              <div class="clearfix"></div>
              <select
                class="form-select"
                v-model="docId"
                required
                @change="selectedId"
              >
                <option
                  v-for="doc in legalTranslationPrices"
                  :key="doc.id"
                  :value="doc.id"
                >
                  {{ doc.type }}
                </option>
              </select>
            </div>
          </div>
          <div
            v-if="service == 'interpretation'"
            class="row justify-content-center"
          >
            <div class="col-4 mb-3 mt-3">
              <label class="small d-block">{{ content.sourceLanguage }}</label>
              <div class="clearfix"></div>
              <select class="form-select" v-model="source" required>
                <option value="kurdish">Kurdish</option>
                <option value="english">English</option>
                <option value="arabic">Arabic</option>
              </select>
            </div>

            <div class="col-4 mb-3 mt-3">
              <label class="small d-block">{{ content.targetLanguage }}</label>
              <div class="clearfix"></div>
              <select class="form-select" v-model="target" required>
                <option value="kurdish">Kurdish</option>
                <option value="english">English</option>
                <option value="arabic">Arabic</option>
              </select>
            </div>
          </div>
          <div
            v-if="service == 'proofreading'"
            class="row justify-content-center"
          >
            <div class="col-4 mb-3 mt-3">
              <label class="small d-block">{{ content.sourceLanguage }}</label>
              <div class="clearfix"></div>
              <select class="form-select" v-model="source" required>
                <option value="kurdish">Kurdish</option>
                <option value="english">English</option>
                <option value="arabic">Arabic</option>
              </select>
            </div>

            <div class="col-4 mt-3 mb-3">
              <label class="small d-block">{{ content.targetLanguage }}</label>
              <div class="clearfix"></div>
              <select class="form-select" v-model="target" required>
                <option value="kurdish">Kurdish</option>
                <option value="english">English</option>
                <option value="arabic">Arabic</option>
              </select>
            </div>
          </div>
        </div>

        <div
          v-if="service === 'documentTranslation'"
          class="col-md-12"
          style="align-self: center"
        >
          <div class="row justify-content-center align-items-center">
            <pricing-card
              v-for="industry in selectedIndustry.prices"
              :key="industry.id"
              :name="industry.tier"
              :lang="source + ' into  ' + target"
              :price="industry.price"
              :translators="industry.tierSub"
              route="/"
            ></pricing-card>

            <!--   <pricing-card name="PROFESSIONAL" :lang='source + " to " +target' :price="'0.12$'+ content.perWord"
            translators="One Translator + One Reviewer" route="/"></pricing-card> -->
          </div>
        </div>

        <div
          v-if="service === 'interpretation'"
          class="col-md-12 justify-content-evenly align-items-center"
        >
          <div class="row justify-content-center align-items-center">
            <pricing-card
              v-for="details in interpretationPrices"
              :key="details.id"
              :name="details.type"
              :lang="source + ' into  ' + target"
              :price="details.price"
              route="/orderinterpretation"
            ></pricing-card>
          </div>
        </div>

        <div
          v-if="service === 'proofreading'"
          class="col-md-12 justify-content-evenly align-items-center"
        >
          <div class="row justify-content-center align-items-center">
            <pricing-card
              v-for="details in proofreadingPrices"
              :key="details.id"
              :name="content.per + ' ' + details.unit"
              :lang="source + ' into  ' + target"
              :price="details.price"
              :unit="details.unit"
              route="/orderproofreading"
            ></pricing-card>
          </div>
        </div>

        <div
          v-if="service === 'legalTranslation'"
          class="col-md-12 justify-content-evenly align-items-center"
        >
          <div class="row justify-content-center align-items-center">
            <pricing-card
              :name="selectedDocument.type"
              :lang="source + ' into  ' + target"
              :price="selectedDocument.price"
              route="/orderlegaltranslation"
            ></pricing-card>
          </div>
        </div>
      </div>
    </div>

    <div
      class="container mt-5"
      style="
        justify-content: center;
        margin-bottom: 80px;
        min-height: 500px;
        max-width: 1000px;
        display: block;
      "
    >
      <div class="row">
        <div
          class="col-12 pb-5 mt-5"
          style="text-align: center; font-size: 24px; color: #2a6049 !important"
        >
          {{ content.saveMoneyWithBundle }}
        </div>
        <bundle
          v-for="bundle in bundlePrices"
          :key="bundle.id"
          :title="bundle.name"
          :price="bundle.credits"
          :extra="bundle.extra"
          @click="buyBundle(bundle.id)"
        ></bundle>
        <!-- 
        <bundle title="large" price="1000" extra="50"></bundle>
        <bundle title="x-large" price="2000" extra="110"></bundle>
        <bundle title="XXL" price="5000" extra="300"></bundle> 
      -->

        <div v-if="buy && !submitted">
          <form
            v-if="isAuth"
            class="alert alert-secondary mt-5"
            @submit.prevent="submitForm"
          >
            <div class="row">
              <div class="col-lg-12 mb-3">
                <h5>{{ content.choosePaymentMethod }}</h5>
              </div>
              <div class="col-4">
                <div class="form-check">
                  <input
                    id="cash"
                    class="form-check-input"
                    style="margin-top: 8px"
                    type="radio"
                    name="payment"
                    value="cash"
                    v-model="payment"
                  />
                  <label class="form-check-label pt-0" for="cash">
                    {{ content.invoiceDirectly }}
                  </label>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-check">
                  <input
                    id="online"
                    class="form-check-input"
                    type="radio"
                    style="margin-top: 8px"
                    name="payment"
                    value="online"
                    v-model="payment"
                  />
                  <label class="form-check-label pt-0" for="online">
                    {{ content.payOnline }}
                  </label>
                </div>
              </div>
              <div class="col-lg-4" style="text-align: right">
                <button type="submit" class="btn green-btn">
                  {{ content.claimOffer }}
                </button>
              </div>
              <!-- <div class="col-lg-4">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                          style="margin-top:8px"
                        name="payment"
                        value="credit"
                        v-model="payment"
                      />
                      <label class="form-check-label pt-0" for="r2">
                        {{ content.useAroCredit
                        }}<a class="btn link p-0">{{ content.buyCredits }}</a>
                      </label>
                    </div>
                  </div>-->
            </div>
          </form>
          <div v-else class="alert alert-warning m-3 mt-5">
            {{ content.youNeedTo }}
            <router-link class="alert-link" to="/signin">{{
              content.signIn
            }}</router-link>
            {{ content.toContinue }}
          </div>
        </div>
        <div v-if="buy && submitted">
          <div
            v-if="updateSuccess"
            class="alert alert-success alert-dismissible fade show"
            role="alert"
          >
            <strong>{{ content.success }}</strong>
            {{ content.bundlePurchaseSuccess }}
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>
          <div
            v-else
            class="alert alert-danger alert-dismissible fade show"
            role="alert"
          >
            <strong>{{ content.failed }}</strong>
            {{ content.bundlePurchaseFail }}
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bundle from "../../components/Bundle.vue";
import PricingCard from "../../components/PricingCard.vue";
import Loader from "../../components/ui/Loading.vue";

export default {
  data() {
    return {
      banner: null,
      service: "documentTranslation",
      source: "english",
      target: "kurdish",
      industry: 1,
      docId: 1,
      documentTranslationPrices: null,
      legalTranslationPrices: null,
      interpretationPrices: null,
      proofreadingPrices: null,
      bundlePrices: null,
      selectedDocument: {},
      selectedIndustry: {},
      buy: false,
      selectedBundle: null,
      payment: "cash",
      updateSuccess: null,
      submitted: null,
      loading: true,
    };
  },
  components: {
    PricingCard,
    Bundle,
    Loader,
  },
  computed: {
    isAuth() {
      return this.$store.getters.isAuth;
    },
    content() {
      this.loadData();
      return this.$store.getters.content;
    },
    bannerData() {
      if (this.banner != null) {
        return this.banner;
      } else {
        return "";
      }
    },
  },
  watch: {
    legalTranslationPrices() {
      this.selectedDocument = this.legalTranslationPrices[0];
    },
    documentTranslationPrices() {
      this.selectedIndustry = this.documentTranslationPrices[0];
    },
  },

  methods: {
    async submitForm() {
      this.response = await this.$store.dispatch("buyBundle", {
        bundle: this.selectedBundle,
        payment: this.payment,
        invoice_address:
          this.$store.getters.user.country +
          ", " +
          this.$store.getters.user.city +
          ", " +
          this.$store.getters.user.address +
          ". Phone:  " +
          this.$store.getters.user.phone,
      });

      console.log(this.response);
      if (this.response.status == "success") {
        if (this.response.url) {
          window.open(this.response.url, "_blank");
        } else {
          this.submitted = true;
          this.updateSuccess = true;
        }
      } else {
        this.updateSuccess = false;
        this.submitted = true;
      }
    },

    selectedId() {
      this.selectedDocument = this.legalTranslationPrices.find(
        (doc) => doc.id === this.docId
      );
      return this.selectedDocument;
    },
    changeIndustry() {
      this.selectedIndustry = this.documentTranslationPrices.find(
        (industry) => industry.id === this.industry
      );
      return this.selectedIndustry;
    },
    async loadData() {
      //banner
      await this.$store.dispatch("getPricingBanner");
      this.banner = this.$store.getters.pricingBanner;

      //doc tarns
      await this.$store.dispatch("getDocumentTranslationPrices");
      this.documentTranslationPrices =
        this.$store.getters.documentTranslationPrices;

      //legal trans
      await this.$store.dispatch("getLegalTranslationPrices");
      this.legalTranslationPrices = this.$store.getters.legalTranslationPrices;

      //interpretation Prices
      await this.$store.dispatch("getInterpretaionPrices");
      this.interpretationPrices = this.$store.getters.interpretationPrices;

      //proofreading Prices
      await this.$store.dispatch("getProofreadingPrices");
      this.proofreadingPrices = this.$store.getters.proofreadingPrices;

      //bundle Prices
      await this.$store.dispatch("getBundlePrices");
      this.bundlePrices = this.$store.getters.bundlePrices;

      this.loading = false;
    },
    buyBundle(id) {
      this.updateSuccess = null;
      this.submitted = null;
      this.buy = true;
      this.selectedBundle = id;
    },
  },
  created() {
    this.loadData();
    if (this.$route.params.pricingId == 1) {
      this.service = "documentTranslation";
    } else if (this.$route.params.pricingId == 2) {
      this.service = "legalTranslation";
    } else if (this.$route.params.pricingId == 3) {
      this.service = "interpretation";
    } else if (this.$route.params.pricingId == 4) {
      this.service = "proofreading";
    }
  },
};
</script>


<style scoped>
.border-right {
  border-right: 1px solid rgb(233, 233, 233);
}

@media (max-width: 992px) {
  .border-right {
    border: none;
  }
}
</style>