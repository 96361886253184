import http from '../../../httpRequests.js'

export default {
    state() {
        return {
            url: 'pages/pricing',
            dturl: 'industries',
            lturl: 'prices/legal-translation',
            iurl: 'prices/interpretation',
            purl: 'prices/proofreading',
            burl: 'bundles',
            durl: 'prices/delivery',
            buyBundleUrl: 'bundles/buy',
            bundlePaymentUrl: 'bundles/pay/',
            pricingBanner: {},
            documentTranslationPrices: {},
            legalTranslationPrices: {},
            interpretationPrices: {},
            proofreadingPrices: {},
            bundlePrices: {},
        }
    },

    mutations: {
        setPricingBanner(state, payload) {
            state.pricingBanner = payload.banner;

        },
        setDocumentTranslationPrices(state, payload) {
            state.documentTranslationPrices = payload.industries;

        },
        setLegalTranslationPrices(state, payload) {
            state.legalTranslationPrices = payload.prices;

        },
        setInterpretationPrices(state, payload) {
            state.interpretationPrices = payload.prices;

        },
        setProofreadingPrices(state, payload) {
            state.proofreadingPrices = payload.prices;

        }
        ,
        setBundlePrices(state, payload) {
            state.bundlePrices = payload.bundles;

        }
        ,
        setDeliveryPrices(state, payload) {
            state.deliveryPrices = payload.cities;
        }
    },

    actions: {
        async getPricingBanner(context) {
            const response = await http.get(context.state.url, context.rootState.lang);
            context.commit('setPricingBanner', response);
        },
        async getDocumentTranslationPrices(context) {
            const response = await http.get(context.state.dturl, context.rootState.lang);
            context.commit('setDocumentTranslationPrices', response);
        },
        async getLegalTranslationPrices(context) {
            const response = await http.get(context.state.lturl, context.rootState.lang);
            context.commit('setLegalTranslationPrices', response);
        },
        async getInterpretaionPrices(context) {
            const response = await http.get(context.state.iurl, context.rootState.lang);
            context.commit('setInterpretationPrices', response);
        },
        async getProofreadingPrices(context) {
            const response = await http.get(context.state.purl, context.rootState.lang);
            context.commit('setProofreadingPrices', response);
        },
        async getBundlePrices(context) {
            const response = await http.get(context.state.burl, context.rootState.lang);
            context.commit('setBundlePrices', response);
        },
        async getDeliveryPrices(context) {
            const response = await http.get(context.state.durl, context.rootState.lang);
            context.commit('setDeliveryPrices', response);
        },
        async buyBundle(context, payload) {
            const response = await http.post(context.state.buyBundleUrl, context.rootState.lang, payload, context.rootState.token);
            return response
        },
        bundlePayment: async (context, payload) => {
            const response = await http.post(context.state.bundlePaymentUrl + payload, context.rootState.lang, null, context.rootState.token)
            return response
        }
    },

    getters: {
        pricingBanner(state) {
            return state.pricingBanner;
        },
        documentTranslationPrices(state) {
            return state.documentTranslationPrices;
        },
        legalTranslationPrices(state) {
            return state.legalTranslationPrices;
        },
        interpretationPrices(state) {
            return state.interpretationPrices;
        },
        proofreadingPrices(state) {
            return state.proofreadingPrices;
        },
        bundlePrices(state) {
            return state.bundlePrices;
        },
        deliveryPrices(state) {
            return state.deliveryPrices;
        }

    }
}