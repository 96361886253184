<template>
  <PaymentResult
    :main="mainText"
    :text-one="content.paymentFailStatusCode[status] ?? 'status'"
    :text-two="content.paymentFailResponseCode[response] ?? 'response'"
  />
</template>

<script>
import PaymentResult from "../../components/layout/PaymentResult.vue";
import { statusCodes, responseCodes } from "../../errors";

export default {
  props: ["statusCode", "responseCode", "type"],
  components: { PaymentResult },
  computed: {
    content() {
      return this.$store.getters.content;
    },
    status() {
      return statusCodes[this.statusCode];
    },
    response() {
      let response = responseCodes[this.responseCode];

      if (this.status == response) response = "tryAgain";

      return response;
    },
    mainText() {
      return this.type == "bundle"
        ? this.content.bundlePurchaseFail
        : this.type == "order"
        ? this.content.orderPaymentFail
        : "";
    },
  },
};
</script>