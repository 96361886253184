import http from '../../../httpRequests.js'

export default {
    state(){
        return{
            url:  'pages/faq',
            faqContent: {}
        }
    }, 
    mutations:{
        setFaqContent(state, payload){
            state.faqContent= payload;

        }
    }, 

    actions:{
        async getFaqData(context){
        const response= await http.get(context.state.url, context.rootState.lang);
        context.commit('setFaqContent',response);
        }
    },
    getters:{
        faqContent(state ){
            return  state.faqContent;
        }
    }
}