<template>

    <div class="container shadow" >
        

    <form class="row" @submit.prevent="submitForm">
        <div class="d-block w-100">
  <div v-if="updateSuccess == false" class="well well-failed">
        <div>
          <h3>{{ content.failed }}</h3>
          <p>
            {{ content.passwordUpdateFailed }}
          </p>
        </div>
      </div>
      <div v-if="updateSuccess == true" class="well well-success">
        <div>
          <h3>{{ content.success }}</h3>
          <p>
            {{ content.passwordUpdateSuccess }}
            <button type="button" class="btn link" @click="dismiss" >Dismiss</button>
          </p>
        </div>
      </div>
        </div>
    <h3 class="mb-3" style="padding:20px 15px">{{content.Updatepassword}}</h3>
     <div class=" col-12 col-sm-6">
             <div class="input-group mb-3">
              <label class="small d-block"  >{{content.oldPassword}}</label> <div class="clearfix"></div>
              <input type="password"  class="form-control d-block"   autocomplete="off" v-model="oldPassword">
              <div class="clearfix m-0"></div>
            <p v-if="oldPasswordError" class="error-text">{{ oldPasswordError }}</p>
             </div>
          </div>  
              <div class=" col-12 col-sm-6">
             <div class="input-group mb-3">
              <label class="small d-block"  >{{content.newPassword}}</label> <div class="clearfix"></div>
              <input type="password"  class="form-control d-block"   autocomplete="off" v-model="newPassword">
              <div class="clearfix m-0"></div>
            <p v-if="newPasswordError" class="error-text">{{ newPasswordError }}</p>
             </div>
          </div>  
              
             
          
                <div style="text-align: right;padding: 40px 0px;display:flex;justify-content: flex-end;">
                    <button class="form-green-btn ">
                        
                          <Spinner v-if="submitted" />
              <span v-else>
                      {{content.update}}
              </span>
                        </button>
            </div>
    </form>
    </div>
</template>
<script>
import Spinner from "../../components/ui/Spinner.vue";

export default {
    components:{
        Spinner
    },
    data(){

        return {
            oldPassword: null,
            newPassword: null,
            newPasswordError:null,
            oldPasswordError:null,
            submitted: false,
            response: null,
            updateSuccess: null,

        }
    },
     computed:{
  content(){
    return this.$store.getters.content;
  }
},
methods:{
    validateOldPassword() {
      this.oldPasswordError = null;
      if (this.oldPassword === "" || this.oldPassword == null) {
        this.oldPasswordError = this.content.required;
      } else if (this.oldPassword.length < 8) {
        this.oldPasswordError = this.content.passwordFormat;
      }
    },
    validateNewPassword() {
      this.newPasswordError = null;
      if (this.newPassword === "" || this.newPassword == null) {
        this.newPasswordError = this.content.required;
      } else if (this.newPassword.length < 8) {
        this.newPasswordError = this.content.passwordFormat;
      }
    },
    dismiss(){
this.updateSuccess= null;
    },
     async submitForm() {
      this.submitted = true;
    
      this.validateNewPassword();
      this.validateOldPassword();
     

      if (
     
        !this.oldPasswordError &&
        !this.newPasswordError
      ) {
        this.response = await this.$store.dispatch("updatePassword", {
          old_password: this.oldPassword,
          new_password: this.newPassword,
         
        });

        if (this.response.result ) {
          this.submitted = false;
          this.updateSuccess = true;
        } else if (this.response.messages) {
          window.scrollTo(0, 0);
          this.updateSuccess = false;
          this.submitted = false;
            
          if (this.response.messages.credentials) {
            this.oldPasswordError = this.response.messages.credentials;
          }/*
          if (this.response.errors.email) {
            this.emailError = this.response.errors.email[0];
          } */
        }
         else {
             this.updateSuccess = false;
        this.submitted = false;
      }
      } else {
           
        this.submitted = false;
      }
    },
}
}
</script>

<style scoped>
.container{
    border-radius: 15px;
    background: #fffd;
}
</style>
