<template>
     <footer class="page-footer">
        <ul>
            <li><router-link to="/">{{content.home}}</router-link></li>
            <li><router-link to="/services">{{content.services}}</router-link></li>
            <li><router-link to="/faq">{{content.faq}}</router-link></li>
            <li><router-link to="/terms">{{content.terms}}</router-link></li>
            <li><router-link to="/contact">{{content.contactUs}}</router-link></li>
        </ul>
    </footer>
</template>

<script>
export default {
    computed:{
  content(){
    return this.$store.getters.content;
  }
}
}
</script>

<style scoped>
li {
    text-transform: uppercase;
}
.page-footer{
  position: absolute;
  width: 60%;
  

}

@media(max-width:992px) {
  .page-footer{
  position: absolute;
  width: 90%;
  margin: 0 5%;
  

}
}
</style>