import http from '../../../httpRequests.js'

export default {
    state(){
        return{
          
            url:  'pages/contact',
            contactContent: {},
        
           

        }
    }, 
    mutations:{
        setContactContent(state, payload){
            state.contactContent= payload;

        },
      
    }, 

    actions:{
        async getContactData(context){
        const response= await http.get(context.state.url, context.rootState.lang);
        context.commit('setContactContent',response);
       

        },
       
    }
,
    getters:{
        contactContent(state ){
            return  state.contactContent;
        },
       
    }
}