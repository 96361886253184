<template>
  <div class="col-md-12 d-flex justify-content-space-between mt-3 mb-1 pad0">
    <div class="order-view m-0">
      <div class="Order-id">{{ content.invoice }}#{{ invoice.id }}</div>
      <div>{{ content.date }} : {{ invoice.date }}</div>
      <div class="text-primary">
        {{ content.totalPrice }} : {{ invoice.total }}$
      </div>

      <img
        src="/static/images/download.png"
        class="download-icon"
        @click="print('body', 'aro')"
      />
    </div>
  </div>

  <div ref="invoice" :style="'display:' + hide">
    <div
      style="
        margin: 0px 0x !important;
        font-size: 32px;
        text-align: left;
        width: 100%;
        color: #fff;
        background: #2a6049 !important;
        padding: 10px 20px !important;
      "
    ></div>
    <div style="display: flex; justify-content: space-between">
      <img
        style="margin-top: 20px; height: 100px; padding: 20px !important"
        src="/static/images/logo.png"
      />
      <div
        class="aro"
        style="margin-top: 20px; padding: 20px !important; text-align: right"
      >
        <h1>Invoice</h1>
        <div style="margin-bottom: 10px">invoice# {{ invoice.id }}</div>
        <div style="margin-bottom: 10px">Invoice date : {{ invoice.date }}</div>
        <div style="margin-bottom: 10px">
          deadline : {{ invoice.request.sub.deadline }}
        </div>
        <div style="margin-bottom: 10px">
          Payment Method : {{ invoice.request.paymentMethod }}
        </div>
      </div>
    </div>
    <div
      style="
        margin-top: 50px;
        padding: 10px 20px !important;
        display: flex;
        justify-content: space-between;
      "
    >
      <div class="aro" style="width: 300px">
        <div style="margin-bottom: 10px; font-weight: 700">FullStop</div>

        <div style="margin-bottom: 10px">Address: Erbil, Iraq</div>
        <div style="margin-bottom: 10px">Phone: 0770 000 0000</div>
        <div style="margin-bottom: 10px">Email: info@arotranslation.com</div>
      </div>
      <div class="client" style="margin-left: 100px; text-align: right">
        <div style="margin-bottom: 10px; font-weight: 700">Bill To</div>
        <div style="margin-bottom: 10px">
          {{ invoice.user.firstName }} {{ invoice.user.lastName }},
          {{ invoice.user.company }}
        </div>

        <div style="margin-bottom: 10px">
          Address:
          <span
            >{{ invoice.user.city }}, {{ invoice.user.country }}<br />
            {{ invoice.user.address }}</span
          >
        </div>
        <div style="margin-bottom: 10px">Phone: {{ invoice.user.phone }}</div>
      </div>
    </div>
    <table style="width: 100%">
      <tr style="color: #fff; background: #2a6049">
        <th style="padding: 20px; width: 200">Item</th>
        <th style="padding: 20px; width: 600">Description</th>
        <th style="padding: 20px; width: 200">Price</th>
      </tr>
      <tr>
        <td
          style="
            padding: 20px;
            text-align: center;
            border-right: solid #ccc !important;
          "
        >
          {{ invoice.request.service }}
        </td>
        <td
          style="
            padding: 20px;
            text-align: center;
            border-right: solid #ccc !important;
          "
        >
          <div style="line-height: 30px">
            Source : {{ invoice.request.sub.source }}
            <div v-if="!!invoice.request.sub.target">
              Target : {{ invoice.request.sub.target }}
            </div>
          </div>
        </td>
        <td style="padding: 20px; text-align: center">
          {{ invoice.request.sub.subtotal }} $
        </td>
      </tr>
    </table>
    <h3 style="float: right; margin-top: 20px; padding: 20px !important">
      Total Price : {{ invoice.total }}$
    </h3>
    <div></div>
  </div>
</template>

<script>
/* import jsPDF from 'jspdf';
 */ export default {
  props: ["invoice"],
  methods: {
    print() {
      const contentHtml = this.$refs.invoice.innerHTML;
      var myWindow = window.open("", "", "width=992,height=1080");
      myWindow.document.write(contentHtml);
      myWindow.print();
    },
  },
  data() {
    return {
      hide: "none",
    };
  },
  computed: {
    content() {
      return this.$store.getters.content;
    },
  },
};
</script>

<style scoped>
.download-icon {
  height: 30px !important;
  cursor: pointer;
}
.order-view {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  line-height: 40px;
  padding: 15px 20px;
  border-radius: 15px;
  width: 100%;
  font-size: 14px;
  justify-content: space-between;
  border: 1px solid #d2d2d2;
}
.Order-id {
  font-weight: 600;
}
.status {
  background: #afa68a;
  padding: 0px 15px;
  border-radius: 25px;
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
}
div {
  margin: 0px 10px;
}
.pad0 {
  padding-left: 0;
  padding-right: 0;
}
</style>