import OrderDocumentTranslation from '../pages/services/OrderDocumentTranslation.vue'
import OrderLegalTranslation from '../pages/services/OrderLegalTranslation.vue'
import OrderInterpretation from '../pages/services/OrderInterpretation.vue'
import OrderProofreading from '../pages/services/OrderProofreading.vue'
import RequestQuote from '../pages/services/RequestQuote.vue'
import Profile from '../pages/account/Profile.vue'
import Orders from '../pages/account/Orders.vue'
import Account from '../pages/account/Account.vue'
import Billing from '../pages/account/Billing.vue'
import Bundles from '../pages/account/Bundles.vue'
import PasswordSetting from '../pages/account/PasswordSetting.vue'
import QLegalTranslation from '../pages/services/QuoteLegalTranslation.vue'
import QDocumentTranslation from '../pages/services/QuoteDocumentTranslation.vue'
import QProofreading from '../pages/services/QuoteProofreading.vue'
import QInterpretation from '../pages/services/QuoteInterpretation.vue'


export default [
    {
        path: '/OrderDocumentTranslation',
        component: OrderDocumentTranslation,
    },
    {
        path: '/OrderLegalTranslation',
        component: OrderLegalTranslation,
    },
    {
        path: '/OrderInterpretation',
        component: OrderInterpretation,
    },
    {
        path: '/OrderProofreading',
        component: OrderProofreading,
    },
    {
        path: '/quote',
        redirect: '/quote/documenttranslation',
        component: RequestQuote,
        children: [
            {
                path: '/quote/legaltranslation',
                component: QLegalTranslation
            },
            {
                path: '/quote/documenttranslation',
                component: QDocumentTranslation
            },
            {
                path: '/quote/interpretation',
                component: QInterpretation
            },
            {
                path: '/quote/proofreading',
                component: QProofreading
            },
        ]
    },
    {
        path: '/account',
        redirect: '/account/profile',
        component: Account,
        meta: { requiresAuth: true },
        children: [
            {
                path: '/account/profile',
                component: Profile,
                meta: { requiresAuth: true },
            },
            {
                path: '/account/orders',
                component: Orders,
                meta: { requiresAuth: true },
            },
            {
                path: '/account/billing',
                component: Billing,
                meta: { requiresAuth: true },
            },
            {
                path: '/account/bundles',
                component: Bundles,
                meta: { requiresAuth: true },
            },
            {
                path: '/account/passwordsetting',
                component: PasswordSetting,
                meta: { requiresAuth: true },
            }
        ]
    },
]