import http from '../../../httpRequests.js';

export default {
    state() {
        return {
            url: 'user/requests',
            userOrders: {},
        }
    },

    mutations: {
        setContent(state, payload) {
            state.userOrders = payload;
        }
    },

    actions: {
        async getOrders(context) {
            const res = await http.get(context.state.url, context.rootState.lang, context.rootState.token);
            context.commit('setContent', res.requests);
        }
    },

    getters: {
        orders(state) {
            return state.userOrders;
        },
    },
}