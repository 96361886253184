import http from '../../../httpRequests.js'

export default {
  state() {
    return {
      url: 'services/request/online/'
    }
  },
  actions: {
    orderPayment: async (context, payload) => {
      const response = await http.get(context.state.url + payload, context.rootState.lang, context.rootState.token)
      console.log(response)
      return response
    }
  }
}