<template>
  <div class="container shadow">
    <div class="row w-100 pb-5" v-if="!!invoices">
      <div class="d-flex justify-content-between align-items-center">
        <h3 style="padding: 20px 0px">{{ content.bundles }}</h3>
        <router-link to="/pricing" class="btn btn-success">
          {{ content.buyBundles }}
        </router-link>
      </div>
      <div>
        <div
          class="alert alert-success d-flex justify-content-between"
          role="alert"
        >
          <h6 class="mt-3">{{ content.remainingInYourAccount }}</h6>
          <h2 class="mt-1">$ {{ $store.getters.user.credits }}</h2>
        </div>
      </div>
      <div v-if="invoices != null && invoices.length > 0">
        <div class="list-group">
          <div
            v-for="invoice in invoices"
            :key="invoice.id"
            href="#"
            class="list-group-item list-group-item-action"
            aria-current="true"
          >
            <div
              class="
                d-flex
                w-100
                mb-2
                mt-2
                justify-content-between
                align-items-center
              "
            >
              <h5 class="mb-1">{{ content.bundles }} #{{ invoice.id }}</h5>
              <!-- <div> -->
              <!-- <button
                  v-if="
                    invoice.paymentMethod == 'online' &&
                    invoice.status == 'pending'
                  "
                  type="button"
                  class="mx-2 btn btn-warning rounded text-white rounded-pill"
                  @click="payInvoice(invoice.id)"
                >
                  {{ content.pay }}
                </button> -->

              <span
                v-if="invoice.status == 'pending'"
                class="badge bg-warning rounded-pill"
              >
                {{ content.pending }}
              </span>
              <!-- </div> -->
              <span
                v-if="invoice.status == 'paid'"
                class="badge bg-success rounded-pill"
              >
                {{ content.paid }}
              </span>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <p>
                {{ content.totalPrice }} : ${{ invoice.price }}

                <span
                  v-if="showPayment(invoice)"
                  @click="payInvoice(invoice.id)"
                  class="text-warning cursor-pointer fw-bold mx-2"
                >
                  {{ content.pay }}
                </span>
              </p>
              <p>{{ content.paymentMethod }} : {{ invoice.paymentMethod }}</p>
              <p>{{ content.date }} : {{ invoice.date }}</p>
            </div>
          </div>
        </div>
      </div>

      <div v-else-if="invoices != null && invoices.length == 0">
        <div class="well mb-5">
          <p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              version="1.1"
              id="Layer_1"
              x="0px"
              y="0px"
              viewBox="0 0 512 512"
              style="
                enable-background: new 0 0 512 512;
                width: 30px;
                height: 30px;
                opacity: 0.3;
              "
              xml:space="preserve"
            >
              <g>
                <g>
                  <path
                    d="M256,0C114.511,0,0,114.496,0,256c0,141.489,114.496,256,256,256c141.489,0,256-114.496,256-256    C512,114.511,397.504,0,256,0z M256,476.279c-121.462,0-220.279-98.817-220.279-220.279S134.538,35.721,256,35.721    S476.279,134.538,476.279,256S377.462,476.279,256,476.279z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M256,203.609c-9.864,0-17.86,7.997-17.86,17.86v146.456c0,9.864,7.997,17.86,17.86,17.86s17.86-7.997,17.86-17.86V221.47    C273.86,211.606,265.864,203.609,256,203.609z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M256,126.214c-9.864,0-17.86,7.997-17.86,17.86v21.433c0,9.864,7.997,17.86,17.86,17.86s17.86-7.997,17.86-17.86v-21.433    C273.86,134.211,265.864,126.214,256,126.214z"
                  />
                </g>
              </g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
            </svg>
            &nbsp; &nbsp;
            {{ content.noInvoice }}
          </p>
        </div>
      </div>
      <div v-else class="col-12">
        <div class="text-center pt-3">
          <GreenSpinner />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GreenSpinner from "../../components/ui/GreenSpinner.vue";
export default {
  components: {
    GreenSpinner,
  },
  data() {
    return {
      invoices: null,
    };
  },
  methods: {
    async loadData() {
      await this.$store.dispatch("getBundleInvoices");

      this.invoices = await this.$store.getters.bundleInvoices;
    },
    async payInvoice(id) {
      try {
        let x = await this.$store.dispatch("bundlePayment", id);
        window.open(x.url, "_blank");
      } catch (error) {
        console.log(error);
      }
    },
    showPayment(invoice) {
      return invoice.paymentMethod == "online" && invoice.status == "pending";
    },
  },
  created() {
    this.loadData();
  },
  computed: {
    content() {
      this.loadData();
      return this.$store.getters.content;
    },
  },
};
</script>

<style scoped>
.container {
  border-radius: 15px;
  background: #fffd;
}
</style>
