<template>
  <auth-header :title="content.forgetPasswordTitle" :text="content.forgetPasswordText"></auth-header>
  <div class="tab-form shadow mt-5" style="border-radius: 25px !important">
    <form class="row" @submit.prevent>
      <div class="col-md-12" v-if="step2 == false">
        <div class="input-group mb-3">
          <label class="small d-block">{{ content.email }}</label>
          <div class="clearfix"></div>
          <input
            type="text"
            class="form-control d-block"
            autocomplete="off"
            v-model="email"
          />
          <div class="clearfix m-0"></div>
          <p v-if="emailError" class="error-text">{{ emailError }}</p>
        </div>
      </div>

      <div
        class="mt-4 d-block d-md-flex term justify-content-end"
        v-if="step2 == false"
      >
        <div>
          <button class="btn termbtn backbtn" @click="back">
            {{ content.back }}
          </button>
          &nbsp; &nbsp;
          <button
            class="form-green-btn"
            style="overflow: hidden"
            @click="submitForm"
          >
            <span>
              {{ content.forgetPasswordTitle }}
            </span>
          </button>
        </div>
      </div>
      <div class="col-md-12" v-if="step2 == true">
        <div class="input-group mb-3">
          <label class="small d-block">{{ content.newPassword }}</label>
          <div class="clearfix"></div>
          <input
            type="password"
            class="form-control d-block"
            autocomplete="off"
            v-model="password"
          />
          <div class="clearfix m-0"></div>
        </div>
      </div>
      <div class="col-md-12" v-if="step2 == true">
        <div class="input-group mb-3">
          <label class="small d-block">{{ content.verificationCode }}</label>
          <div class="clearfix"></div>
          <input
            type="text"
            class="form-control d-block"
            autocomplete="off"
            v-model="code"
          />
          <div class="clearfix m-0"></div>
          <p v-if="passwordError" class="error-text">{{ passwordError }}</p>
        </div>
      </div>
      <div
        class="mt-4 d-block d-md-flex term justify-content-end"
        v-if="step2 == true"
      >
        <div>
          &nbsp; &nbsp;
          <button
            class="form-green-btn"
            style="overflow: hidden"
            @click="setNewPassword"
          >
            <span>
              {{ content.Updatepassword }}
            </span>
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import AuthHeader from "../../components/layout/AuthHeader";

export default {
  data() {
    return {
      emailError: null,
      passwordError: null,
      step2: false,
      email: null,
      password: null,
      code: null,
    };
  },
  computed: {
    content() {
      return this.$store.getters.content;
    },
  },
  components: {
    AuthHeader,
  },
  methods: {
    validateRequired(inputed) {
      if (inputed === "" || inputed == null) {
        return this.content.required;
      } else {
        return null;
      }
    },
    back() {
      this.$router.back();
    },
    async submitForm() {
      this.emailError = this.validateRequired(this.email);

      if (!this.emailError) {
        this.response = await this.$store.dispatch("forgetPassword", {
          email: this.email,
        });

        if (this.response.status == "success") {
          this.step2 = true;
        } else {
          this.emailError = "this email does not exist";
        }
      } else {
        this.submitted = false;
      }
    },
    async setNewPassword() {
      this.emailError = this.validateRequired(this.email);

      if (!this.emailError) {
        this.response = await this.$store.dispatch("resetPassword", {
          email: this.email,
          password: this.password,
          code: this.code,
        });

        if (this.response.status == "success") {
          this.$router.push("/signin");
        } else {
          this.passwordError = "Invalid Code";
        }
      }
    },
  },
};
</script>