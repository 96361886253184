import http from '../../../httpRequests.js';

export default {
    state(){
        return{
          
            url:'user/logout',
            
        }
    }, 
  

    actions:{
        async signOut(context ){
         await http.post(context.state.url, context.rootState.lang, {},context.rootState.token, true);
        
        }
    }
,
   
}