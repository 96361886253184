import http from '../../../httpRequests.js'

export default {
    state() {
        return {
            url: 'user/invoices',
            bundleUrl: "invoices/bundles",
            invoices: {},
            bundleInvoices: {}
        }
    },
    mutations: {
        setContent(state, payload) {
            state.invoices = payload;

        },
        setBundles(state, payload) {
            state.bundleInvoices = payload;
        }
    },

    actions: {
        async getInvoices(context) {
            const res = await http.get(context.state.url, context.rootState.lang, context.rootState.token);
            context.commit('setContent', res.invoices);

        },
        async getBundleInvoices(context) {
            const res = await http.get(context.state.bundleUrl, context.rootState.lang, context.rootState.token);
            context.commit('setBundles', res.invoices);

        }
    },
    getters: {
        invoices(state) {
            return state.invoices;
        },
        bundleInvoices(state) {
            return state.bundleInvoices;
        }
    }
}