<template>
  <div class="inner-container ">
    <router-link to="/"
      ><img src="/static/images/fullstopwhite-logo.png" class="form-logo"
    /></router-link>
    <div class="form-header">
      {{ title }} <br />
      <div class="auth-header-text" style="font-weight: 300; font-size: 14px">
        {{ text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "text"],
};
</script>