<template>
  <div class="container shadow orderContainer justify-content-center">
    <div class="row w-100">
      <h3 class="mb-3" style="padding: 20px 15px">{{ content.myOrders }}</h3>

      <div v-if="orders != null && orders.length > 0" class="col-12">
        <OrderCard
          v-for="order in orders"
          :key="order.id"
          :orderId="order.id"
          :date="order.date"
          :price="order.total"
          :status="order.status"
          :method="order.paymentMethod"
          @view="orderDetails(order)"
        ></OrderCard>
      </div>
      <div v-else-if="orders != null && orders.length == 0">
        <div class="well mb-5">
          <p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              version="1.1"
              id="Layer_1"
              x="0px"
              y="0px"
              viewBox="0 0 512 512"
              style="
                enable-background: new 0 0 512 512;
                width: 30px;
                height: 30px;
                opacity: 0.3;
              "
              xml:space="preserve"
            >
              <g>
                <g>
                  <path
                    d="M256,0C114.511,0,0,114.496,0,256c0,141.489,114.496,256,256,256c141.489,0,256-114.496,256-256    C512,114.511,397.504,0,256,0z M256,476.279c-121.462,0-220.279-98.817-220.279-220.279S134.538,35.721,256,35.721    S476.279,134.538,476.279,256S377.462,476.279,256,476.279z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M256,203.609c-9.864,0-17.86,7.997-17.86,17.86v146.456c0,9.864,7.997,17.86,17.86,17.86s17.86-7.997,17.86-17.86V221.47    C273.86,211.606,265.864,203.609,256,203.609z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M256,126.214c-9.864,0-17.86,7.997-17.86,17.86v21.433c0,9.864,7.997,17.86,17.86,17.86s17.86-7.997,17.86-17.86v-21.433    C273.86,134.211,265.864,126.214,256,126.214z"
                  />
                </g>
              </g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
            </svg>
            &nbsp; &nbsp;
            {{ content.noOrders }}
          </p>
        </div>
      </div>
      <div v-else class="col-12">
        <div class="text-center pt-3">
          <GreenSpinner />
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="orderDetails"
    tabindex="-1"
    role="dialog"
    aria-labelledby="PlaceOrderLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
      <div class="modal-content" style="border-radius: 15px">
        <div class="modal-header justify-content-center align-items-center">
          <h4 class="modal-title w-100" id="PlaceOrderLabel">
            {{ content.myOrder }} #{{ selectedOrder.id }}
            <div
              class="status"
              :class="
                selectedOrder.status === 'pending'
                  ? 'bg-warning'
                  : 'btn-success'
              "
            >
              {{ selectedOrder.status }}
            </div>
          </h4>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <h4 class="mb-5 mt-2">
                {{ selectedOrder.service }}
              </h4>
            </div>
            <div class="col-6 col-md-6 col-lg-4">
              <label>
                {{ content.date }}
              </label>
              <p>
                {{ selectedOrder.date }}
              </p>
            </div>
            <div class="col-6 col-md-6 col-lg-4">
              <label>
                {{ content.payment }}
              </label>
              <p>
                {{ selectedOrder.payment == false ? "no" : "yes" }}
              </p>
            </div>
            <div class="col-6 col-md-6 col-lg-4">
              <label>
                {{ content.paymentMethod }}
              </label>
              <p>
                {{ selectedOrder.paymentMethod }}
              </p>
            </div>
            <div class="col-6 col-md-6 col-lg-4">
              <label>
                {{ content.deadline }}
              </label>
              <p>
                {{ selectedOrder.sub.date ?? selectedOrder.sub.deadline }}
                {{
                  selectedOrder.sub.time ? " - " + selectedOrder.sub.time : ""
                }}
              </p>
            </div>
            <div class="col-6 col-md-6 col-lg-4">
              <label>
                {{ content.sourceLanguage }}
              </label>
              <p>
                {{ selectedOrder.sub.source }}
              </p>
            </div>
            <div
              v-if="selectedOrder.serviceId != 3"
              class="col-6 col-md-6 col-lg-4"
            >
              <label>
                {{ content.targetLanguage }}
              </label>
              <p>
                {{ selectedOrder.sub.target }}
              </p>
            </div>
            <div v-if="selectedOrder.sub.notes" class="col-6 col-md-6 col-lg-4">
              <label>
                {{ content.additionalComments }}
              </label>
              <p>
                {{ selectedOrder.sub.notes }}
              </p>
            </div>
            <div v-if="selectedOrder.sub.field" class="col-6 col-md-6 col-lg-4">
              <label>
                {{ content.field }}
              </label>
              <p>
                {{ selectedOrder.sub.field }}
              </p>
            </div>
            <div
              v-if="selectedOrder.sub.delivery == 1"
              class="col-6 col-md-6 col-lg-4"
            >
              <label>
                {{ content.delivery }}
              </label>
              <p v-if="selectedOrder.sub.city_id != null">
                City : {{ selectedOrder.sub.city_id.city }}
              </p>
              <p v-if="selectedOrder.sub.city_id != null">
                Price : {{ selectedOrder.sub.city_id.price }} $
              </p>
            </div>

            <div
              v-if="
                selectedOrder.sub.files && selectedOrder.sub.files.length > 0
              "
              class="col-12"
            >
              <hr />
              <label>
                {{ content.files }}
              </label>
              <ul class="list-group mt-3">
                <li
                  v-for="item in selectedOrder.sub.files"
                  :key="item.id"
                  class="list-group-item d-sm-flex justify-content-between align-items-center"
                >
                  <div
                    class="mb-0 d-flex justify-content-between align-items-center"
                    style="min-width: 90px"
                  >
                    <div c v-if="orders != null">
                      # {{ orders.indexOf(selectedOrder) + 1 }}
                    </div>
                    <span
                      v-if="!!item.completed"
                      class="badge bg-success rounded-pill"
                      >{{ content.done }}</span
                    >
                    <span
                      v-if="!!!item.completed"
                      :class="
                        selectedOrder.status === 'pending'
                          ? 'bg-warning'
                          : 'btn-success'
                      "
                      class="badge rounded-pill"
                      >{{ selectedOrder.status }}</span
                    >
                  </div>
                  <a class="link" :href="item.path" target="_blank" download>{{
                    content.downloadOriginalFile
                  }}</a>
                  <a
                    class="link"
                    v-if="!!item.result"
                    :href="item.result"
                    target="_blank"
                    download
                  >
                    {{ downloadResults }}
                  </a>
                </li>
              </ul>
            </div>
            <div v-else class="list-group-item d-sm-flex align-items-center">
              <div
                v-if="orders != null"
                class="mb-0 d-flex justify-content-between align-items-center"
                style="min-width: 30px"
              >
                # {{ orders.indexOf(selectedOrder) + 1 }}
              </div>
              <span
                :class="
                  selectedOrder.status === 'pending'
                    ? 'bg-warning'
                    : 'btn-success'
                "
                class="badge rounded-pill"
                >{{ selectedOrder.status }}</span
              >
            </div>

            <div class="col-12">
              <hr />
            </div>
            <div class="col-6"></div>
            <div class="col-6 text-right">
              <h6>
                {{ content.subtotal }} :
                <span
                  >${{
                    selectedOrder.serviceId != 2
                      ? selectedOrder.sub.subtotal
                      : selectedOrder.total
                  }}
                </span>
              </h6>

              <h6
                class="text-dark"
                v-if="selectedOrder.service === 'Translation'"
              >
                {{ content.delivery }} :
                <span>${{ selectedOrder.sub.delivery ?? 0 }} </span>
              </h6>
              <hr />
              <h4>
                {{ content.totalPrice }} :
                <span>${{ selectedOrder.total }} </span>
              </h4>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            {{ content.close }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import OrderCard from "../../components/OrderCard.vue";
import GreenSpinner from "../../components/ui/GreenSpinner.vue";
export default {
  data() {
    return {
      orders: null,
      selectedOrder: {
        id: 0,
        service: "--",
        date: "0000-00-00",
        payment: false,
        paymentMethod: "--",
        total: "00.00",
        status: "--",
        sub: {
          files: [],
        },
      },
    };
  },
  methods: {
    async loadData() {
      await this.$store.dispatch("getOrders");

      this.orders = await this.$store.getters.orders;

      console.log(this.orders);
    },
    orderDetails(order) {
      this.selectedOrder = order;
    },
  },
  created() {
    this.loadData();
  },

  computed: {
    content() {
      this.loadData();
      return this.$store.getters.content;
    },
  },

  components: {
    OrderCard,
    GreenSpinner,
  },
};
</script>

<style scoped >
.orderContainer {
  border-radius: 15px;
  background: #fffd;
}
.status {
  background: #32594f;
  padding: 0px 15px;
  border-radius: 25px;
  line-height: 25px;
  height: 25px;
  color: #fff;
  font-size: 10px;
  text-transform: uppercase;
  margin-top: 7px;
  float: right;
}
.text-right {
  text-align: right;
  color: #32594f;
}

.text-right span {
  min-width: 100px;
  display: inline-block;
}

@media (max-width: 600px) {
  .list-group li a {
    display: block;
    margin: 10px 0;
  }
}
</style>

