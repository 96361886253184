<template>
  <div
    class="pb-5 header-background"
    style="background: url(/static/images/order-bg.jpg)"
  >
    <form @submit.prevent="">
      <div class="container" style="max-width: 1000px; padding: 0">
        <div class="row flex-center">
          <page-title
            title="Place New Order"
            class="d-flex justify-content-center"
          ></page-title>

          <div
            class="page-heading"
            style="font-size: 24px; margin-top: 20px; margin-bottom: 80px"
          >
            {{ content.documentTranslation }}
          </div>

          <div class="shadow qoute-form mb-5 p-0 pb-5">
            <div style="display: flex">
              <button
                type="button"
                class="stepper"
                style="border-radius: 25px 0px 0px 0px"
              >
                <div :class="step1 ? 'active stepper-icon' : 'stepper-icon'">
                  1
                </div>
                {{ content.documentDetails }}
              </button>
              <button type="button" class="stepper">
                <div :class="step2 ? 'active stepper-icon' : 'stepper-icon'">
                  2
                </div>
                {{ content.choosePackage }}
              </button>
              <button
                type="button"
                class="stepper"
                style="border-radius: 0px 25px 0px 0px"
              >
                <div :class="step3 ? 'active stepper-icon' : 'stepper-icon'">
                  3
                </div>
                {{ content.payment }}
              </button>
            </div>
            <div v-if="isAuth">
              <div class="container d-block mt-4" v-if="step1">
                <div class="prices p-3" @click="$router.push('/pricing/1')">
                  {{ content.viewPrices }}
                </div>
                <div
                  class="col-md-12 mb-5 p-3"
                  style="color: #363636; font-size: 20px"
                >
                  {{ content.addDocumentDetails }}
                </div>

                <div class="row">
                  <div class="col-12">
                    <div class="input-group mb-3">
                      <div
                        class="d-flex w-100 flex-wrap"
                        style="justify-content: space-between"
                      >
                        <label class="small d-block" style="float: right">{{
                          content.uploadYourDocument
                        }}</label>
                        <label
                          class="small d-block"
                          style="
                            color: #2a6049;
                            font-weight: 500;
                            cursor: pointer;
                          "
                          >{{ content.supportedFiles }}(Pdf , Word , Excel ,
                          PowerPoint)</label
                        >
                      </div>
                      <div class="clearfix"></div>
                      <div class="d-block w-100">
                        <input
                          type="file"
                          class="form-control d-block"
                          style="font-size: 14px; padding: 2px 5px"
                          accept=".docx,.pdf, .doc, .xlsx, .xls, .pptx"
                          id="x"
                          @change="apicall"
                        />
                        <GreenSpinner
                          v-if="attaching"
                          style="position: absolute; top: 32px; right: 20px"
                        />
                        <div class="error-text">
                          {{ fileError }}
                        </div>
                      </div>
                    </div>

                    <div class="list-group list-group-flush mb-4">
                      <div
                        class="list-group-item d-flex p-0"
                        style="justify-content: space-between"
                        v-for="(file, index) in filesComputed"
                        :key="file"
                      >
                        <p class="mt-4">
                          {{ index + 1 }} . {{ file.file.name }}
                          <span class="badge bg-secondary rounded-pill">
                            {{ file.contted }} {{ content.words }}</span
                          >
                        </p>
                        <p class="mt-3">
                          <button
                            type="button"
                            class="btn btn-outline-danger"
                            @click="remove(index)"
                          >
                            {{ content.remove }}
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="col-12"><hr /></div>

                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                    <div class="input-group mb-3">
                      <label class="small d-block">{{
                        content.sourceLanguage
                      }}</label>
                      <div class="clearfix"></div>
                      <select class="form-select" v-model="source" required>
                        <option value="1">{{ content.english }}</option>
                        <option value="2">{{ content.arabic }}</option>
                        <option value="3">{{ content.kurdish }}</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                    <div class="input-group mb-3">
                      <label class="small d-block">{{
                        content.targetLanguage
                      }}</label>
                      <div class="clearfix"></div>
                      <select class="form-select" v-model="target" required>
                        <option value="1">{{ content.english }}</option>
                        <option value="2">{{ content.arabic }}</option>
                        <option value="3">{{ content.kurdish }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                    <div class="input-group mb-3">
                      <label class="small d-block">{{
                        content.specifyIndustry
                      }}</label>
                      <div class="clearfix"></div>
                      <select class="form-select" v-model="industry">
                        <option
                          v-for="type in documentTranslationPrices"
                          :key="type.id"
                          :value="type"
                        >
                          {{ type.name }}
                        </option>
                      </select>
                      <div class="clearfix"></div>
                      <div class="error-text">{{ industryError }}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                    <div class="input-group mb-3">
                      <label class="small d-block">
                        {{ content.YourDeadline }}</label
                      >
                      <div class="clearfix"></div>
                      <input
                        type="date"
                        class="form-control"
                        v-model="date"
                        :min="minDate"
                        required
                      />
                      <div class="clearfix"></div>
                      <div class="error-text">{{ deadlineError }}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                    <div class="input-group mb-3">
                      <label class="small d-block">{{
                        content.needStamped
                      }}</label>
                      <div class="clearfix"></div>
                      <select class="form-select" v-model="stamp">
                        <option value="1">{{ content.yes }}</option>
                        <option value="0">{{ content.no }}</option>
                      </select>
                    </div>
                  </div>

                  <div v-if="stamp == 1" class="col-md-6 col-sm-12 col-12">
                    <div class="input-group mb-3">
                      <label class="small d-block">
                        {{ content.physicalDelivery }}</label
                      >
                      <div class="clearfix"></div>
                      <select class="form-select" v-model="delivery">
                        <option value="1">{{ content.yes }}</option>
                        <option value="0">{{ content.no }}</option>
                      </select>
                    </div>
                  </div>

                  <div v-if="delivery == 1 && stamp == 1" class="col-12 p-0">
                    <hr class="mt-3" />
                    <h6 class="mb-4">{{ content.physicalCollection }}</h6>
                    <div
                      class="row mt-1 pr-0"
                      style="padding-right: 0"
                      v-if="delivery == 1 && stamp == 1"
                    >
                      <div class="col-md-6 col-sm-12">
                        <div class="input-group mb-3">
                          <label class="small d-block">
                            {{ content.contactName }}</label
                          >
                          <div class="clearfix"></div>
                          <input
                            type="text"
                            class="form-control"
                            v-model="contactName"
                          />
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <div class="input-group mb-3">
                          <label class="small d-block">
                            {{ content.phone }}
                          </label>
                          <div class="clearfix"></div>
                          <input
                            type="text"
                            class="form-control"
                            v-model="contactPhone"
                          />
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <div class="input-group mb-3">
                          <label class="small d-block">
                            {{ content.email }}
                          </label>
                          <div class="clearfix"></div>
                          <input
                            type="text"
                            class="form-control"
                            v-model="contactEmail"
                          />
                        </div>
                      </div>

                      <div class="col-md-6 col-sm-12">
                        <label class="small d-block">
                          {{ content.city }}
                        </label>
                        <select class="form-select" v-model="city">
                          <option
                            v-for="price in deliveryPrice"
                            :key="price"
                            :value="price"
                          >
                            {{ price.name }} ( $ {{ price.price }} )
                          </option>
                        </select>
                        <div class="error-text">
                          {{ cityError }}
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <div class="input-group mb-3">
                          <label class="small d-block">
                            {{ content.address }}
                          </label>
                          <div class="clearfix"></div>
                          <input
                            type="text"
                            class="form-control"
                            v-model="contactAddress"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12">
                    <button
                      type="button"
                      v-on:click.prevent="toStep2"
                      class="btn green-btn mt-4"
                    >
                      {{ content.next }}
                    </button>
                  </div>
                </div>
              </div>
              <div class="container d-block mt-4" v-if="step2">
                <div class="row">
                  <div
                    class="col-md-12 mb-5 p-3"
                    style="color: #363636; font-size: 20px"
                  >
                    {{ content.step2 }} {{ content.choosePackage }}
                  </div>
                  <div
                    class="col-md-12"
                    v-for="pricePackage in prices"
                    :key="pricePackage.id"
                  >
                    <div
                      class="card mb-3 packcard"
                      :class="pricePackage.id == packageID ? 'bg-light' : ''"
                    >
                      <div class="card-body row text-align-center">
                        <h5
                          class="
                            col-lg-3 col-sm-12 col-md-3
                            pt-3
                            d-flex
                            flex-wrap
                            align-items-center
                          "
                        >
                          {{ pricePackage.tier }}
                        </h5>
                        <p
                          class="
                            col-6 col-lg-3 col-sm-4 col-md-3
                            justify-content-start
                            pt-3
                            d-flex
                            flex-wrap
                            align-items-center
                            packprice
                          "
                        >
                          $
                          {{ pricePackage.price }}
                          {{ content.perWord }}
                        </p>
                        <p
                          class="
                            col-6 col-lg-4 col-sm-4 col-md-4
                            justify-content-start
                            pt-3
                            d-flex
                            flex-wrap
                            align-items-center
                          "
                        >
                          {{ pricePackage.tierSub }}
                        </p>
                        <div
                          class="
                            col-lg-2 col-sm-4 col-md-2
                            justify-content-sm-start justify-content-sm-end
                            d-flex
                            flex-wrap
                            align-items-center
                          "
                        >
                          <button
                            type="button"
                            class="btn btn-outline-success mt-2"
                            v-on:click.prevent="
                              setPackage(
                                pricePackage.id,
                                pricePackage.tier,
                                pricePackage.price
                              )
                            "
                          >
                            {{ content.select }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="error-text">{{ packageError }}</div>
                  <div class="col-md-12">
                    <div class="clearfix w-100"></div>
                    <button
                      type="button"
                      class="btn btn-light mt-4 m-l-4"
                      v-on:click.prevent="toStep1"
                    >
                      {{ content.back }}
                    </button>
                    &nbsp;
                    <button
                      type="button"
                      class="btn green-btn mt-4"
                      v-on:click.prevent="toStep3"
                    >
                      {{ content.next }}
                    </button>
                  </div>
                </div>
              </div>
              <div class="container d-block mt-4" v-if="step3">
                <div class="row">
                  <div
                    class="col mb-5 mt-1"
                    style="color: #363636; font-size: 20px"
                  >
                    {{ content.step3 }} {{ content.orderSummery }}
                  </div>
                  <div class="col" style="text-align: right">
                    <button
                      type="button"
                      class="btn btn-outline-success print-btn"
                      @click="print()"
                    >
                      {{ content.printInvoice }}
                    </button>
                  </div>

                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="col-6 col-sm-3">
                      <label class="small d-block">{{ content.package }}</label>
                      <div class="clearfix"></div>
                      <p>{{ packageName }}</p>
                    </div>
                    <div
                      class="col-6 col-sm-3 left-in-sm"
                      style="text-align: center"
                    >
                      <label class="small d-block">{{
                        content.documentType
                      }}</label>
                      <div class="clearfix"></div>
                      <p>{{ industry.name }}</p>
                    </div>
                    <div
                      class="col-6 col-sm-3 left-in-sm"
                      style="text-align: center"
                    >
                      <label class="small d-block">{{
                        content.costPerWord
                      }}</label>
                      <div class="clearfix"></div>

                      <p>$ {{ packagePrice }}</p>
                    </div>
                    <div class="col-6 col-sm-3 end-align left-in-sm">
                      <label class="small d-block">{{
                        content.languages
                      }}</label>
                      <div class="clearfix"></div>
                      <p>{{ language(source) }} into {{ language(target) }}</p>
                    </div>
                    <div class="col-6 col-sm-9 mt-3" v-if="delivery">
                      <label class="small d-block">{{
                        content.delivery
                      }}</label>
                      <div class="clearfix"></div>
                      <p>{{ address }}</p>
                    </div>
                    <div
                      v-if="delivery"
                      class="col-6 col-sm-3 left-in-sm mt-3 end-align"
                    >
                      <label class="small d-block">{{
                        content.deliveryCost
                      }}</label>
                      <div class="clearfix"></div>
                      <p>$ {{ deliveryCost }}</p>
                    </div>
                  </div>

                  <div class="col-lg-12 mt-4 alert alert-secondary bg-light">
                    <div class="row p-0">
                      <p class="col-6 col-sm-6 col-md-7">
                        <label class="small d-block">
                          {{ content.files }}
                        </label>
                      </p>
                      <p class="col-3 col-sm-3 col-md-3">
                        <label class="small d-block">
                          {{ content.totalWordCount }}</label
                        >
                      </p>
                      <p class="col-3 col-md-2 end-align">
                        <label class="small d-block">
                          {{ content.price }}
                        </label>
                      </p>
                    </div>
                    <hr />
                    <div
                      class="row p-0"
                      v-for="(file, index) in filesComputed"
                      :key="file"
                    >
                      <p class="col-6 col-sm-6 col-md-7 mt-4">
                        {{ index + 1 }} . {{ file.file.name }}
                      </p>
                      <p class="col-3 col-sm-3 col-md-3 mt-4">
                        {{ file.contted }}
                      </p>
                      <p class="mt-4 col-3 col-md-2 end-align">
                        $
                        {{ (packagePrice * file.contted).toFixed(2) }}
                      </p>
                    </div>
                  </div>

                  <div class="col-12 text-align-center end-align left-in-sm">
                    <h5 class="text-success mt-3">
                      {{ content.totalUSD }}

                      {{ invoiceTotal.toFixed(2) }}
                    </h5>
                  </div>

                  <div class="col-lg-12 mt-3 mb-5" style="text-align: right">
                    <hr />
                  </div>

                  <div class="col-lg-12 mb-5">
                    <h5>{{ content.choosePaymentMethod }}</h5>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-check">
                      <input
                        id="radio-cash"
                        class="form-check-input"
                        type="radio"
                        name="payment"
                        value="cash"
                        v-model="payment"
                      />
                      <label class="form-check-label pt-0" for="radio-cash">
                        {{ content.invoiceDirectly }}
                      </label>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-check">
                      <input
                        id="radio-online"
                        class="form-check-input"
                        type="radio"
                        name="payment"
                        value="online"
                        v-model="payment"
                      />
                      <label class="form-check-label pt-0" for="radio-online">
                        {{ content.payOnline }}
                      </label>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-check">
                      <input
                        id="radio-credit"
                        class="form-check-input"
                        type="radio"
                        name="payment"
                        value="credit"
                        v-model="payment"
                      />
                      <label class="form-check-label pt-0" for="radio-credit">
                        {{ content.useAroCredit
                        }}<a class="btn link p-0">{{ content.buyCredits }}</a>
                      </label>
                    </div>
                  </div>

                  <div class="col-lg-12 mt-3 mb-3 p-0">
                    <hr />

                    <div class="row mb-5 p-0" v-if="payment == 'cash'">
                      <div class="col-lg-4">
                        <label class="small d-block">
                          {{ content.delivery }}
                        </label>
                        <select class="form-select" v-model="invoiceDelivery">
                          <option value="personal">
                            {{ content.personalDelivery }}
                          </option>
                          <option value="physical">
                            {{ content.physicalCollection }}
                          </option>
                        </select>
                      </div>

                      <div
                        class="col-lg-8"
                        v-if="invoiceDelivery == 'physical'"
                      >
                        <div class="input-group mb-3">
                          <label class="small d-block">
                            {{ content.address }}
                          </label>
                          <div class="clearfix"></div>
                          <textarea
                            class="form-control"
                            v-model="invoiceAddress"
                          ></textarea>
                          <div class="clearfix"></div>
                          <p class="error-text">
                            {{ invoiceDeliveryError }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <p v-if="submitError" class="alert alert-warning mt-3">
                      {{ submitError }}
                    </p>
                    <div class="clearfix w-100"></div>
                    <button
                      type="button"
                      @click="toStep2"
                      class="btn btn-light mt-4 m-l-4"
                    >
                      {{ content.back }}
                    </button>
                    &nbsp;
                    <button
                      class="btn green-btn mt-4"
                      v-on:click.prevent="submitOrder"
                    >
                      {{ content.submitOrder }}</button
                    >&nbsp;&nbsp;&nbsp;&nbsp;
                  </div>
                </div>
              </div>
              <div class="container-xl mt-5" v-if="submit">
                <div v-if="!success" style="text-align: center">
                  <h5>
                    {{ content.processingOrder }}
                    <br />
                  </h5>
                  <GreenSpinner />
                </div>
                <div v-if="success" class="w-100 alert alert-success">
                  <h3>
                    {{ content.orderWasSubmitted }}
                  </h3>
                  <p class="mb-0">
                    {{ content.weReviewOrder }}
                    <router-link to="/" class="alert-link">{{
                      content.done
                    }}</router-link>
                  </p>
                </div>
                <div v-if="success == false" class="w-100 alert alert-danger">
                  <h3>{{ content.failedRequest }}</h3>
                  <p class="mb-0">
                    {{ content.weReviewOrder }}
                    <router-link to="/" class="alert-link">{{
                      content.back
                    }}</router-link>
                  </p>
                </div>
              </div>
            </div>
            <div v-else class="alert alert-warning m-3 mt-5">
              {{ content.youNeedTo }}
              <router-link class="alert-link" to="/signin">{{
                content.signIn
              }}</router-link>
              {{ content.toContinue }}
            </div>
          </div>
        </div>
      </div>
    </form>

    <div ref="invoice" :style="'display:' + hide">
      <div
        style="
          margin: 0px 0x !important;
          font-size: 32px;
          text-align: left;
          width: 100%;
          color: #fff;
          background: #2a6049 !important;
          padding: 10px 20px !important;
        "
      ></div>
      <div style="display: flex; justify-content: space-between">
        <img
          style="margin-top: 20px; height: 100px; padding: 20px !important"
          src="/static/images/logo.png"
        />
        <div
          class="aro"
          style="margin-top: 20px; padding: 20px !important; text-align: right"
        >
          <h1>Invoice</h1>

          <div style="margin-bottom: 10px">
            Invoice date : {{ todayDate() }}
          </div>
          <div style="margin-bottom: 10px">Deadline : {{ date }}</div>
          <div style="margin-bottom: 10px">Payment Method : {{ payment }}</div>
        </div>
      </div>
      <div
        style="
          margin-top: 50px;
          padding: 10px 20px !important;
          display: flex;
          justify-content: space-between;
        "
      >
        <div class="aro" style="width: 300px">
          <div style="margin-bottom: 10px; font-weight: 700">
            FullStop
          </div>

          <div style="margin-bottom: 10px">Address: Erbil, Iraq</div>
          <div style="margin-bottom: 10px">Phone: 0770 000 0000</div>
          <div style="margin-bottom: 10px">Email: info@arotranslation.com</div>
        </div>
        <div class="client" style="margin-left: 100px; text-align: right">
          <div style="margin-bottom: 10px; font-weight: 700">Bill To</div>
          <div style="margin-bottom: 10px">
            {{ user?.firstName }} {{ user?.lastName }},
            {{ user?.company }}
          </div>

          <div style="margin-bottom: 10px">
            Address:
            <span
              >{{ user?.city }}, {{ user?.country }}<br />
              {{ user?.address }}</span
            >
          </div>
          <div style="margin-bottom: 10px">Phone: {{ user?.phone }}</div>
        </div>
      </div>
      <table style="width: 100%">
        <tr style="color: #fff; background: #2a6049">
          <th style="padding: 20px; width: 200">Item</th>
          <th style="padding: 20px; width: 600">Description</th>
          <th style="padding: 20px; width: 200">Price</th>
        </tr>
        <tr>
          <td
            style="
              padding: 20px;
              text-align: center;
              border-right: solid #ccc !important;
            "
          >
            {{ content.documentTranslation }}
          </td>
          <td
            style="
              padding: 20px;
              text-align: center;
              border-right: solid #ccc !important;
            "
          >
            <div style="line-height: 30px">
              Source language: {{ language(source) }}
              <div v-if="!!target">Target language: {{ language(target) }}</div>
            </div>
            <div class="row p-0">
              <p class="col-3 col-sm-3 col-md-3 mt-4">
                {{ invoiceTotalWords }} Words
              </p>
            </div>
          </td>
          <td style="padding: 20px; text-align: center">
            total translation cost : $ {{ subtotal.toFixed(2) }}<br />
            <div v-if="delivery == 1">
              delivery Cost: $ {{ deliveryCost.toFixed(2) }}<br />
            </div>
            <div>translation cost per Word : $ {{ packagePrice }}<br /></div>
          </td>
        </tr>
      </table>
      <h3 style="float: right; margin-top: 20px; padding: 20px !important">
        Total Price : $ {{ invoiceTotal.toFixed(2) }}
      </h3>
      <div></div>
    </div>
  </div>
</template>

<script>
import GreenSpinner from "../../components/ui/GreenSpinner.vue";
export default {
  data() {
    return {
      //main request variables
      serviceId: 1,
      source: 1,
      target: 1,
      payment: "cash",
      invoiceAddress: "...",
      date: null,
      minDate: this.getMinDate(),
      deliveryPrice: 0,
      total: 0, //suntotal + delivery
      city: {},

      // Delivery and stamp Info
      delivery: 0,
      contactName: "",
      contactPhone: "",
      contactEmail: "",
      contactAddress: "",
      stamp: 0,

      //industry and package choices
      industry: null,
      documentTranslationPrices: null,
      packageID: null,
      packagePrice: 0,

      //ui variables
      step1: true,
      step2: false,
      step3: false,
      submit: false,
      invoiceDelivery: "personal",
      attaching: false,

      //files
      files: [],
      fileInfo: [],

      //errors

      fileError: null,
      industryError: null,
      deadlineError: null,
      cityError: null,

      packageError: null,
      invoiceDeliveryError: null,
      submitError: null,

      //submission
      success: null,
      response: null,
      hide: "none",
      res: [],
    };
  },
  mounted() {
    this.getMinDate();
  },
  methods: {
    getMinDate() {
      var today = new Date();
      var dd = today.getDate();

      var mm = today.getMonth() + 1;
      var yyyy = today.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }

      if (mm < 10) {
        mm = "0" + mm;
      }
      return (today = yyyy + "-" + mm + "-" + dd);
    },
    todayDate() {
      var today = new Date();
      var dd = today.getDate();

      var mm = today.getMonth() + 1;
      var yyyy = today.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }

      if (mm < 10) {
        mm = "0" + mm;
      }
      return (today = yyyy + "/" + mm + "/" + dd);
    },
    print() {
      const contentHtml = this.$refs.invoice.innerHTML;
      var myWindow = window.open("", "", "width=992,height=1080");
      myWindow.document.write(contentHtml);
      myWindow.print();
    },
    remove(index) {
      this.files.splice(index, 1);
    },

    async apicall(e) {
      this.attaching = true;
      let fileinfoo = 0;
      // let counter = 0;

      let file = e.target.files[0];

      let form = new FormData();

      form.append("file", file);
      let that = this;

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
        },
        body: form,
      };
      const fileInfo = await fetch(
        "https://calm-falls-29246.herokuapp.com/http://givemetext.okfnlabs.org/tika/tika/form",
        requestOptions
      );
      async function fetchStream() {
        const reader = await fileInfo.body.getReader();
        let charsReceived = 0;
        let result;

        reader.read().then(function processText({ done, value }) {
          if (done) {
            that.files[that.files.length] = { file, contted: fileinfoo };
            that.attaching = false;
            that.fileError = null;

            console.log("Stream complete and fileinfoo is " + fileinfoo);
            return;
          }

          charsReceived += value.length;
          console.log(value);
          const chunk = value;
          let finalText = "";
          chunk.forEach((el) => {
            finalText = finalText + String.fromCharCode(el);
          });
          let words = finalText.split(" ");
          console.log(words.length);
          fileinfoo = words.length;

          return reader.read().then(processText);
        });

        console.log(result + charsReceived);
      }

      await fetchStream();

      console.log(that);
    },

    setPackage(id, name, price) {
      this.packageID = id;
      this.packageName = name;
      this.packagePrice = price;
    },
    toStep1() {
      this.step1 = true;
      this.step2 = false;
      this.step3 = false;
      this.submit = false;
    },
    toStep2() {
      if (this.files.length < 1) {
        this.fileError = "File is required";
      } else {
        this.fileError = null;
      }

      if (this.industry == null || this.industry == "" || this.industry == 1) {
        this.industryError = "Industry is required.";
      } else {
        this.industryError = null;
      }
      if (this.date == null) {
        this.deadlineError = "Date is required.";
      } else {
        this.deadlineError = null;
      }

      if (this.stamp == 1 && this.delivery == 1 && this.cityId == 0) {
        this.cityError = "Delivery city is required.";
      } else {
        this.cityError = null;
      }

      if (
        this.fileError == null &&
        this.industryError == null &&
        this.deadlineError == null &&
        this.cityError == null
      ) {
        this.step1 = false;
        this.step2 = true;
        this.step3 = false;
        this.submit = false;
        window.scrollTo(0, 250);
      }
    },
    toStep3() {
      if (this.packageID != null) {
        this.packageError = null;
        this.step1 = false;
        this.step2 = false;
        this.step3 = true;
        this.submit = false;
      } else {
        this.packageError = "choose a package to continue";
      }
    },
    toStep4() {
      if (this.invoiceDelivery == "physical") {
        if (this.invoiceAddress == "..." || this.invoiceAddress == "") {
          this.invoiceDeliveryError =
            "please provide delivery address for your invoice  to continue";
          return false;
        }
      }

      this.invoiceDeliveryError = null;
      return true;
    },

    inputIsValid() {
      if (
        this.fileError == null &&
        this.industryError == null &&
        this.deadlineError == null &&
        this.cityError == null &&
        this.packageError == null
      ) {
        return true;
      } else {
        return false;
      }
    },

    async submitOrder() {
      if (this.inputIsValid() && this.toStep4()) {
        //submit order
        this.step1 = false;
        this.step2 = false;
        this.step3 = false;
        this.submit = true;
        window.scrollTo(0, 250);
        console.log("inputs are: ");

        //this.$store.dispatch('');

        this.response = await this.$store.dispatch(
          "orderDocumentTranslation",
          this.delivery == 0
            ? {
                service_id: this.serviceId,
                source: this.source,
                target: this.target,
                date: this.date,
                delivery: this.delivery,
                payment: this.payment,
                subtotal: this.subtotal,
                total: this.invoiceTotal,
                invoice_address: this.invoiceAddress,
              }
            : {
                service_id: this.serviceId,
                source: this.source,
                target: this.target,
                date: this.date,
                delivery: this.delivery,
                payment: this.payment,
                subtotal: this.subtotal,
                total: this.invoiceTotal,
                city: this.cityId,
                address: this.address,
                invoice_address: this.invoiceAddress,
              }
        );
        console.log(this.response);
        if (this.response.id) {
          for (let i = 0; i < this.filesComputed.length; i++) {
            //console.log(i);
            let form = new FormData();
            console.log(this.filesComputed[i].file);

            // console.log(this.response.sub.id);
            // console.log(this.industryId);
            // console.log(this.stamp);

            // console.log(this.filesComputed[i].contted * this.packagePrice);
            // console.log(this.packageID);

            form.append("file", this.filesComputed[0].file);
            form.append("request", this.response.sub.id.toString());
            form.append("industry", this.industryId.toString());
            form.append("stamp", this.stamp.toString());
            form.append(
              "total",
              (this.filesComputed[i].contted * this.packagePrice).toString()
            );
            form.append("price", this.packageID.toString());

            let res = await new Promise((resolve, reject) => {
              let xhr = new XMLHttpRequest();
              xhr.open(
                "POST",
                `${
                  this.$store.state.postURL + this.$store.state.apiURL
                }services/request/document-translation/file`,
                true
              );

              //  xhr.setRequestHeader("Content-Type", "multipart/form-data" );
              xhr.setRequestHeader("Accept", "application/json");
              xhr.setRequestHeader(
                "Authorization",
                "Bearer " + this.$store.getters.token
              );
              xhr.send(form);
              xhr.onloadend = () => {
                if (xhr.status >= 200 && xhr.status < 300) {
                  resolve(xhr.response);
                } else {
                  reject(
                    JSON.stringify({
                      status: xhr.status,
                      response: xhr.response,
                    })
                  );
                }
              };
            });
            // return res
            this.res[i] = res;

            this.success = true;
          }
          if (this.response.url) {
            window.open(this.response.url, "_self");
          }
        } else {
          this.success = false;
        }
      } else {
        //don't submit
        this.step1 = false;
        this.step2 = false;
        this.step3 = true;
        this.submit = false;
        this.submitError =
          "Unable to submit, please check all the required inputs";
      }
    },

    async loadData() {
      await this.$store.dispatch("getDeliveryPrices");
      this.deliveryPrice = await this.$store.getters.deliveryPrices;

      await this.$store.dispatch("getDocumentTranslationPrices");
      this.documentTranslationPrices = await this.$store.getters
        .documentTranslationPrices;
      this.industry = this.documentTranslationPrices[0].id;
    },
    setCity(cityName) {
      this.cityName = cityName;
    },
    language(id) {
      if (id == 1) {
        return this.content.english;
      } else if (id == 2) {
        return this.content.arabic;
      } else if (id == 3) {
        return this.content.kurdish;
      }
    },
  },
  computed: {
    isAuth() {
      return this.$store.getters.isAuth;
    },
    invoiceTotalWords() {
      let sum = 0;
      for (let i = 0; i < this.filesComputed.length; i++) {
        sum += parseFloat(this.filesComputed[i].contted);
      }
      return sum;
    },
    content() {
      this.loadData();

      return this.$store.getters.content;
    },

    cityId() {
      return this.city.id ?? 0;
    },
    cityName() {
      return this.city.name ?? "empty";
    },
    deliveryCost() {
      return Number(this.city.price ?? 0);
    },
    address() {
      return (
        "Contact Name : " +
        this.contactName +
        ",  Phone: " +
        this.contactPhone +
        ",  Email : " +
        this.contactEmail +
        ",  City : " +
        this.cityName +
        ", Contact Address Line: " +
        this.contactAddress
      );
    },
    user() {
      return this.$store.getters.user;
    },
    industryId() {
      return this.industry.id;
    },
    prices() {
      return this.industry.prices;
    },
    filesComputed() {
      return this.files;
    },
    invoiceTotal() {
      let total = 0;
      this.filesComputed.forEach((file) => {
        total += this.packagePrice * file.contted;
      });
      total += this.deliveryCost;
      return total;
    },

    subtotal() {
      let total = 0;
      this.filesComputed.forEach((file) => {
        total += this.packagePrice * file.contted;
      });

      return total;
    },
  },
  created() {
    this.loadData();
  },
  components: {
    GreenSpinner,
  },
};
</script>

<style scoped>
.card {
  border-radius: 15px;
}

.packcard h5 {
  text-align: left;
}

.packprice {
  text-align: left;
}
.card p,
.card h5,
.link {
  color: #2a6049;
}

.link {
  color: #2a6049;
  font-size: 12px;
  margin-left: 10px;
  font-weight: bold;
}

p {
  margin-top: 5px;
  font-size: 15px;
}

.form-check-label {
  font-size: 15px !important;
}

.row {
  margin: 0px !important;
}

.end-align {
  text-align: end;
}
@media (max-width: 767px) {
  .left-in-sm {
    text-align: start !important;
  }
}
</style>
