<template>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px"
    viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve" style="width:45px;" class="d-flex flex-row">
    <g stroke-opacity="1"  fill-opacity="0">
        <path :stroke="color" stroke-width="2" stroke-linecap="round"
            d="M58.464,19.597c-2.393-6.312-8.551-10.548-15.347-10.548c-6.878,0-13.062,4.306-15.41,10.722v13.221   l-0.415-0.004c-4.214-0.044-7.642-3.477-7.642-7.653c0-3.395,2.27-6.382,5.544-7.335c3.009-7.246,10.022-11.916,17.923-11.916   c7.911,0,14.928,4.679,17.932,11.937c3.232,0.976,5.472,3.955,5.472,7.314c0,4.176-3.428,7.609-7.642,7.653l-0.415,0.004V19.597z" />
        <path :stroke="color" stroke-width="2" stroke-linecap="round"
            d="M43.086,12.103c-7.355,0-13.317,5.91-13.317,13.201c0,1.183,0.172,2.324,0.466,3.415   c1.149,0.833,2.502,1.565,3.955,2.117c2.526,0.959,8.713,0.844,8.882,0.844c2.523,0,4.568,2.027,4.568,4.528   c0,0.563-0.108,1.099-0.298,1.596c5.265-1.762,9.06-6.687,9.06-12.5C56.403,18.014,50.44,12.103,43.086,12.103z" />
        <path :stroke="color" stroke-width="2" stroke-linecap="round"
            d="M42.635,39.628h-3.202c-1.791,0-3.256-1.465-3.256-3.256v0c0-1.791,1.465-3.256,3.256-3.256h3.202   c1.791,0,3.256,1.465,3.256,3.256v0C45.89,38.163,44.425,39.628,42.635,39.628z" />
        <path :stroke="color" stroke-width="2" stroke-linecap="round"
            d="M49.944,80.718c0-9.504,7.704-17.208,17.208-17.208c0.004,0,0.009,0.001,0.013,0.001l-0.774-10.435   c0-6.282-4.55-11.374-10.164-11.374H29.943c-5.613,0-10.164,5.092-10.164,11.374l-2.093,28.216h32.286   C49.966,81.1,49.944,80.912,49.944,80.718z" />
    
    </g>
</svg>
</template>

<script>
export default {
    props:['color']
}
</script>