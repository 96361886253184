import http from '../../../httpRequests.js'

export default {
    state() {
        return {
            url: 'pages/order',
            quoteContent: {}
        }
    },
    
    mutations: {
        setQuoteContent(state, payload) {
            state.quoteContent = payload;
        }
    },
    
    actions: {
        async getQuoteData(context) {
            const response = await http.get(context.state.url, context.rootState.lang);
            context.commit('setQuoteContent', response);
        }
    },
    
    getters: {
        quoteContent(state) {
            return state.quoteContent;
        }
    }
}