<template>
  <div v-if="services != null" class="header-background">
    <div class="container">
      <page-title :title="content.services"></page-title>
    </div>

    <div class="ServicesTab">
      <div class="container" style="position: relative">
        <div class="row">
          <div class="tab">
            <button
              class="tablinks btn11"
              :class="translation ? 'active' : ''"
              @click="stateTranslation"
            >
              <img :src="services[0].icon" class="services-icon" /><br />
              <div class="tablinks-label">{{ services[0].title }}</div>
            </button>
            <button
              class="tablinks"
              :class="interpretation ? 'active' : ''"
              @click="stateinterpretation"
            >
              <img :src="services[1].icon" class="services-icon" /><br />
              <div class="tablinks-label">{{ services[1].title }}</div>
            </button>
            <button
              class="tablinks"
              :class="proofreading ? 'active' : ''"
              @click="stateproofreading"
            >
              <img :src="services[2].icon" class="services-icon" /><br />
              <div class="tablinks-label">{{ services[2].title }}</div>
            </button>
          </div>

          <div
            id="DocumentTranslation"
            class="tabcontent mt-5"
            v-if="translation == true"
          >
            <h3>{{ services[0].title }}</h3>
            <div class="ServiceTextContent">
              {{ services[0].summary }}
              <div class="row" style="margin: 0px">
                <button
                  class="Services-inner-btn btn clearfix col-6"
                  @click="$router.push('/OrderDocumentTranslation')"
                >
                  {{ services[0].items.document }}
                </button>
                <button
                  class="Services-inner-btn btn clearfix col-6"
                  @click="$router.push('OrderLegalTranslation')"
                >
                  {{ services[0].items.legal }}
                </button>
              </div>
            </div>
          </div>

          <div
            id="Interpretation"
            class="tabcontent mt-5"
            v-if="interpretation == true"
          >
            <h3>{{ services[1].title }}</h3>
            <div class="ServiceTextContent">
              {{ services[1].summary }}
              <div class="row" style="margin: 0px">
                <button
                  class="Services-inner-btn btn clearfix"
                  @click="$router.push('/OrderInterpretation')"
                >
                  {{ content.orderInterpretation }}
                </button>
              </div>
            </div>
          </div>

          <div
            id="Proofreading"
            class="tabcontent mt-5"
            v-if="proofreading == true"
          >
            <h3>{{ services[2].title }}</h3>
            <div class="ServiceTextContent">
              {{ services[2].summary }}
              <div class="row" style="margin: 0px">
                <button
                  class="Services-inner-btn btn clearfix"
                  @click="$router.push('/Orderproofreading')"
                >
                  {{ content.orderProofreading }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <loader v-else></loader>
</template>

<script>
import Loader from "../../components/ui/Loading.vue";
export default {
  components: {
    Loader,
  },

  data() {
    return {
      translation: true,
      interpretation: false,
      proofreading: false,
      services: null,
    };
  },
  methods: {
    stateTranslation() {
      this.translation = true;
      this.interpretation = false;
      this.proofreading = false;
    },
    stateinterpretation() {
      this.translation = false;

      this.interpretation = true;
      this.proofreading = false;
    },
    stateproofreading() {
      this.translation = false;
      this.interpretation = false;
      this.proofreading = true;
    },
    async loadData() {
      await this.$store.dispatch("getServicesData");
      this.services = await this.$store.getters.servicesContent.services;
    },
  },
  computed: {
    content() {
      this.loadData();
      return this.$store.getters.content;
    },
  },
  created() {
    this.loadData();
    const serviceId = this.$route.params.serviceId;
    if (serviceId == "3") {
      this.stateproofreading();
    } else if (serviceId == "2") {
      this.stateinterpretation();
    } else {
      this.stateTranslation();
    }
  },
};
</script>

<style scoped>
.header-background {
  background: url(/static/images/serviceBackground.png);
  background-position-y: -200px !important;
}

.rtl .header-background {
  background: url(/static/images/serviceBackgroundRtl.png);
  background-position-y: -200px !important;
}
</style>