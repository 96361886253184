import { createApp } from 'vue'
import App from './App.vue'
    import router from './router/router'


import PageTitle from "./components/layout/PageTitle.vue"
import store from './store/index'
const app = createApp(App)

app.component('page-title',PageTitle);
app.use(router);

app.use(store);
app.mount('#app');