<template>
  <div
    class="pb-5 header-background"
    :style="'background: url(' + quoteContent?.photo + ');'"
  >
    <div class="container justify-content-center">
      <div class="quote-container" style="text-align: center">
        <page-title :title="quoteContent?.title"></page-title>
        <div style="margin-top: 30px; font-size: 20px; color: #2a6049">
          {{ quoteContent?.subtitle }}
        </div>

        <div class="col-md-5 mt-3">
          <div class="input-group">
            <div class="clearfix"></div>
            <select class="form-select" v-model="service" required>
              <optgroup :label="content.translationServices">
                <option value="documenttranslation">
                  {{ content.documentTranslation }}
                </option>
                <option value="legaltranslation">
                  {{ content.legalTranslation }}
                </option>
              </optgroup>
              <option value="interpretation">
                {{ content.interpretation }}
              </option>
              <option value="proofreading">{{ content.proofreading }}</option>
            </select>
          </div>
        </div>

        <div class="qoute-form shadow">
          <form class="mt-5">
            <router-view></router-view>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      service: "documenttranslation",

      quoteContent: null,
    };
  },
  computed: {
    content() {
      this.loadData();
      return this.$store.getters.content;
    },
  },
  watch: {
    service: function () {
      this.$router.push("/quote/" + this.service);
    },
  },
  methods: {
    async loadData() {
      await this.$store.dispatch("getQuoteData");
      this.quoteContent = await this.$store.getters.quoteContent.quote;
    },
  },
  created() {
    this.loadData();
  },
};
</script>

<style scoped>
select optgroup {
  background: #efefef;
  color: #363636;
  font-style: normal;
}
select optgroup option {
  background: #efefef;
  color: #363636;
}
</style>