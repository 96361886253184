import http from '../../../httpRequests.js'

export default {
    state(){
        return{
            url:  'pages/account',
            accountContent: {}
        }
    }, 
    mutations:{
        setAccountContent(state, payload){
            state.accountContent= payload;

        }
    }, 

    actions:{
        async getAccountData(context){
        const response= await http.get(context.state.url, context.rootState.lang);
        context.commit('setAccountContent',response);
        }
    },
    getters:{
        accountContent(state){
            return  state.accountContent;
        }
    }
}