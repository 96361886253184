import http from '../../../httpRequests.js';

export default {
    state(){
        return{
          
            url:'user/verify',
            
           

        }
    }, 
  

    actions:{
        async verify(context, payload){
        const response= await http.post(context.state.url, context.rootState.lang,payload,context.rootState.token);
        
       return response;
       

        }
    }
,
   
}