let paymentFailStatusCodes = {
  '01': 'cancel', // 'Payment canceled by user',// cancel
  '02': 'card', // 'invalid card number',//card
  '03': 'error', // 'invalid OTP',//password
  '04': 'maxTries', // 'you have exceeded max tries.',//lock
  '05': 'error', // 'failed',// error
  '08': 'error', // 'error',// error
  '09': 'error', // 'No response received',// error
  '10': 'error', // 'External error', //error
  '11': 'timeout', // 'Response timeout',// timeout
  '12': 'timeout', // 'PG timeout',//timeout
}
let paymentFailResponseCodes = {
  '01': 'card', // 'Refer to card issuer', //card
  '02': 'card', // 'Refer to card issuers special conditions', //card
  '03': 'env', // 'Invalid merchant', //env
  '04': 'error', // 'Pick-up',// error
  '05': 'error', // 'Do not honor', // error
  '06': 'error', // 'Error', // error
  '07': 'error', // 'Pick-up card, special conditions', //error
  '08': 'error', // 'identity', // 'Honor with identification',// identity
  '09': 'pending', // 'Request in progress',// pending
  '10': 'error', // ask // 'Approved for partial amount', // nazanm
  '11': 'success', // 'Approved (VIP)',// success
  '12': 'error', // 'Invalid transaction',//error
  '13': 'error', // 'Invalid amount',// error
  '14': 'card', // 'Invalid card number (no such number)',//card
  '15': 'card', // 'No such issuer',//card
  '16': 'error', // ask // 'Approved, update track 3',//success, nazanm
  '17': 'cancel', // 'Customer cancellation, reversal (unsupported)',//canceled
  '18': 'error', // 'Customer dispute, chargeback (future)',// error
  '19': 'error', // ask // 'Re-enter transaction',// nzanm
  '20': 'error', // 'Invalid response',//error
  '21': 'error', // 'No action taken, reversal (unsupported)',//error
  '22': 'error', // 'Suspected malfunction, reversal (unsupported)',//eror
  '23': 'error', // 'Unacceptable transaction fee',//error
  '24': 'error', // 'File update not supported by receiver (only in 03xx)',//error
  '25': 'error', // 'Unable to locate record on file (only in 03xx)',//error
  '26': 'error', // 'Duplicate file update record, no action (only in 03xx)',//error
  '27': 'error', // 'File update field edit error (only in 03xx)',//error
  '28': 'error', // 'File update record locked out (only in 03xx)',//error
  '29': 'error', // 'File update not successful, contact acquirer (only in 03xx)',//error
  '30': 'error', // 'Format error (may also be a reversal)',//error
  '31': 'bank', // 'Bank not supported by switch',// bank not supported
  '32': 'error', // ask // 'Completed partially, reversal (unsupported)',//nazanm
  '33': 'cardExpired', // 'Expired card, pick-up',//card expired
  '34': 'error', // 'Suspected fraud, pick-up',//error
  '35': 'error', // ask // 'Card acceptor contact acquirer, pick-up',//nazanm
  '36': 'card', // 'Restricted card, pick-up',// card
  '37': 'error', // ask // 'Card acceptor call acquirer security',// sus
  '38': 'maxTries', // 'Allowable PIN tries exceeded, pick-up', // max tries
  '39': 'account', // 'No credit account',// account
  '40': 'error', // 'Requested function not supported',// error
  '41': 'cardLost', // 'Lost card, pick-up',// card lost
  '42': 'error', // 'No universal account',// error
  '43': 'cardStolen', // 'Stolen card, pick-up',//card stolen
  '44': 'account', // 'No investment account',//account
  '51': 'account', // 'Insufficient funds',//account
  '52': 'account', // 'No checking account',// account
  '53': 'account', // 'No savings account',// account
  '54': 'cardExpired', // 'Expired card',// card expire
  '55': 'pin', // 'Incorrect PIN', // pin
  '56': 'card', // 'No card record',// card
  '57': 'card', // 'Transaction not permitted to cardholder', // card
  '58': 'error', // 'Transaction not permitted to terminal (may also be a chargeback)', // error
  '59': 'sus', // 'Suspected fraud', // sus
  '60': 'error', // 'Card acceptor contact acquirer', // error
  '61': 'error', // 'Exceeds withdrawal amount limit', // error
  '62': 'card', // 'Restricted card',// card
  '63': 'error', // 'Security violation (may also be a chargeback)', // error
  '64': 'transaction', // 'Original amount incorrect, reversal (unsupported)', // transaction
  '65': 'error', // 'Exceeds withdrawal frequency limit',// error
  '66': 'error', // 'Card acceptor call acquirer security', // error
  '67': 'error', // ask // 'Hard capture, pick-up',// 
  '68': 'timeout', // 'Response received too late, reversal (unsupported)',// timeout
  '75': 'pinLimit', // 'Allowable number of PIN tries exceeded',//max pin tries
  '76': 'error', // 'Key synchronization error(FIS)',// error
  '78': 'error', // 'Customer not eligible for POS (StarSM)',// error
  '79': 'error', // 'Invalid digital signature',//  error
  '80': 'timeout', // 'Stale dated transaction (StarSM)',// timeout 
  '81': 'error', // 'Issuer requested standin',// error
  '82': 'error', // 'Count exceeds limit (VISANet)',// error
  '84': 'timeout', // 'Time limit for pre-authorization reached (VISANet)',// timout
  '85': 'error', // 'Reserved for private use',// error
  '86': 'pinVerification', // 'Cannot verify PIN (VISANet)',// pin 
  '87': 'error', // 'Check already posted',// error
  '88': 'error', // 'Information not on file',// error
  '89': 'error', // 'Card verification value (CVV) verification failed (no pickup)',// error
  '90': 'error', // 'Cutoff is in progress',// error
  '91': 'error', // 'Issuer or switch is inoperative',// error
  '92': 'error', // 'Financial institution or intermediate network unknown for routing', // error
  '93': 'violation', // 'Transaction cannot be completed, violation of law',// violation
  '94': 'duplicate', // 'Duplication transaction',// duplicate
  '95': 'error', // 'Reconcile error',// error
  '96': 'system', // 'System malfunction',// system
}

module.exports = {
  statusCodes: paymentFailStatusCodes,
  responseCodes: paymentFailResponseCodes
}