import http from '../../../httpRequests.js'

export default {
    state(){
        return{
            url:  'pages/terms',
            termsContent: {}
        }
    }, 
    mutations:{
        setTermsContent(state, payload){
            state.termsContent= payload;

        }
    }, 

    actions:{
        async getTermsData(context){
        const response= await http.get(context.state.url, context.rootState.lang);
        context.commit('setTermsContent',response);
        }
    },
    getters:{
        termsContent(state){
            return  state.termsContent;
        }
    }
}