const language = {
    ku: {
        planAndPrice: 'پلان و نرخی تایبەتت دەوێت؟ ، کێشە نیە.',
        quoteHeader: 'دەرخستە',
        language: 'زمان',
        arabic: 'عربي',
        kurdish: 'کوردی',
        english: 'ئینگلیزی',
        contactUs: 'پەیوەندی',
        faq: 'پرسیارە باوەکان',
        terms: 'مەرجەکان',
        placeOrder: ' داوا بکە',
        getQuote: ' دەرخستە',
        translationServices: 'خزمەتگوزاری وەرگێڕان',
        documentTranslation: 'وەرگێڕانی پەڕاو',
        legalTranslation: 'وەرگێڕانی یاسای',
        interpretation: ' وەرگێڕانی زارەکی',
        proofreading: '‌‌هەڵەچنی',
        home: 'سەرەتا',
        services: 'خزمەتگوزاریەکان',
        homeU: 'سەرەتا',
        servicesU: 'خزمەتگوزاریەکان',
        termsU: 'مەرجەکان',
        pricing: 'نرخەکان',
        about: 'دەربارە',
        account: 'هەژمار',
        youNeedTo: 'پێویستت بە ',
        signIn: 'چوونەژوەرەوە',
        toContinue: 'یە بۆ بەردەوام بوون',
        signUp: 'تۆمارکردن',
        getQuoteU: 'دەرخستە',
        placeOrderU: 'داوا بکە ',
        ourServices: 'خزمەتگوزاریەکانمان',
        translation: 'وەرگێڕان',
        LegalDocumentType: 'جۆری پەڕاوی یاسای',
        pricingHome: 'نرخی گونجاو و هەرزان',
        viewPricing: 'بینینی نرخەکان',
        ourPricing: 'نرخەکانمان',
        getInstantQuote: 'دەرخستە',
        ourQualities: ' تایبەتمەندیەکانمان',
        rates: 'رێژەکان',
        quality: 'کوالێتی',
        speed: 'خێرای',
        wantJoinUs: 'دەتەوێت کارمان لەگەڵ بکەیت',
        submitApplication: 'ناردنی داواکاری',
        whatIsAro: 'ئارۆ ترانسلەیشن چیە؟',
        vision: 'ڕووانینمان',
        mission: 'ڕووانینمان',
        whyAro: 'بۆ ئارۆ هەڵبژێریم ؟',
        joinUs: 'کارمان لەگەڵ بکە',
        contactUsU: 'پەیوەندی',
        howCanWeHelp: 'چۆن دەتوانین یارمەتیت بدەین',
        contactFormText: ' چۆن یارمەتیت بدەین؟',
        send: 'ناردن',
        existingOrder: 'پێویستیم بە یارمەتیە لە کڕینێکی پێشوو',
        salesTeam: 'باسکردنی پڕۆژەکەت لەگەڵ تیمی فرۆشتن',
        becomeTranslator: 'ببە وەرگێڕ لە  ئارۆ ترانسلەیشن',
        fullName: 'ناوی تەواو',
        phone: 'ژمارەی مۆبایل',
        businessEmail: 'ئیمەیڵ',
        yourMessage: 'پەیامەکەت',
        attachment: 'هاوپێچ',
        email: 'ئیمەیڵ',
        subject: 'بابەت',
        address: 'ناونیشان',
        FAQA: 'پرسیارە  باوەکان',
        chooseService: 'خزمەتگوزاریەک هەڵبژێرە',
        sourceLanguage: 'زمانی ڕەسەن/ بنچینەیی',
        targetLanguage: 'زمانی داواکراو',
        specifyIndustry: 'جۆری پیشەسازی',
        documentType: 'جۆری پەڕاو',
        saveMoneyWithBundle: 'کڕینی پلانەکانمان پاشەکەوت بکە',
        documentDetails: 'دەربارەی پەڕاو',
        choosePackage: 'پاکێجێک هەلبژێرە',
        payment: 'پارەدان',
        viewPrices: 'بینینی نرخەکان',
        addDocumentDetails: 'دانانی زانیاری پەڕاو ',
        uploadYourDocument: 'بارکردنی پەڕاو',
        supportedFiles: 'پەڕاوی پشگیریکراو',
        needStamped: 'ئایا پەڕاوە وەرگێڕدراوەکەت پێویستە مۆر بکرێت ؟ ئاماژەی پێ بدە',
        yes: 'بەڵی',
        no: 'نەخێر',
        physicalDelivery: 'پێویستیت بە گەیاندن هەیە بۆ وەرگێڕانەکەت؟',
        contactName: 'ناوی بەشداربوو',
        next: 'دواتر',
        back: 'گەڕانەوە',
        step1: ' هەنگاوی ١ : ',
        step2: 'هەنگاوی ٢ : ',
        step3: 'هەنگاوی ٣ : ',

        standard: 'ستاندارد',
        oneTranslator: 'یەک وەرگێڕ',
        select: 'هەڵبژاردن',
        perWord: ' بۆ وشەیەک',
        perPage: ' بۆ پەڕەیەک',
        professional: 'پڕۆفێشناڵ',
        oneReviewer: 'یەک پێداچووەوە',
        orderSummery: 'پوختەی داواکاری',
        printInvoice: 'چاپکردن',
        package: 'پاکێج',
        languages: 'زمانەکان',
        totalWordCount: 'کۆی گشتی وشەکان',
        WordCount: ' کۆی وشەکان',

        costPerWord: 'تێچوو بۆ یەک وشە',
        totalPrice: 'نرخ',
        choosePaymentMethod: 'جۆری پارەدان دیاری بکە',
        invoiceDirectly: 'پارەدانی ڕاستەوخۆ',
        payOnline: 'ئۆنڵاین',
        useAroCredit: 'بەکارهێنانی کۆینی ئارۆ',
        buyCredits: 'کڕینی کۆین',
        personalDelivery: 'گەیاندنی کەسی',
        physicalCollection: 'کۆکردنەوە لە لایەن ئارۆوە',
        submitOrder: 'پێشکەشکردنی کڕین',
        orderWasSubmitted: 'داواکرایەکەت بە سەرکەوتووی پێشکەش کرا',
        weReviewOrder: 'بە زوترین کات سێری ئەکەین و ئاگادارت ئەکەینەوە',
        done: 'سەرەتا',
        interpretationDetails: 'زانیاری وەرگێڕانی  زارەکی',
        addInterpretationDetails: 'دانانی زانیاری وەرگێڕانی  زارەکی',
        specifyInterpretationType: 'جۆری وەرگێڕانی زارەکی دیاری بکە',
        simultaneous: 'وەگێڕانی هاوکات/ڕاستەخۆ',
        consecutive: 'وەرگێڕانی زنجیرەیی',
        field: 'بوار',
        dateInterpretation: 'بەرواری وەرگێرانی زارەکی دیاری بکە',
        startTime: 'کاتی دەستپێکردن',
        additionalComments: 'تێبینی',
        contactInformation: 'زانیاری پەیوەندی',
        firstName: 'ناوی یەکەم',
        lastName: 'ناوی دووەم',
        companyName: 'ناوی کۆمپانیا',
        interpretationType: 'جۆری وەرگێڕانی  زارەکی',
        imageScanDetails: 'زانیاری وێنە/ سکان',
        addImageScanDetails: 'دانانی وێنە / سکان',
        chooseImagesScans: "وێنە / سکان هەڵبژێرە",
        chooseLegalDocumentType: 'جۆری بەڵگەنامەی یاسای دیاری بکە',
        YourDeadline: 'دوا وادە بۆ وەرگێڕانەکەت دیاری بکە',
        pageNotFound: 'ئەم پەڕەیە نەدۆزرایەوە',
        goHomePage: 'گەڕانەوە بۆ سەرەتا',
        whyWorkWithAro: 'بۆچی دەتەوێت لەگەڵ ئارۆ کار بکەیت؟',
        uploadCv: 'بارکردنی سیڤی',
        password: 'وشەی نهێنی',
        forgetPassword: 'وشەی نهێنیم لەبیرکردوە',
        dontHaveAccount: "هەژمارم نیە",
        country: 'وڵات',
        city: 'شار',
        clickSignUp: "کە تۆمار کردن کرد تۆ ڕازی ئەبیت بە ",
        alreadyMember: 'هەژمارت هەیە؟',
        profileSetting: 'رێکخستنی هەژمار',
        passwordSetting: 'رێکخستنی وشەی نهێنی',
        orders: 'داواکاریەکان',
        billing: 'کڕاوەکان',
        invoice: 'پسولە',
        date: 'بەروار',
        paymentMethod: 'جۆری پارەدان',
        myOrders: 'دواکاریەکانم',
        myOrder: 'دواکاری',
        Updatepassword: 'نوێکردنەوەی وشەی نهێنی',
        oldPassword: 'وشەی نهێنی کۆن',
        newPassword: 'وشەی نهێنی نوێ',
        confirmPassword: 'دووبارە وشەی نهێنی نوێ',
        update: 'نوێکردنەوە',
        updateProfile: 'نوێکردنەوەی هەژمار',
        saveChanges: 'تۆمارکردن',
        orderNow: 'کڕین',
        getExtra: 'بکڕە و بڕی',
        forFree: 'بەدەستبهێنە',
        claimOffer: 'بەشداریکردن',
        order: 'داواکردن',
        orderDocumentTranslation: 'وەرگێڕانی پەڕاوی ئاسای',
        orderLegalTranslation: 'وەرگێڕانی پەڕاوی یاسای',
        orderInterpretation: 'وەرگێڕانی ڕاسەتەوخۆ',
        orderProofreading: 'پێداچونەوە',
        apply: 'ناردن',
        registerText: 'خۆت تۆماربکە لە ئارۆ زانیاریەکانت لە خوارەوە بنووسە',
        createAccount: 'هەژمار دروست بکە',
        enterDetailsBelow: 'زانیاریەکانت لە خوارەوە بنووسە',
        signInToAro: 'چوونەژوورەوە بۆ ئارۆ',
        haveQuestions: ' پرسیارت هەیە؟ لەگەڵ تیمەکەمان قسە بکە.',
        translator: 'وەرگێڕ',
        client: 'بەکارهێنەر',
        quickLinks: 'لینکەکان',
        required: 'ئەم خانەیە داواکراوە',
        emailFormat: "ئیمەیلەکەت هەڵەیە.",
        phoneFormat: "ژمارەکەت هەڵەیە. پێویستە بەم شێوازە بێت 077x xxx xxxx",
        passwordFormat: "تێپەڕەوشە پێویستە ٨ پیت یان زیاتر بێت.",
        failed: "!کارەکە سەرکەوتوو نەبوو",
        accountRegisterationFailed: "دروستکردنی هەژمارەکەت سەرکەوتوو نەبوو. بۆ زانیاری زیاتر، تەماشای خانەکان بکە.",
        success: "کارەکە سەرکەوتوو بوو!",
        accountRegisterationSuccess: "دروستکردنی هەژمارەکەت سەرکەوتوو بوو",
        loginFailed: "ئیمەیل یان تێپەڕە وشە هەڵەیە! ",
        logout: "چونەدەرەوە",
        accountUpdateFailed: " نوێکردنەوەی هەژمارەکەت سەرکەوتوو نەبوو. بۆ زانیاری زیاتر، تەماشای خانەکان بکە. ",
        accountUpdateSuccess: "نوێکردنەوەی هەژمارەکەت سەرکەوتوو بوو",
        passwordUpdateFailed: " نوێکردنەوەی تێپەڕەوشە سەرکەوتوو نەبوو. بۆ زانیاری زیاتر، تەماشای خانەکان بکە. ",
        passwordUpdateSuccess: "نوێکردنەوەی تێپەڕەوشە سەرکەوتوو بوو",
        noOrders: "هیچ داواکاریەکت نیە.",
        deadline: "کۆتا بەروار",
        subtotal: "کۆ",
        //delivery : "پێویستیت بە گەیاندن هەیە بۆ وەرگێڕانەکەت؟",
        delivery: 'گەیاندن',
        deliveryCost: 'تێچوی گەیاندن',
        files: "فایلەکان",
        bundles: "پلانەکان",
        noInvoice: "هیچ پوسڵەیەکت نیە",
        buyBundles: "کڕینی پلان",
        per: ' بۆ هەر',
        price: 'نرخ',
        totalUSD: 'کۆی گشتی(بە دۆلار) : $',
        processingOrder: 'پێداچونەوەی كرینەکەت ئەکەین تکایە چاوەڕوانبە',
        failedRequest: 'داواکاریەکەت سەرکەوتوو نەبوو',
        remove: 'سڕینەوە',
        words: 'وشە',
        uploadedFile: 'فایلی بەرزکراوە : ',
        downloadOriginalFile: 'داگرتنی فایل',
        downloadResults: 'ئەنجامی داگرتن',
        close: "داخستن",
        remainingInYourAccount: 'باڵانسی هەژمارەکەت',
        WantToWorkWithUs: 'دەتەوێت کارمان لەگەڵ بکەیت؟',
        allRightsReserved: ' هەموو مافەکان پارێزراون ',
        DevelopedBy: 'ئارۆ ترانسلەیشن | پەرەیپێدراوە لە لایەن',
        manuallyEntered: 'وشەکان بە دەستی تۆمارکرا',
        forgetPasswordTitle: 'وشەی نهێنیت لە بیرکردوە',
        forgetPasswordText: 'ئیمەڵەکەت بنووسە ئێمە کۆدی دڵنیایت بۆ دەنێرین',
        verificationCode: 'کۆدی دڵنیای',

        pending: 'pending',
        paid: 'paid',
        pay: 'pay',
        paymentSuccessPrimary: 'Payment Success',
        bundlePurchaseSuccess: 'Bundle purchase was successful!',
        orderPaymentSuccess: 'Order payment was successful!',
        bundlePurchaseFail: 'Bundle purchase was not successful!',
        orderPaymentFail: 'Order payment was not successful!',

        paymentFailStatusCode: {
            cancel: 'Transaction canceled',
            card: 'Card issue',
            password: 'Incorrect password',
            maxTries: 'You have reached maximum allowed tries',
            error: 'An error occured',
            timeout: 'Timeout',
        },

        paymentFailResponseCode: {
            card: 'Card issue',
            error: 'An error occured',
            env: 'Environmanet Error',
            identity: 'Honor with identification',
            success: 'Success',
            pending: 'Request in progress',
            cancel: 'Transaction canceled',
            bank: 'Bank not supported',
            maxTries: 'You have reached maximum tries',
            cardLost: 'Card lost',
            cardStolen: 'Card stolen',
            cardExpired: 'Card expired',
            account: 'Account error',
            pinIncorrect: 'Incorrect PIN',
            pinLimit: 'You have reached maximum allowed PIN entries',
            transaction: 'Transaction rrror',
            duplicate: 'Duplicate transaction',
            system: 'System error',
            timeout: 'Timeout',
            tryAgain: 'Please try again later',
            sus: 'Suspected fraud',
            pinVerification: 'Cannot verify PIN',
            violation: 'Transaction cannot be completed, violation of law',
        },

    },
    en: {
        WantToWorkWithUs: 'Want to work with us?',
        close: "close",
        downloadOriginalFile: 'Download original file',
        remove: 'Remove',
        processingOrder: 'Processing your order, please wait.',
        totalUSD: 'Total (USD): $',
        planAndPrice: 'Want a custom plan and price? no problem.',
        translator: 'I am Translator',
        client: 'I am Client',
        quickLinks: 'Quick Links',
        haveQuestions: ' Have any questions? our team is here for you.',
        quoteHeader: 'Get Instant Quote Now!',
        language: 'LANGUAGE',
        arabic: 'عربي',
        kurdish: 'کوردی',
        english: 'English',
        contactUs: 'Contact us',
        faq: 'FAQ',
        terms: 'Terms & Conditions',
        placeOrder: 'Place Order',
        getQuote: 'Get Quote',
        translationServices: 'Translation Services',
        documentTranslation: 'Document Translation',
        legalTranslation: 'Legal Translation',
        interpretation: 'Interpretation',
        proofreading: 'Proofreading',
        home: 'Home',
        services: 'Services',
        orderDocumentTranslation: 'Order Document Translation',
        orderLegalTranslation: 'Order Legal Translation',
        orderInterpretation: 'Order Interpretation',
        orderProofreading: ' Order Proofreading',
        homeU: 'Home',
        send: 'SEND',
        servicesU: 'SERVICES',
        termsU: 'TERMS & CONDITIONS',
        pricing: 'Pricing',
        about: 'About',
        account: 'Account',
        youNeedTo: 'You need to',
        signIn: 'SIGN IN',
        toContinue: 'to continue',
        getQuoteU: 'GET QUOTE',
        placeOrderU: 'PLACE ORDER',
        ourServices: 'Our Services',
        translation: 'Translation',
        pricingHome: 'Cost Effective & Simple Pricing',
        viewPricing: 'View Pricing',
        ourPricing: 'Our Pricing',
        getInstantQuote: 'GET INSTATNT QUOTE',
        ourQualities: ' Our Qualities',
        rates: 'Rates',
        quality: 'Quality',
        speed: 'Speed',
        wantJoinUs: 'Want to join us?',
        submitApplication: 'SUBMIT APPLICATION',
        whatIsAro: 'What is FullStop?',
        vision: 'Our Vision',
        mission: 'Our Mission',
        whyAro: 'Why Choose Aro?',
        joinUs: 'Join us',
        contactUsU: 'CONTACT US',
        howCanWeHelp: 'How can we help?',
        existingOrder: 'Need support on an existing order',
        salesTeam: 'Discuss a project with the sales team',
        becomeTranslator: 'Become a translator at FullStop',
        fullName: 'Full Name',
        phone: 'Phone',
        businessEmail: 'Business Email',
        yourMessage: 'Your Message',
        attachment: 'Attachment',
        email: 'Email',
        subject: 'Subject',
        contactFormText: ' what can we help you with?',
        address: 'Address',
        FAQA: 'Frequently Asked Questions',
        chooseService: 'Choose a Service',
        sourceLanguage: 'Source Language',
        targetLanguage: 'Target Language',
        specifyIndustry: 'specify an industry expert for your translation',
        documentType: 'Document Type',
        saveMoneyWithBundle: 'Save money with bundle offers',
        documentDetails: 'Document Details',
        choosePackage: 'Choose a Package',
        payment: 'Payment',
        viewPrices: 'View Prices',
        addDocumentDetails: 'Step 1: Add Document Details',
        uploadYourDocument: 'Upload your Document',
        supportedFiles: 'Supported Files',
        needStamped: 'Indicate if translated document needs to be stamped',
        yes: 'Yes',
        no: 'No',
        physicalDelivery: 'Is physical delivery required?',
        contactName: 'Contact Name',
        next: 'NEXT',
        back: 'BACK',
        step1: 'Step 1: ',
        step2: 'Step 2: ',
        step3: 'Step 3: ',
        standard: 'STANDARD',
        oneTranslator: 'One Translator',
        select: 'Select',
        perWord: ' per word',
        perPage: ' per page',

        professional: 'PROFESSIONAL',
        oneReviewer: 'One Reviewer',
        orderSummery: 'Order Summery',
        printInvoice: 'PRINT INVOICE',
        package: 'Package',
        languages: 'Languages',
        totalWordCount: 'Total Word Count',
        WordCount: 'Word Count',

        costPerWord: 'Cost per Word',
        totalPrice: 'Total Price',
        choosePaymentMethod: 'Choose Your Payment Method',
        invoiceDirectly: 'Invoice Directly',
        payOnline: 'Pay Online',
        useAroCredit: 'Use Aro Credit',
        buyCredits: 'Buy Credits',
        personalDelivery: 'Personal Delivery',
        physicalCollection: 'FullStop Physical Collection',
        submitOrder: 'SUBMIT ORDER',
        orderWasSubmitted: 'Your order was submitted successfully!',
        weReviewOrder: 'We will review your order shortly and notify you!',
        done: 'Done',
        interpretationDetails: 'Interpretation Details',
        addInterpretationDetails: 'Add Interpretation Details',
        specifyInterpretationType: 'Specify Interpretation Type',
        simultaneous: 'Simultaneous',
        consecutive: 'Consecutive',
        field: 'Field',
        dateInterpretation: 'Date of Interpretation',
        startTime: 'Start Time',
        additionalComments: 'Additional Comments',
        contactInformation: 'Contact Information',
        firstName: 'First Name',
        lastName: 'Last Name',
        companyName: 'Company Name',
        interpretationType: 'Interpretation Type',
        imageScanDetails: 'Image/Scan Details',
        addImageScanDetails: 'Add Image/Scan Details',
        chooseImagesScans: "choose images/scans.",

        chooseLegalDocumentType: 'Choose Legal Document Type',
        LegalDocumentType: 'Legal Document Type',
        YourDeadline: 'What is your deadline?',
        pageNotFound: 'Page not found',
        goHomePage: 'Go to Home Page',
        whyWorkWithAro: 'Why do you want to work with FullStop?',
        uploadCv: 'Upload your CV',
        password: 'Password',
        apply: 'APPLY',
        forgetPassword: 'Forgot Password?',
        dontHaveAccount: "Don't have an account?",
        country: 'Country',
        city: 'City',
        clickSignUp: "once you click 'Sign up' you agree to our",
        alreadyMember: 'Are you already a member?',
        profileSetting: 'Profile Setting',
        passwordSetting: 'Password Setting',
        orders: 'Orders',
        billing: 'Billing',
        invoice: 'Invoice',
        date: 'Date',
        paymentMethod: 'Payment Method',
        myOrders: 'My Orders',
        Updatepassword: 'Update Your Password',
        oldPassword: 'Old Password',
        newPassword: 'New Password',
        confirmPassword: 'Confirm Password',
        update: 'Update',
        updateProfile: 'Update Your Profile',
        saveChanges: 'SAVE CHANGES',
        orderNow: 'Order Now',
        getExtra: 'Get extra',
        forFree: 'for free',
        claimOffer: 'claim offer',
        order: 'Order',
        registerText: 'Register to FullStop, enter your details below',
        createAccount: 'CREATE AN ACCOUNT',
        enterDetailsBelow: 'enter your details below',
        signInToAro: 'SIGN IN TO FullStop ',
        signUp: 'SIGN UP',
        required: "This field is required.",
        emailFormat: "Your email is invalid",
        phoneFormat: "Your phone number is invalid. It should be in this format 077x xxx xxxx",
        passwordFormat: "Password must be at least 8 characters.",
        failed: "FAILED!",
        accountRegisterationFailed: " Account Registeration was not successful! Please check bellow for details. ",
        success: "SUCCESS!",
        accountRegisterationSuccess: " Account Registeration was successful!",
        loginFailed: "Your login credentials are incorrect. ",
        logout: "Sign out",
        accountUpdateFailed: " Profile update was not successful! Please check bellow for details. ",
        accountUpdateSuccess: " Profile update was successful!",
        passwordUpdateFailed: " Password update was not successful! Please check bellow for details. ",
        passwordUpdateSuccess: "Password update was successful!",
        myOrder: 'order ',
        noOrders: "You don't have any orders",
        deadline: "deadline",
        subtotal: "subtotal",
        delivery: "delivery",
        deliveryCost: 'Delivery Cost',
        files: "files",
        bundles: "bundles",
        noInvoice: "You don't have any invoices.",
        buyBundles: "Buy Bundles",
        per: 'per ',
        price: 'Price',
        failedRequest: 'Your request was not successful',
        words: 'words',
        uploadedFile: 'Uploaded files',
        downloadResults: 'Download Results',
        remainingInYourAccount: 'Remaining in your account',
        allRightsReserved: ' All rights reserved ',
        DevelopedBy: 'FullStop | Developed by',
        manuallyEntered: 'manually entered word counts',
        forgetPasswordTitle: 'Forget Password',
        forgetPasswordText: 'Enter your E-mail we will send you verification code',
        verificationCode: 'verification code',

        pending: 'Pending',
        paid: 'Paid',
        pay: 'Pay',
        paymentSuccessPrimary: 'Payment Success',
        bundlePurchaseSuccess: 'Bundle purchase was successful!',
        orderPaymentSuccess: 'Order payment was successful!',
        bundlePurchaseFail: 'Bundle purchase was not successful!',
        orderPaymentFail: 'Order payment was not successful!',

        paymentFailStatusCode: {
            cancel: 'Transaction canceled',
            card: 'Card issue',
            password: 'Incorrect password',
            maxTries: 'You have reached maximum allowed tries',
            error: 'An error occured',
            timeout: 'Timeout',
        },

        paymentFailResponseCode: {
            card: 'Card issue',
            error: 'An error occured',
            env: 'Environmanet Error',
            identity: 'Honor with identification',
            success: 'Success',
            pending: 'Request in progress',
            cancel: 'Transaction canceled',
            bank: 'Bank not supported',
            maxTries: 'You have reached maximum tries',
            cardLost: 'Card lost',
            cardStolen: 'Card stolen',
            cardExpired: 'Card expired',
            account: 'Account error',
            pinIncorrect: 'Incorrect PIN',
            pinLimit: 'You have reached maximum allowed PIN entries',
            transaction: 'Transaction rrror',
            duplicate: 'Duplicate transaction',
            system: 'System error',
            timeout: 'Timeout',
            tryAgain: 'Please try again later',
            sus: 'Suspected fraud',
            pinVerification: 'Cannot verify PIN',
            violation: 'Transaction cannot be completed, violation of law',
        },
    },

    ar: {
        allRightsReserved: 'جميع الحقوق محفوظة',
        DevelopedBy: '  ئارو للترجمة| طورت من قبل',
        WantToWorkWithUs: 'تريد أن تعمل معنا?',
        remainingInYourAccount: 'المتبقي في حسابك',
        close: "خروج",
        downloadResults: 'تحميل النتائج',
        downloadOriginalFile: 'تنزيل الملف الأصلي',
        uploadedFile: 'الملفات المرفوعة',
        words: 'کلمة',
        remove: 'إزالة',
        failedRequest: 'لم يكن طلبك ناجحًا',
        processingOrder: 'جارٍ معالجة طلبك ، يرجى الانتظار.',
        planAndPrice: 'هل تريد خطة وسعر مخصصين؟ لا مشكلة.',
        apply: 'تقدیم',
        translator: 'مترجم',
        client: 'مستخدم',
        language: 'لغة',
        arabic: 'عربي',
        kurdish: 'کوردی',
        english: 'الإنجليزية',
        contactUs: 'اتصل بنا',
        faq: 'التعليمات',
        terms: 'البنود و الظروف',
        placeOrder: 'تقدیم طلب',
        getQuote: 'طلب اقتباس',
        translationServices: 'خدمات الترجمة',
        documentTranslation: 'ترجمة الوثيقة',
        legalTranslation: 'الترجمة القانونية',
        interpretation: 'ترجمة صوتیة',
        proofreading: 'التدقيق اللغوي',
        home: 'بدایة',
        services: 'خدمات',
        homeU: 'بدایة',
        servicesU: 'خدمات',
        termsU: 'البنود و الظروف',
        pricing: 'أسعارنا',
        about: 'حول',
        account: 'حساب',
        youNeedTo: 'تحتاج',
        signIn: 'تسجيل الدخول',
        toContinue: 'لتستمر',
        signUp: 'اشتراك',
        getQuoteU: 'GET QUOTE',
        placeOrderU: 'تقدیم طلب',
        ourServices: 'خدماتنا',
        translation: 'ترجمة',
        pricingHome: 'فعالة من حيث التكلفة وأسعار بسيطة',
        viewPricing: 'عرض التسعير',
        ourPricing: 'أسعارنا',
        getInstantQuote: 'طلب اقتباس',
        ourQualities: ' صفاتنا',
        rates: 'معدلات',
        quality: 'جودة',
        speed: 'سرعة',
        wantJoinUs: 'تريد الانضمام إلينا؟',
        submitApplication: 'قدم الطلب',
        whatIsAro: 'ما هي ترجمة آرو؟',
        vision: 'رؤيتنا',
        haveQuestions: 'لديك أي أسئلة؟ فريقنا هنا من أجلك.',
        mission: 'مهمتنا',
        whyAro: 'لماذا تختار ئارو',
        joinUs: 'انضم إلينا',
        contactUsU: 'اتصل بنا',
        howCanWeHelp: 'كيف يمكن أن نساعد؟',
        existingOrder: 'بحاجة إلى دعم في أمر موجود',
        salesTeam: 'ناقش مشروعًا مع فريق المبيعات',
        becomeTranslator: 'كن مترجمًا في ترجمة ئارو',
        fullName: 'الاسم بالكامل',
        phone: 'هاتف',
        businessEmail: 'بريد العمل',
        yourMessage: 'رسالتك',
        attachment: 'مرفق',
        email: 'بريد إلكتروني',
        address: 'عنوان',
        FAQA: 'أسئلة مكررة',
        chooseService: 'اختر خدمة',
        sourceLanguage: 'لغة المصدر',
        targetLanguage: 'اللغة الهدف',
        specifyIndustry: 'حدد خبيرًا في المجال لترجمتك',
        documentType: 'نوع الوثيقة',
        saveMoneyWithBundle: 'وفر المال مع عروض الحزمة',
        documentDetails: 'تفاصيل المستند',
        choosePackage: 'اختر الباقة',
        payment: 'دفع',
        viewPrices: 'عرض الأسعار',
        addDocumentDetails: 'الخطوة 1: إضافة تفاصيل المستند',
        uploadYourDocument: 'قم بتحميل المستند الخاص بك',
        supportedFiles: 'الملفات المدعومة',
        needStamped: 'حدد ما إذا كان المستند المترجم يحتاج إلى ختم',
        yes: 'نعم',
        no: 'لا',
        physicalDelivery: 'هل التسليم المادي مطلوب؟',
        contactName: 'اسم جهة الاتصال',
        next: 'التالي',
        back: 'عودة',
        step1: 'الخطوة 1: ',
        step2: 'الخطوة 2:',
        standard: 'اساسي',
        oneTranslator: 'مترجم واحد',
        select: 'اختار',
        perWord: 'لكل كلمة',
        perPage: 'لكل صفحة',
        professional: 'المحترفين',
        oneReviewer: 'مراجع واحد',
        orderSummery: 'ملخص الطلب',
        printInvoice: 'فاتورة طباعة',
        package: 'طرد',
        languages: 'اللغات',
        totalWordCount: 'إجمالي عدد الكلمات',
        WordCount: ' عدد الكلمات',

        costPerWord: 'التكلفة لكل كلمة',
        totalPrice: 'السعر الكلي',
        choosePaymentMethod: 'اختر طريقتك في الدفع',
        invoiceDirectly: 'الفاتورة مباشرة',
        payOnline: 'ادفع عبر الإنترنت',
        useAroCredit: 'استخدم رصيد Aro',
        buyCredits: 'شراء إئتمانات',
        personalDelivery: 'التسليم الشخصي',
        physicalCollection: 'المجموعة المادية للترجمة ئارو',
        submitOrder: 'أكد الطلب',
        orderWasSubmitted: 'تم تقديم طلبك بنجاح!',
        weReviewOrder: 'سنراجع طلبك قريبًا ونخطرك!',
        done: 'منجز',
        interpretationDetails: 'تفاصيل التفسير',
        addInterpretationDetails: 'أضف تفاصيل التفسير',
        specifyInterpretationType: 'حدد نوع التفسير',
        simultaneous: 'متزامنة',
        consecutive: 'متتابع',
        field: 'مجال',
        dateInterpretation: 'تاريخ التفسير',
        startTime: 'وقت البدء',
        additionalComments: 'تعليقات اضافية',
        contactInformation: 'معلومات الاتصال',
        firstName: 'الاسم الأول',
        lastName: 'اسم العائلة',
        companyName: 'اسم الشركة',
        interpretationType: 'نوع التفسير',
        imageScanDetails: 'تفاصيل الصورة / المسح الضوئي',
        addImageScanDetails: 'إضافة تفاصيل الصورة / المسح الضوئي',
        chooseImagesScans: "اختر الصور /  المسح.",

        chooseLegalDocumentType: 'اختر نوع المستند القانوني',
        YourDeadline: 'ما هو الموعد النهائي الخاص بك؟',
        pageNotFound: 'الصفحة غير موجودة',
        goHomePage: 'اذهب إلى الصفحة الرئيسية',
        whyWorkWithAro: 'لماذا تريد العمل مع ترجمة ئارو؟',
        uploadCv: 'حمل سيرتك الذاتية',
        password: 'كلمه السر',
        forgetPassword: 'هل نسيت كلمة السر؟',
        forgetPasswordTitle: 'نسيت كلمة المرور',
        dontHaveAccount: "ليس لديك حساب؟",
        country: 'دولة',
        city: 'مدينة',
        clickSignUp: "بمجرد النقر فوق 'تسجيل' ، فإنك توافق على",
        alreadyMember: 'هل أنت عضو بالفعل؟',
        profileSetting: 'إعداد الملف الشخصي',
        passwordSetting: 'ضبط كلمة المرور',
        orders: 'الطلبات',
        billing: 'الفواتير',
        invoice: 'فاتورة',
        date: 'تاريخ',
        paymentMethod: 'طريقة الدفع او السداد',
        myOrders: 'طلباتي',
        Updatepassword: 'قم بتحديث كلمة المرور الخاصة بك',
        oldPassword: 'كلمة سر قديمة',
        newPassword: 'كلمة السر الجديدة',
        confirmPassword: 'تأكيد كلمة المرور',
        update: 'تحديث',
        updateProfile: 'تحديث ملفك الشخصي',
        saveChanges: 'حفظ التغييرات',
        orderNow: 'اطلب الان',
        getExtra: 'احصل على المزيد',
        forFree: 'مجانا',
        claimOffer: 'عرض المطالبة',
        order: 'الطلب',
        required: " هذه الخانة مطلوبه",
        emailFormat: "بريدك الالكتروني خاطئ",
        phoneFormat: "رقم هاتفك غير صالح. يجب أن يكون بهذا التنسيق 077x xxx xxxx",
        passwordFormat: "يجب أن تكون كلمة المرور 8 أحرف على الأقل",
        failed: "فشل!",
        accountRegisterationFailed: " فشل تسجيل الحساب! يرجى التحقق أدناه للحصول على مزيد من التفاصيل. ",
        success: "نجاح!",
        accountRegisterationSuccess: " تم تسجيل الحساب بنجاح!",
        loginFailed: "بيانات الدخول غير صحيحة. ",
        logout: "تسجيل خروج",
        accountUpdateFailed: " لم يكن تحديث الملف الشخصي ناجحًا! يرجى التحقق أدناه للحصول على التفاصيل. ",
        accountUpdateSuccess: " تم تحديث الملف الشخصي بنجاح!",
        passwordUpdateFailed: " لم يكن تحديث كلمة المرور ناجحًا! يرجى التحقق أدناه للحصول على التفاصيل. ",
        passwordUpdateSuccess: "تم تحديث كلمة المرور بنجاح!",
        myOrder: 'طلب ',
        noOrders: "ليس لديك أي طلبات",
        deadline: "حد اقصى",
        subtotal: "المجموع الفرعي",
        delivery: "توصيل",
        deliveryCost: 'تكلفة التوصيل',
        files: "الملفات",
        bundles: "حزم",
        noInvoice: "ليس لديك أي فواتير.",
        buyBundles: "شراء الحزم",
        orderDocumentTranslation: 'ترجمة الوثيقة',
        orderLegalTranslation: 'ترجمة قانونية',
        orderInterpretation: ' ترجمة صوتیة',
        orderProofreading: ' التدقيق اللغوي',
        per: ' لکل',
        price: 'سعر',
        totalUSD: 'الإجمالي (بالدولار): $ ',
        manuallyEntered: 'تم إدخال عدد الكلمات يدويًا',
        forgetPasswordText: 'أدخل بريدك الإلكتروني وسنرسل لك رمز التحقق',
        verificationCode: 'رمز التحقق',

        pending: 'pending',
        paid: 'paid',
        pay: 'pay',
        paymentSuccessPrimary: 'Payment Success',
        bundlePurchaseSuccess: 'Bundle purchase was successful!',
        orderPaymentSuccess: 'Order payment was successful!',
        bundlePurchaseFail: 'Bundle purchase was not successful!',
        orderPaymentFail: 'Order payment was not successful!',

        paymentFailStatusCode: {
            cancel: 'Transaction canceled',
            card: 'Card issue',
            password: 'Incorrect password',
            maxTries: 'You have reached maximum allowed tries',
            error: 'An error occured',
            timeout: 'Timeout',
        },

        paymentFailResponseCode: {
            card: 'Card issue',
            error: 'An error occured',
            env: 'Environmanet Error',
            identity: 'Honor with identification',
            success: 'Success',
            pending: 'Request in progress',
            cancel: 'Transaction canceled',
            bank: 'Bank not supported',
            maxTries: 'You have reached maximum tries',
            cardLost: 'Card lost',
            cardStolen: 'Card stolen',
            cardExpired: 'Card expired',
            account: 'Account error',
            pinIncorrect: 'Incorrect PIN',
            pinLimit: 'You have reached maximum allowed PIN entries',
            transaction: 'Transaction rrror',
            duplicate: 'Duplicate transaction',
            system: 'System error',
            timeout: 'Timeout',
            tryAgain: 'Please try again later',
            sus: 'Suspected fraud',
            pinVerification: 'Cannot verify PIN',
            violation: 'Transaction cannot be completed, violation of law',
        },

    },

}

export default language;