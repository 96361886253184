<template>
    <div v-if="contacts!=null">
  <div class="ContactDropDown" style="margin-bottom:50px">

    <h2 class="page-heading mt-5 " style="color: #fff !important;font-weight:700;margin-bottom:50px">{{content.contactUsU}}</h2>
    <div class="cont_select_center" >
    <span class="contact-header-text">{{content.howCanWeHelp}}</span> 
      <!-- Custom select structure --> 
    <div class="select_mate " data-mate-select="active" >
   <select class="form-select" v-model="SelectedForm" required>
  <option value="existingOrder">{{content.existingOrder}}</option>
  <option value="discuss">{{content.salesTeam}}</option>
  <option value="translator">{{content.becomeTranslator}}</option>

</select>

      </div>
    
    
    </div> 
      

  </div>



   <div class="container" style="max-width:850px"> 
     <div class="ContactForm shadow" >
          <form @submit.prevent="submitForm">
            <div class="row" >
              <div class="ContactFormTitle "> {{content.contactFormText}}</div>
            <div class="col-md-6 col-12">
              <div class="input-group mb-3">
                <label class="small d-block"  >{{content.fullName}}</label> <div class="clearfix"></div>
                <input type="text"  class="form-control d-block"  v-model.trim="firstName"  autocomplete="off" >
              
               </div>
               <div class="input-group mb-3">
                <label class="small d-block"  >{{content.phone}}</label> <div class="clearfix"></div>
                <input type="tel"  class="form-control d-block"   v-model.trim="phone">
              <div class="clearfix"></div>
              <div class="error-text">
                          {{ firstNameError }}
                        </div>
               </div>
            </div>  
                                        
            <div class="col-md-6 col-sm-6 ">
              <div class="input-group mb-3">
                <label class="small d-block"  v-if="SelectedForm=='existingOrder'" >{{content.email}}</label> 

 <label class="small d-block"  v-else >{{content.businessEmail}}</label> <div class="clearfix"></div>
                               <input type="email"  class="form-control d-block"   v-model.trim="email">
              <div class="clearfix"></div>
              <div class="error-text">
                          {{ emailError }}
                        </div>
               </div>
        
              <div class="input-group mb-3 "  v-if="SelectedForm=='existingOrder'">
                <label class="small d-block"   >{{content.subject}}</label> <div class="clearfix"></div>
                <input type="text"  class="form-control d-block"  v-model.trim="subject">
              <div class="clearfix"></div>
              <div class="error-text">
                          {{ subjectError }}
                        </div>
               </div>
            </div>                
            <div class="col-md-12 col-sm-6 ">
              <div class="input-group mb-3">
                <label class="small d-block"  >{{content.yourMessage}}</label> <div class="clearfix"></div>
                 <textarea class="form-control" placeholder=".." v-model.trim="message" rows="5"></textarea>
              <div class="clearfix"></div>
              <div class="error-text">
                          {{ messageError }}
                        </div>
               </div>
            </div>                     
            
            <div class="col-lg-12  pb-6">
               <label class="small d-block"  >{{content.attachment}}</label> <div class="clearfix"></div>
               <input type="file"  class="form-control d-block" @change="apicall"  autocomplete="off"    accept=".docx,.pdf, .doc" >
             
              </div>
            <input type="submit"  class="btn green-btn mt-4 SendBtn" :value="content.send"/>

             
                                 
          </div>
           
          </form>  
            <div class="row mt-5" v-if="submitted">
                <div v-if="!success" style="text-align: center">
                  <h5>
                    {{ content.processingOrder }}
                    <br />
                  </h5>
                  <p>
                  <GreenSpinner /></p>
                </div>
                <div v-if="success" class="w-100 alert alert-success">
                  <h3>
                    Your message was sent.
                  </h3>
                  <p class="mb-0">
                    We will go over your message and contact you shortly.
                    <router-link to="/" class="alert-link">{{
                      content.done
                    }}</router-link>
                  </p>
                </div>
                <div v-if="success == false" class="w-100 alert alert-danger">
                  <h3>  Your message was not sent.</h3>
                  <p class="mb-0">
                   An Error Occured. Please try again.
                    <router-link to="/joinus" class="alert-link">{{
                      content.back
                    }}</router-link>
                  </p>
                </div>
              </div> 
        </div>
           
  
    </div>

    <div class="container-xxl">
      <div class="row mt-5 mb-3  pb-5">
        <div class=" col-12 col-md-4">
          <div class="at-icon-box">
            <div class="at-icon-box-text">
              <h4>{{content.email}}</h4>
           
                <p class="mr-3" v-for="email in contacts.email" :key="email">
                  {{email}}
                </p>
                
            
            </div>
            <div class="at-icon-box-icon icon-right">
              <i style="font-size: 70px;opacity: 0.2;position: relative;" class="fa fa-envelope"></i>
            </div>
           
          </div>
        </div>
        <div class=" col-12 col-md-4 ">
          <div class="at-icon-box" style="background-color:#2a6049 !important;">
            <div class="at-icon-box-text">
              <h4>{{content.phone}}</h4>
                     <p class="mr-3" v-for="phone in contacts.phone" :key="phone">
                  {{phone}}
                </p>
            </div>
            <div class="at-icon-box-icon icon-right">
              <i style="font-size: 70px;opacity: 0.4;position: relative;" class="fa fa-phone"></i>
            </div>
          
          </div>
        </div>
        <div class=" col-12 col-md-4 ">
          <div class="at-icon-box" style="background-color:#2a6049 !important;">
            <div class="at-icon-box-text">
              <h4>{{content.address}}</h4>
                <p class="mr-3" v-for="address in contacts.address" :key="address">
                  {{address}}
                </p>
            
            
            </div>
            <div class="at-icon-box-icon icon-right">
              <i style="font-size: 70px;opacity: 0.4;position: relative;" class="fa fa-map"></i>
            </div>
          
          </div>
        </div>
        
    
  
      </div>
  
</div></div>
 <loader v-else></loader>
</template>


<script>

import Loader from '../../components/ui/Loading.vue'

import GreenSpinner from "../../components/ui/GreenSpinner.vue";
export default {
  components:{
    Loader,
    GreenSpinner
  },
  data(){
    return{
    SelectedForm:"existingOrder",
    contacts: null,
      firstName:null,
      phone:null,
      email:null, 
      subject:null,
      file:null,
      message:null,

      firstNameError:null,
      phoneError:null, 
      emailError:null,
      subjectError:null, 
   
      messageError:null,


      success:null,
      response:null,
      submitted:false
    }
  },
  computed:{
      content(){
        this.loadData();
    return this.$store.getters.content;
  },
  apiRoute(){
    return this.SelectedForm=="existingOrder"? "support":"projects"
  },
    existingOrder(){
      return this.SelectedForm==="existingOrder"
    },
    discuss(){
    return  this.SelectedForm==="discuss";
    }
  },
  watch :{
    SelectedForm(){
      if(this.SelectedForm==='translator')
{
  this.$router.push('/joinus')
}
    }
  },
   methods: {
    async loadData() {
      await this.$store.dispatch("getContactData");
      this.contacts = await this.$store.getters.contactContent;
     
    },

      inputIsValid() {

        if(this.firstName==null|| this.firstName ==''){
          this.firstNameError= this.content.required;
        }
        else{
                    this.firstNameError= null;

        }
        
         if(this.phone==null|| this.phone ==''){
          this.phoneError= this.content.required;
        }
       
         else{

           if(this.phone.length<10){
this.phoneError= "phone must be least 10 digits";
           }else{
             this.phoneError= null;
           }
                    

        }
             if(this.email==null|| this.email ==''){
          this.emailError= this.content.required;
        }
         else{
                    this.emailError= null;

        }

         if(this.SelectedForm=="existingOrder"){
 if(this.subject==null|| this.subject ==''){
          this.subjectError= this.content.required;
        }
         else{
                    this.subjectError= null;

        }
         }else{
           this.subjectError= null;
         }
          
           if(this.message==null||this.message=='' ){
          this.messageError= this.content.required;
        }
         else{
                    this.messageError= null;

        }

        if(this.firstNameError==null&&this.subjectError==null&&this.phoneError==null&&this.emailError==null&&this.messageError==null){

          return true
        }
        else{
          return false
        }
        

        
      },
        async apicall(e) {
     
      this.file = e.target.files[0];

     

    },
        async submitForm(){
       if(this.inputIsValid()){
         this.submitted = true;

          let form = new FormData();
          form.append("file", this.file);
          form.append("name", this.firstName.toString());
        
          form.append("phone", this.phone.toString());
          form.append("email", this.email.toString());
          if(this.SelectedForm=="existingOrder"){
               form.append("subject", this.subject.toString());
          }
       
           form.append("message", this.message.toString());

           this.response = new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open(
              "POST",
              `${
                this.$store.state.postURL + this.$store.state.apiURL + this.apiRoute
              }`,
              true
            );

           
            xhr.setRequestHeader("Accept", "application/json");
          
            xhr.send(form);
            xhr.onloadend = () => {
              if (xhr.status >= 200 && xhr.status < 300) {
                resolve(xhr.response);
                this.success = true;
                 this.firstName=null;
      this.phone=null;
      this.email=null;
      this.subject=null;
      this.file=null;
      this.message=null;
              } else {
                reject(
                  JSON.stringify({ status: xhr.status, response: xhr.response })
                );
                this.success = false;
              }
            };
          });

          
       }
       else{
          window.scrollTo(0, 250);
       }
      
      },
  },
  created() {
    this.loadData();
  },
}
</script>

