<template>
  <div
    v-if="terms != null"
    class="pb-5 header-background"
    :style="'background: url(' + banner.photo + ')'"
  >
    <div class="container">
      <page-title
        :title="banner.title"
        style="margin-bottom: 80px"
      ></page-title>
    </div>
    <span class="d-none">
      {{ content.terms }}
    </span>
    <div
      class="container terms-container mb-5 mt-5 shadow"
      style="border-radius: 15px; background-color: #fff; display: block"
    >
      <div class="row" v-if="ScreenWidth > 992">
        <div
          class="col-6 col-lg-3 col-md-6 col-sm-6 terms-btn-list pl-5 pt-5 pb-5"
        >
          <div class="term-btn-container">
            <button
              class="terms-tab"
              v-for="(term, index) in terms"
              :class="{ active: activeIndex === index }"
              :key="index"
              @click="ChangeTerm(index)"
            >
              {{ term.title }}
            </button>
          </div>
        </div>

        <div class="col-12 col-lg-9 terms-content p-5">
          <div class="terms-tab active pl-0 ml-0">{{ termTitle }}</div>
          <div v-html="termContent"></div>
        </div>
      </div>
      <div class="row mb-3 mt-3" v-if="ScreenWidth < 992">
        <div class="col-6 col-lg-3 col-md-6 col-sm-6 terms-btn-list mb-5">
          <div
            style="
              display: flex;
              flex-direction: column;
              border-right: 1px solid #eeeeee;
              align-self: center;
            "
          ></div>
        </div>
        <div
          class="col-12 col-lg-9 terms-content p-5 mb-2 pb-2 pt-2"
          v-for="(term, index) in terms"
          :key="index"
        >
          <div class="terms-tab active" style="font: size 20px !important">
            {{ term.title }}
          </div>
          <div v-html="termContent"></div>
        </div>
      </div>
    </div>
  </div>

  <loader v-else></loader>
</template>

<script>
import Loader from "../../components/ui/Loading.vue";
export default {
  components: {
    Loader,
  },
  data() {
    return {
      activeIndex: 0,

      terms: null,
      banner: null,
    };
  },

  computed: {
    termContent() {
      if (this.terms != null) {
        return this.terms[this.activeIndex].content;
      } else {
        return "Term";
      }
    },
    termTitle() {
      if (this.terms != null) {
        return this.terms[this.activeIndex].title;
      } else {
        return "Term";
      }
    },
    ScreenWidth() {
      return screen.width;
    },
    content() {
      this.loadData();
      return this.$store.getters.content;
    },
  },
  methods: {
    ChangeTerm(index) {
      this.activeIndex = index;
    },
    async loadData() {
      console.log("loadData was run");
      await this.$store.dispatch("getTermsData");
      const termData = this.$store.getters.termsContent;
      this.terms = termData.terms;
      console.log(this.terms);
      this.banner = termData.banner;
    },
  },
  created() {
    this.loadData();
  },
};
</script>

<style scoped>
.terms-tab {
  text-align: left;

  margin-left: 0;
}
.rtl .terms-tab {
  text-align: right;

  margin-right: 0;
}
.term-btn-container {
  padding-left: 10px;
}

.rtl .term-btn-container {
  border-right: none;
}
</style>