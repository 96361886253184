import http from '../../../httpRequests.js';

export default {
    state(){
        return{
          
            url:'user/password/forgot',
            reseturl:'user/password/reset'

            
           

        }
    }, 
  

    actions:{
        async forgetPassword(context, payload){
            const res = 
         await http.post(context.state.url, context.rootState.lang,payload);
        return res;
        },
        async resetPassword(context, payload){
            const res = 
         await http.post(context.state.reseturl, context.rootState.lang,payload);
        return res;
        },
     
    }
,
   
}