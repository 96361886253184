<template>
  <router-view></router-view>

</template>

<script>

export default {
  beforeCreate() {
    this.$store.dispatch("updateLanguage");
    this.$store.dispatch("setAuth");
    this.$store.dispatch("checkToken");
    
  },
  computed:{
    user(){
      return this.$store.getters.user;
    }
  },
  watch:{
    user: function(){
      if(this.user?.status=='pending'){
this.$router.push('/verify')
      }
    }
   
  }
};
</script>

<style>
</style>
