<template>
    <div class="form-background " >
        <div class="container" style="flex-direction: column;max-width: 830px;">
     <router-view></router-view>

              </div>
   <auth-footer></auth-footer>
    </div>


</template>
<script>
import AuthFooter from "../components/layout/AuthFooter.vue"
export default {
     components:{
          AuthFooter
     }
}
</script>