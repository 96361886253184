<template>
    
  <div v-if="faq!=null" class="pb-5 header-background"  style="background: url(/static/images/faqBg2.png);">
  <div class="container flex-column" >
      <page-title :title="content.faq" ></page-title>
       <div  style="font-weight: 300;margin-bottom:80px" >{{content.FAQA}} </div>
  </div>
    

    <div class="container pb-5"> 
      <div class="accordion w-100 mt-5" id="accordionExample">
        <div class="card mb-3" style="border-radius: 15px;" v-for="q in faq" :key="q.question">
          <div class="card-header" :id="'q'+q.id" style="background-color: #fff;border-radius: 15px;border:none">
            <h2 class="mb-0 " style="display: flex;">
              <button class="btn-link px-3" style="text-decoration: none;" type="button" data-toggle="collapse" :data-target="'#c'+q.id"  aria-expanded="true" :aria-controls="'c'+q.id">
                {{q.question}}   <button data-toggle="collapse" data-target="#collapseOne" style="width: 40px; padding-right: 20px;"><img src="/static/images/faq-down.png" style="width: 20px;">   </button> </button>    
            </h2>
          </div>
      
          <div :id="'c'+q.id" class="collapse  px-3" :aria-labelledby="'q'+q.id" data-parent="#accordionExample">
            <div class="card-body mb-4 mt-4">
             {{q.answer}}
              </div>
          </div>
        </div>
       
       
      </div>
    </div>
  </div>
<Loader v-else/>
</template>
<script>
import Loader from "../../components/ui/Loading.vue";
export default {
  components: {
    Loader,
  },
  data() {
    return {
     

      faq: null,
      banner:null
    };
  },

  computed: {
  
    content() {
      this.loadData();
      return this.$store.getters.content;
    
    },
  },
  methods: {
   
    async loadData() {
      console.log('loadData was run');
      await this.$store.dispatch("getFaqData");
      const faqData =  this.$store.getters.faqContent;
      this.faq = faqData.faq;
      console.log(this.faq);
      this.banner= faqData.banner;
    },
  },
  created() {
    this.loadData();
  },
  
};
</script>

