<template>
  <div class="col-lg-5 col-sm-10 mt-5">
    <div
      class="card shadow"
      style="border-radius: 15px; text-align: center; padding: 10px"
    >
      <div class="card-body" style="color: #2a6049 !important">
        <div class="mb-4 cardname" style="text-align: center; font-size: 32px">
          {{ name }}
        </div>
        <hr />
        <div class="card-text mb-3 capitalize" style="font-size: 20px">
          <div style="font-size: 32px; margin-bottom: 10px">${{ price }}</div>
          <div v-if="unit == null">{{ content.perWord }}</div>
          <div v-if="unit != null">{{ content.per + " " + unit }}</div>
        </div>
        <div
          class="d-flex align-items-center mb-3"
          style="font-size: 20px; display: flex; justify-content: center"
        >
          {{ translators }}
        </div>
        <div
          class="mb-3 capitalize"
          style="font-size: 20px; display: flex; justify-content: center"
        >
          {{ lang }}
        </div>

        <button
          class="btn mt-3 pricing-order-btn"
          style="background-color: #afa68a; color: #fff"
          @click="$router.push(route)"
        >
          {{ content.orderNow }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["route", "lang", "translators", "name", "price", "unit"],
  computed: {
    content() {
      return this.$store.getters.content;
    },
  },
};
</script>

<style scoped>
.card {
  min-width: 300px;
}
.pricing-order-btn {
  background: #2a6049;
  border: none;
  color: #fff;
  height: 40px;
}
.cardname {
  text-transform: uppercase;
}
.capitalize {
  text-transform: capitalize;
}
</style>