<template>
  <footer class="site-footer">
    <div class="container-xl hide-on-small">
      <div class="row">
        <div class="col-sm-12 col-md-3">
          <img
            src="/static/images/fullstopwhite-logo.png"
            class="img-fluid"
            style="max-width: 200px"
          />

          <p class="mt-3">{{ footer }}</p>
        </div>
        <div class="col-md-1"></div>
        <div class="col-6 col-md-3">
          <h6>{{ content.services }}</h6>
          <ul class="footer-links">
            <li>
              <router-link to="/services/1">
                {{ content.translationServices }}</router-link
              >
            </li>

            <li>
              <router-link to="/services/2">{{
                content.interpretation
              }}</router-link>
            </li>
            <li>
              <router-link to="/services/3">{{
                content.proofreading
              }}</router-link>
            </li>

            <li>
              <router-link to="/quote">{{ content.getQuote }} </router-link>
            </li>
          </ul>
        </div>
        <div class="col-6 col-md-3">
          <h6>{{ content.account }}</h6>
          <ul class="footer-links">
            <li>
              <a href="/account/profile">{{ content.profileSetting }} </a>
            </li>
            <li>
              <a href="/account/billing">{{ content.billing }}</a>
            </li>
            <li>
              <a href="/account/orders">{{ content.orders }}</a>
            </li>
            <li>
              <a href="/joinus">{{ content.joinUs }}</a>
            </li>
          </ul>
        </div>

        <div class="col-6 col-md-2">
          <h6>{{ content.quickLinks }}</h6>
          <ul class="footer-links">
            <li>
              <router-link to="/">{{ content.home }}</router-link>
            </li>
            <li>
              <router-link to="/about">{{ content.about }}</router-link>
            </li>
            <li>
              <router-link to="/contact">{{ content.contactUs }}</router-link>
            </li>
            <li>
              <router-link to="/terms">{{ content.terms }}</router-link>
            </li>
            <li>
              <router-link to="/faq">{{ content.faq }}</router-link>
            </li>
            <li>
              <router-link to="/pricing">{{ content.pricing }}</router-link>
            </li>
          </ul>
        </div>
      </div>
      <hr class="mt-5" />
    </div>

    <div class="container-xl">
      <div class="row">
        <div class="col-md-8 col-sm-12 col-xl-6 center-on-small">
          <p>
            <br />{{ content.allRightsReserved }} 2024 &copy;
            {{ content.DevelopedBy }}
            <a href="https://www.omermukhtar.me" class="link text-white">
              omer mukhtar</a
            >
          </p>
        </div>

        <div class="col-md-4 col-sm-12 col-xl-6 center-on-small">
          <ul class="social-icons d-block">
            <li>
              <a class="phone" :href="'tel:' + phone"
                ><i class="fa fa-phone"></i
              ></a>
            </li>
            <li>
              <a class="mail" :href="'mailto:' + email"
                ><i class="fa fa-envelope"></i
              ></a>
            </li>
            <li>
              <a
                v-for="sm in socialMedia"
                :key="sm"
                :class="sm.name"
                :href="sm.link"
                ><i :class="'fa fa-' + sm.name"></i
              ></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      contacts: null,
    };
  },
  computed: {
    content() {
      this.loadData();
      return this.$store.getters.content;
    },
    footer() {
      if (this.contacts != null) {
        return this.contacts.footer.content;
      } else {
        return [{ link: "" }, { link: "" }, { link: "" }];
      }
    },

    socialMedia() {
      if (this.contacts != null) {
        return this.contacts.socialMedia;
      } else {
        return [];
      }
    },
    phone() {
      if (this.contacts != null) {
        let phone = this.contacts.phone;
        return phone[0];
      } else {
        return "";
      }
    },
    email() {
      if (this.contacts != null) {
        let email = this.contacts.email;
        return email[0];
      } else {
        return "";
      }
    },
  },
  methods: {
    async loadData() {
      await this.$store.dispatch("getContactData");
      this.contacts = await this.$store.getters.contactContent;
    },
  },
  created() {
    this.loadData();
  },
};
</script>

<style scoped>
.social-icons {
  float: right;
}
.rtl .social-icons {
  float: left;
}
</style>