<template>
  <div v-if="registerContent">
    <auth-header
      :title="registerContent.register.title"
      :text="registerContent.register.subtitle"
    ></auth-header>
    <div class="d-flex">
      <button
        class="form-tab-btn w-btn d-flex justify-content-center align-items-center"
        style="z-index: 1"
        @click.prevent="navigateTo('/signup')"
      >
        <client color="#2a6049"></client> {{ content.client }}
      </button>
      <button
        class="form-tab-btn g-btn d-flex justify-content-center align-items-center"
        style="margin-left: -36px; z-index: 0"
        @click.prevent="navigateTo('/joinus')"
      >
        <translator color="#fff"></translator> {{ content.translator }}
      </button>
    </div>
    <div class="tab-form shadow">
      <div v-if="registerSuccess == false" class="well well-failed">
        <div>
          <h3>{{ content.failed }}</h3>
          <p>
            {{ content.accountRegisterationFailed }}
          </p>
        </div>
      </div>
      <div v-if="registerSuccess == true" class="well well-success">
        <div>
          <h3>{{ content.success }}</h3>
          <p>
            {{ content.accountRegisterationSuccess }}
            <router-link class="link" to="/verify">VERIFY ACCOUNT</router-link>
          </p>
        </div>
      </div>
      <form v-else class="row" @submit.prevent="submitForm">
        <div class="col-md-6">
          <div class="input-group mb-3">
            <label class="small d-block">{{ content.firstName }}</label>
            <div class="clearfix"></div>
            <input
              type="text"
              class="form-control d-block"
              autocomplete="off"
              v-model.trim="firstname"
            />
            <div class="clearfix m-0"></div>
            <p v-if="firstNameError" class="error-text">{{ firstNameError }}</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="input-group mb-3">
            <label class="small d-block">{{ content.lastName }}</label>
            <div class="clearfix"></div>
            <input
              type="text"
              class="form-control d-block"
              autocomplete="off"
              v-model.trim="lastname"
            />
            <div class="clearfix m-0"></div>
            <p v-if="lastNameError" class="error-text">{{ lastNameError }}</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="input-group mb-3">
            <label class="small d-block">{{ content.companyName }}</label>
            <div class="clearfix"></div>
            <input
              type="text"
              class="form-control d-block"
              autocomplete="off"
              v-model.trim="company"
            />
          </div>
        </div>

        <div class="col-md-6">
          <div class="input-group mb-3">
            <label class="small d-block">{{ content.email }}</label>
            <div class="clearfix"></div>
            <input
              type="text"
              class="form-control d-block"
              autocomplete="off"
              v-model.trim="email"
            />
            <div class="clearfix m-0"></div>
            <p v-if="emailError" class="error-text">{{ emailError }}</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="input-group mb-3">
            <label class="small d-block">{{ content.country }}</label>
            <div class="clearfix"></div>

            <input
              v-model.trim="country"
              type="text"
              class="form-control d-block"
              autocomplete="off"
            />
            <div class="clearfix m-0"></div>
            <p v-if="countryError" class="error-text">{{ countryError }}</p>
          </div>
        </div>

        <div class="col-md-6">
          <div class="input-group mb-3">
            <label class="small d-block">{{ content.city }}</label>
            <div class="clearfix"></div>
            <input
              v-model.trim="city"
              type="text"
              class="form-control d-block"
              autocomplete="off"
            />
            <div class="clearfix m-0"></div>
            <p v-if="cityError" class="error-text">{{ cityError }}</p>
          </div>
        </div>

        <div class="col-md-6">
          <div class="input-group mb-3">
            <label class="small d-block">{{ content.phone }}</label>
            <div class="clearfix"></div>
            <input
              type="number"
              class="form-control d-block"
              autocomplete="off"
              v-model.trim="phone"
            />
            <div class="clearfix m-0"></div>
            <p v-if="phoneError" class="error-text">{{ phoneError }}</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="input-group mb-3">
            <label class="small d-block">{{ content.password }}</label>
            <div class="clearfix"></div>
            <input
              type="password"
              class="form-control d-block"
              autocomplete="off"
              v-model.trim="password"
            />
            <div class="clearfix m-0"></div>
            <p v-if="passwordError" class="error-text">{{ passwordError }}</p>
          </div>
        </div>

        <div class="col-12 mt-4 d-block d-md-flex term">
          <div class="align-rtl-text pt-2 pb-4">
            {{ content.clickSignUp }}
            <router-link to="/terms" class="termbtn">
              {{ content.terms }}
            </router-link>
          </div>
          <div>
            <button
              class="btn termbtn backbtn"
              type="button"
              @click="navigateTo()"
            >
              {{ content.back }}
            </button>
            <button type="submit" class="form-green-btn">
              <Spinner v-if="submitted" />
              <span v-else>
                {{ content.signUp }}
              </span>
            </button>
          </div>
        </div>

        <div class="mt-3 align-rtl-text well">
          <div>
            {{ content.alreadyMember }}
          </div>
          <div>
            <button
              type="button"
              class="form-white-btn m-0"
              @click="navigateTo('/signin')"
            >
              {{ content.signIn }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <Loader v-else />
</template>

<script>
import Translator from "../../components/Icons/Translator.vue";
import Client from "../../components/Icons/Client.vue";

import AuthHeader from "../../components/layout/AuthHeader";
import Loader from "../../components/ui/Loading.vue";
import Spinner from "../../components/ui/Spinner.vue";

export default {
  data() {
    return {
      registerContent: null,
      firstname: null,
      lastname: null,
      company: null,
      email: null,
      country: null,
      city: null,
      phone: null,
      password: null,

      firstNameError: null,
      lastNameError: null,
      emailError: null,
      phoneError: null,
      countryError: null,
      cityError: null,
      passwordError: null,
      submitted: false,
      response: null,
      registerSuccess: null,
    };
  },
  components: {
    AuthHeader,
    Translator,
    Client,
    Loader,
    Spinner,
  },
  methods: {
    navigateTo(path) {
      if (path) {
        this.$router.push(path);
      } else {
        this.$router.back();
      }
    },
    async loadData() {
      await this.$store.dispatch("getAccountData");
      this.registerContent = await this.$store.getters.accountContent;
    },
    validateEmail() {
      this.emailError = null;
      if (this.email === "" || this.email == null) {
        this.emailError = this.content.required;
      } else if (!this.email.includes("@")) {
        this.emailError = this.content.emailFormat;
      }
    },
    validatePhone() {
      this.phoneError = null;
      if (this.phone === "" || this.phone == null) {
        this.phoneError = this.content.required;
      } else if (this.phone.length < 11) {
        this.phoneError = this.content.phoneFormat;
      }
    },
    validatePassword() {
      this.passwordError = null;
      if (this.password === "" || this.password == null) {
        this.passwordError = this.content.required;
      } else if (this.password.length < 8) {
        this.passwordError = this.content.passwordFormat;
      }
    },
    validateRequired(inputed) {
      if (inputed === "" || inputed == null) {
        return this.content.required;
      } else {
        return null;
      }
    },
    async submitForm() {
      this.submitted = true;
      this.validateEmail();
      this.validatePhone();
      this.validatePassword();
      this.firstNameError = this.validateRequired(this.firstname);
      this.lastNameError = this.validateRequired(this.lastname);
      this.cityError = this.validateRequired(this.city);
      this.countryError = this.validateRequired(this.country);

      if (
        !this.firstNameError &&
        !this.lastNameError &&
        !this.phoneError &&
        !this.emailError &&
        !this.passwordError &&
        !this.cityError &&
        !this.countryError
      ) {
        this.response = await this.$store.dispatch("signUp", {
          first_name: this.firstname,
          last_name: this.lastname,
          phone: this.phone,
          email: this.email,
          city: this.city,
          country: this.country,
          address: "address",
          password: this.password,
        });

        if (this.response.user && this.response.token) {
          this.submitted = false;
          this.registerSuccess = true;

           this.$store.dispatch("setAuth", {
            token: this.response.token,
            user: this.response.user,
          });
        } 
        else if (this.response.errors) {
          window.scrollTo(0, 0);
          this.registerSuccess = false;
          this.submitted = false;

          if (this.response.errors.phone) {
            this.phoneError = this.response.errors.phone[0];
          }
          if (this.response.errors.email) {
            this.emailError = this.response.errors.email[0];
          }
        }
      } else {
        this.submitted = false;
      }
    },
  },
  computed: {
    content() {
      this.loadData();
      return this.$store.getters.content;
    },
  },
  created() {
    this.loadData();
  },
};
</script>
