
import Home from '../pages/main/Home.vue';
import About from '../pages/main/About.vue';
import Faq from '../pages/main/Faq.vue';
import Terms from '../pages/main/Terms.vue';
import Pricing from '../pages/main/Pricing.vue';
import Contact from '../pages/main/Contact.vue';
import Services from '../pages/services/Services.vue'
export default [
    {
        path: '/',
        component: Home,
    },
    {
        path: '/about',
        component: About,
    },
    {
        path: '/faq',
        component: Faq,
    },
    {
        path: '/terms',
        component: Terms,
    },
    {
        path: '/pricing',
        component: Pricing,
    },
    {
        path: '/pricing/:pricingId',
        component: Pricing,
    },
    {
        path: '/contact',
        component: Contact
    },
    {
        path: '/services',
        component: Services
    },
    {
        path: '/services/:serviceId',
        component: Services
    }
]