import SignIn from '../pages/authentication/SignIn.vue'
import SignUp from '../pages/authentication/SignUp.vue'
import JoinUs from '../pages/authentication/JoinUs.vue'
import Verify from '../pages/authentication/Verify.vue'
import Forget from '../pages/authentication/Forget.vue'


export default [
    {
        path: '/signin',
        component: SignIn,
        meta: { requiresUnAuth: true },
    },
    {
        path: '/signup',
        component: SignUp,
        meta: { requiresUnAuth: true },
    },
    {
        path: '/verify',
        component: Verify,
        meta: { requiresAuth: true },
    },
    {
        path: '/joinus',
        component: JoinUs,
    },
    {
        path: '/forgetpassword',
        component: Forget,
    }
]