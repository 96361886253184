export default {
    apiURL: 'https://api.fullstopiq.com/api/',
    postURL: "",
    // postURL : "https://calm-falls-29246.herokuapp.com/",
    get: async function (url, lang, token) {
        const requestOptions = {

            headers: token ? {
                "Content-Type": "application/json",
                "Accept": "application/json",
                // "Origin": "localhost:3000",
                "Authorization": "Bearer " + token,

            } : {
                "Content-Type": "application/json",
                "Accept": "application/json",
                // "Origin": "localhost:3000"

            }
        };
        return await fetch(this.apiURL + url + '?lang=' + lang, requestOptions).then(response => response.json());



    },

    post: async function (url, lang, body, token, noresponse) {
        const requestOptions = {
            method: "POST",
            headers: token ? {
                "Content-Type": "application/json",
                "Accept": "application/json",
                // "Origin": "localhost:3000",
                "Authorization": "Bearer " + token,

            } : {
                "Content-Type": "application/json",
                "Accept": "application/json",
                // "Origin": "localhost:3000"

            },
            body: body ? JSON.stringify(body) : {},
        };

        if (noresponse) {
            return await fetch(this.postURL + this.apiURL + url + '?lang=' + lang, requestOptions);
        }
        else {
            return await fetch(this.postURL + this.apiURL + url + '?lang=' + lang, requestOptions).then(response => response.json());
        }

    },
    put: async function (url, lang, body, token) {
        const requestOptions = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                // "Origin": "localhost:3000",
                "Authorization": "Bearer " + token,

            },

            body: JSON.stringify(body),

        };

        return await fetch(this.postURL + this.apiURL + url + '?lang=' + lang, requestOptions).then(response => response.json());



    },

}