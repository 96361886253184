import http from '../../../httpRequests.js'

export default {
    state(){
        return{
          
            url:  'pages/about',
            aboutContent: {}
           

        }
    }, 
    mutations:{
        setContent(state, payload){
            state.aboutContent= payload;

        }
    }, 

    actions:{
        async getData(context){
        const response= await http.get(context.state.url, context.rootState.lang);
        
        context.commit('setContent',response);
       

        }
    }
,
    getters:{
        aboutContent(state ){
            console.log(state.aboutContent);
            return  state.aboutContent;
        }
    }
}