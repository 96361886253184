<template>
  <form @submit.prevent="">
    <div class="container d-block mt-4" v-if="step1">
      <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <div class="input-group mb-3">
            <label class="small d-block">{{ content.sourceLanguage }}</label>
            <div class="clearfix"></div>
            <select class="form-select" v-model="source" required>
              <option value="1">{{ content.english }}</option>
              <option value="2">{{ content.arabic }}</option>
              <option value="3">{{ content.kurdish }}</option>
            </select>
          </div>
        </div>

        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <div class="input-group mb-3">
            <label class="small d-block">{{ content.targetLanguage }}</label>
            <div class="clearfix"></div>
            <select class="form-select" v-model="target" required>
              <option value="1">{{ content.english }}</option>
              <option value="2">{{ content.arabic }}</option>
              <option value="3">{{ content.kurdish }}</option>
            </select>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
          <div class="input-group mb-3">
            <label class="small d-block">{{ content.specifyIndustry }}</label>
            <div class="clearfix"></div>
            <select class="form-select" v-model="industry">
              <option
                v-for="type in documentTranslationPrices"
                :key="type.id"
                :value="type"
              >
                {{ type.name }}
              </option>
            </select>
            <div class="clearfix"></div>
            <div class="error-text">{{ industryError }}</div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12" v-if="industryId">
          <div class="input-group mb-3">
            <label class="small d-block">{{ content.package }}</label>
            <div class="clearfix"></div>
            <select class="form-select" v-model="setPackage">
              <option
                v-for="pricePackage in prices"
                :key="pricePackage"
                :value="pricePackage"
              >
                {{ pricePackage.tier }} ( $
                {{ pricePackage.price }}
                {{ content.perWord }})
              </option>
            </select>
            <div class="clearfix"></div>
            <div class="error-text">{{ packageError }}</div>
          </div>
        </div>

        <div class="col-md-6 col-sm-12">
          <div class="input-group mb-3">
            <label class="small d-block"> {{ content.fullName }}</label>
            <div class="clearfix"></div>
            <input type="text" class="form-control" v-model="contactName" />
          </div>
        </div>

        <div class="col-md-6 col-sm-12">
          <div class="input-group mb-3">
            <label class="small d-block">
              {{ content.phone }}
            </label>
            <div class="clearfix"></div>
            <input type="text" class="form-control" v-model="contactPhone" />
          </div>
        </div>
        <div class="col-md-6 col-sm-12">
          <div class="input-group mb-3">
            <label class="small d-block">
              {{ content.email }}
            </label>
            <div class="clearfix"></div>
            <input type="text" class="form-control" v-model="contactEmail" />
          </div>
        </div>
        <hr class="mt-3" />
        <div class="col-xl-8 col-lg-12">
          <div class="input-group">
            <div
              class="d-flex w-100 flex-wrap"
              style="justify-content: space-between"
            >
              <label class="small d-block" style="float: right">{{
                content.uploadYourDocument
              }}</label>

              <label
                class="small d-block"
                style="color: #2a6049; font-weight: 500; cursor: pointer"
                >{{ content.supportedFiles }}(Pdf , Word , Excel ,
                PowerPoint)</label
              >
            </div>

            <div class="clearfix"></div>
            <input
              type="file"
              class="form-control d-block"
              style="font-size: 14px; padding: 2px 5px"
              accept=".docx,.pdf, .doc, .xlsx, .xls, .pptx"
              id="x"
              @change="apicall"
            />
            <GreenSpinner
              v-if="attaching"
              style="position: absolute; top: 42px; right: 20px"
            />

            <div class="clearfix"></div>
          </div>

          <div class="list-group list-group-flush mb-4">
            <div
              class="list-group-item d-flex p-0"
              style="justify-content: space-between"
              v-for="(file, index) in filesComputed"
              :key="file"
            >
              <p class="mt-4">
                {{ index + 1 }} . {{ file.file.name }}
                <span class="badge bg-secondary rounded-pill">
                  {{ file.contted }} {{ content.words }}</span
                >
              </p>
              <p class="mt-3">
                <button class="btn btn-outline-danger" @click="remove(index)">
                  {{ content.remove }}
                </button>
              </p>
            </div>
          </div>
        </div>
        <div
          class="col-1 d-flex align-items-center justify-content-center"
          style="margin-top: 10px"
        >
          or
        </div>
        <div class="col-xl-3 col-lg-12">
          <div class="input-group mb-3">
            <label class="small d-block">
              {{ content.WordCount }}
            </label>
            <div class="clearfix"></div>
            <input type="text" class="wordcount" v-model="countedWord" />
          </div>
        </div>

        <div class="col-12">
          <button
            v-on:click.prevent="toStep3"
            type="submit"
            class="btn green-btn mt-4"
          >
            {{ content.getQuote }}
          </button>
        </div>
      </div>
    </div>

    <div class="container d-block mt-4" v-if="step3">
      <div class="row">
        <div
          class="col-8 mb-5 mt-1"
          style="color: #363636; font-size: 20px; text-align: start"
        >
          {{ content.orderSummery }}
        </div>
        <div class="col-4" style="text-align: right">
          <button
            type="button"
            class="btn btn-outline-success print-btn"
            @click="print()"
          >
            PRINT
          </button>
        </div>

        <div class="d-flex justify-content-between flex-wrap">
          <div class="col-6 col-sm-3" style="text-align: start">
            <label class="small d-block">{{ content.package }}</label>
            <div class="clearfix"></div>
            <p>{{ packageName }}</p>
          </div>
          <div class="col-6 col-sm-3 left-in-sm" style="text-align: center">
            <label class="small d-block">{{ content.documentType }}</label>
            <div class="clearfix"></div>
            <p>{{ industry.name }}</p>
          </div>
          <div class="col-6 col-sm-3 left-in-sm" style="text-align: center">
            <label class="small d-block">{{ content.costPerWord }}</label>
            <div class="clearfix"></div>

            <p>$ {{ packagePrice }}</p>
          </div>
          <div class="col-6 col-sm-3 end-align left-in-sm">
            <label class="small d-block">{{ content.languages }}</label>
            <div class="clearfix"></div>
            <p>{{ language(source) }} into {{ language(target) }}</p>
          </div>
          <div class="col-6 col-sm-9 mt-3" style="text-align: start">
            <label class="small d-block">{{
              content.contactInformation
            }}</label>
            <div class="clearfix"></div>
            <p>{{ address }}</p>
          </div>
        </div>

        <div class="col-lg-12 mt-4 alert alert-secondary bg-light">
          <div class="row p-0">
            <p class="col-6 col-sm-6 col-md-7">
              <label class="small d-block" style="text-align: start">
                {{ content.files }}
              </label>
            </p>
            <p class="col-3 col-sm-3 col-md-3">
              <label class="small d-block"> {{ content.totalWordCount }}</label>
            </p>
            <p class="col-3 col-md-2 end-align">
              <label class="small d-block">
                {{ content.price }}
              </label>
            </p>
          </div>
          <hr />
          <div v-if="countedWord == null">
            <div
              class="row p-0"
              v-for="(file, index) in filesComputed"
              :key="file"
            >
              <p class="col-6 col-sm-6 col-md-7 mt-4" style="text-align: start">
                {{ index + 1 }} . {{ file.file.name }}
              </p>
              <p class="col-3 col-sm-3 col-md-3 mt-4">
                {{ file.contted }}
              </p>
              <p class="mt-4 col-3 col-md-2 end-align">
                $
                {{ (packagePrice * file.contted).toFixed(2) }}
              </p>
            </div>
          </div>
          <div v-if="countedWord != null">
            <div class="row p-0">
              <p class="col-6 col-sm-6 col-md-7 mt-4" style="text-align: start">
                {{ content.manuallyEntered }}
              </p>
              <p class="col-3 col-sm-3 col-md-3 mt-4">
                {{ countedWord }}
              </p>
              <p class="mt-4 col-3 col-md-2 end-align">
                $
                {{ (packagePrice * countedWord).toFixed(2) }}
              </p>
            </div>
          </div>
        </div>

        <div class="col-12 text-align-center end-align left-in-sm">
          <h5 class="text-success mt-3 d-flex justify-content-end">
            {{ content.totalUSD }}
            <div v-if="countedWord == null">
              {{ invoiceTotal.toFixed(2) }}
            </div>
            <div v-if="countedWord != null">
              {{ (packagePrice * countedWord).toFixed(2) }}
            </div>
          </h5>
          <div class="col-12 mt-3">
            <hr />
            <button
              v-on:click.prevent="toStep1"
              type="button"
              class="btn btn-light mt-4"
            >
              {{ content.back }}
            </button>
            <button
              class="btn btn-success mt-4 m-l-4"
              v-on:click.prevent="$router.push('/OrderDocumentTranslation')"
            >
              {{ content.order }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div ref="invoice" :style="'display:' + hide">
    <div
      style="
        margin: 0px 0x !important;
        font-size: 32px;
        text-align: left;
        width: 100%;
        color: #fff;
        background: #2a6049 !important;
        padding: 10px 20px !important;
      "
    ></div>
    <div style="display: flex; justify-content: space-between">
      <img
        style="margin-top: 20px; height: 100px; padding: 20px !important"
        src="/static/images/logo.png"
      />
      <div
        class="aro"
        style="margin-top: 20px; padding: 20px !important; text-align: right"
      >
        <h1>Quote</h1>

        <div style="margin-bottom: 10px">Quote date : {{ todayDate() }}</div>
      </div>
    </div>
    <div
      style="
        margin-top: 50px;
        padding: 10px 20px !important;
        display: flex;
        justify-content: space-between;
      "
    >
      <div class="aro" style="width: 300px">
        <div style="margin-bottom: 10px; font-weight: 700">FullStop</div>

        <div style="margin-bottom: 10px">Address: Erbil, Iraq</div>
        <div style="margin-bottom: 10px">Phone: 0770 000 0000</div>
        <div style="margin-bottom: 10px">Email: info@arotranslation.com</div>
      </div>
      <div class="client" style="margin-left: 100px; text-align: right">
        <div style="margin-bottom: 10px; font-weight: 700">Bill To</div>
        <div style="margin-bottom: 10px">
          {{ user?.firstName }} {{ user?.lastName }},
          {{ user?.company }}
        </div>

        <div style="margin-bottom: 10px">
          Address:
          <span
            >{{ user?.city }}, {{ user?.country }}<br />
            {{ user?.address }}</span
          >
        </div>
        <div style="margin-bottom: 10px">Phone: {{ user?.phone }}</div>
      </div>
    </div>
    <table style="width: 100%">
      <tr style="color: #fff; background: #2a6049">
        <th style="padding: 20px; width: 200">Item</th>
        <th style="padding: 20px; width: 600">Description</th>
        <th style="padding: 20px; width: 200">Price</th>
      </tr>
      <tr>
        <td
          style="
            padding: 20px;
            text-align: center;
            border-right: solid #ccc !important;
          "
        >
          {{ content.documentTranslation }}
        </td>
        <td
          style="
            padding: 20px;
            text-align: center;
            border-right: solid #ccc !important;
          "
        >
          <div style="line-height: 30px">
            Source language: {{ language(source) }}
            <div v-if="!!target">Target language: {{ language(target) }}</div>
          </div>
          <div class="row p-0">
            <p class="col-3 col-sm-3 col-md-3 mt-4">
              {{ invoiceTotalWords }} Words
            </p>
          </div>
        </td>
        <td style="padding: 20px; text-align: center">
          $ {{ packagePrice }} per word
        </td>
      </tr>
    </table>
    <h3 style="float: right; margin-top: 20px; padding: 20px !important">
      <div v-if="countedWord == null">
        Total Price : ${{ invoiceTotal.toFixed(2) }}
      </div>
      <div v-if="countedWord != null">
        Total Price : $ {{ (packagePrice * countedWord).toFixed(2) }}
      </div>
    </h3>
    <div></div>
  </div>
</template>

<script>
import GreenSpinner from "../../components/ui/GreenSpinner.vue";
export default {
  data() {
    return {
      //main request variables
      serviceId: 1,
      source: 1,
      target: 1,
      countedWord: null,
      total: 0, //suntotal + delivery
      setPackage: null,

      // Delivery and stamp Info
      contactName: "",
      contactPhone: "",
      contactEmail: "",

      //industry and package choices
      industry: {},
      documentTranslationPrices: null,
      packageID: null,
      packagePrice: 0,
      packageName: "",

      //ui variables
      step1: true,

      step3: false,
      submit: false,
      attaching: false,

      //files
      files: [],
      fileInfo: [],

      //errors

      fileError: null,
      industryError: null,

      packageError: null,
      submitError: null,

      //submission
      success: null,
      response: null,
      hide: "none",
      res: [],
    };
  },

  methods: {
    todayDate() {
      var today = new Date();
      var dd = today.getDate();

      var mm = today.getMonth() + 1;
      var yyyy = today.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }

      if (mm < 10) {
        mm = "0" + mm;
      }
      return (today = yyyy + "/" + mm + "/" + dd);
    },
    print() {
      const contentHtml = this.$refs.invoice.innerHTML;
      var myWindow = window.open("", "", "width=992,height=1080");
      myWindow.document.write(contentHtml);
      myWindow.print();
    },
    remove(index) {
      this.files.splice(index, 1);
    },

    async apicall(e) {
      this.attaching = true;
      let fileinfoo = 0;
      // let counter = 0;

      let file = e.target.files[0];

      let form = new FormData();

      form.append("file", file);
      let that = this;

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
        },
        body: form,
      };
      const fileInfo = await fetch(
        "https://calm-falls-29246.herokuapp.com/http://givemetext.okfnlabs.org/tika/tika/form",
        requestOptions
      );
      async function fetchStream() {
        const reader = await fileInfo.body.getReader();
        let charsReceived = 0;
        let result;

        reader.read().then(function processText({ done, value }) {
          if (done) {
            that.files[that.files.length] = { file, contted: fileinfoo };
            that.attaching = false;
            that.fileError = null;

            console.log("Stream complete and fileinfoo is " + fileinfoo);
            return;
          }

          charsReceived += value.length;
          console.log(value);
          const chunk = value;
          let finalText = "";
          chunk.forEach((el) => {
            finalText = finalText + String.fromCharCode(el);
          });
          let words = finalText.split(" ");
          console.log(words.length);
          fileinfoo = words.length;

          return reader.read().then(processText);
        });

        console.log(result + charsReceived);
      }

      await fetchStream();

      console.log(that);
    },

    toStep1() {
      this.step1 = true;
      this.step3 = false;
    },
    toStep3() {
      if (this.files.length < 1 && this.countedWord === null) {
        this.fileError = "File or Word Count is required";
      } else {
        this.fileError = null;
      }

      if (this.industry == null || this.industry == "" || this.industry == 1) {
        this.industryError = "Industry is required.";
      } else {
        this.industryError = null;
      }
      if (this.packageID != null) {
        this.packageError = null;
      } else {
        this.packageError = "choose a package to continue";
      }

      if (
        this.fileError == null &&
        this.industryError == null &&
        this.packageError == null
      ) {
        this.step1 = false;

        this.step3 = true;
      }
    },

    async loadData() {
      await this.$store.dispatch("getDocumentTranslationPrices");
      this.documentTranslationPrices = await this.$store.getters
        .documentTranslationPrices;
      this.industry = this.documentTranslationPrices[0].id;
    },

    language(id) {
      if (id == 1) {
        return this.content.english;
      } else if (id == 2) {
        return this.content.arabic;
      } else if (id == 3) {
        return this.content.kurdish;
      }
    },
  },
  computed: {
    content() {
      this.loadData();

      return this.$store.getters.content;
    },
    invoiceTotalWords() {
      if (this.countedWord == null) {
        let sum = 0;
        for (let i = 0; i < this.filesComputed.length; i++) {
          sum += parseFloat(this.filesComputed[i].contted);
        }
        return sum;
      } else {
        return this.countedWord;
      }
    },
    address() {
      return (
        "Contact Name : " +
        this.contactName +
        ",  Phone: " +
        this.contactPhone +
        ",  Email : " +
        this.contactEmail
      );
    },
    user() {
      return this.$store.getters.user;
    },
    industryId() {
      return this.industry.id;
    },
    prices() {
      return this.industry.prices ?? {};
    },
    filesComputed() {
      return this.files;
    },
    invoiceTotal() {
      let total = 0;
      this.filesComputed.forEach((file) => {
        total += this.packagePrice * file.contted;
      });

      return total;
    },

    subtotal() {
      let total = 0;
      this.filesComputed.forEach((file) => {
        total += this.packagePrice * file.contted;
      });

      return total;
    },
  },
  created() {
    this.loadData();
  },
  components: {
    GreenSpinner,
  },
  watch: {
    setPackage: function () {
      this.packageID = this.setPackage.id;
      this.packageName = this.setPackage.tier;
      this.packagePrice = this.setPackage.price;
    },
  },
};
</script>

<style scoped>
.card {
  border-radius: 15px;
}

.packcard h5 {
  text-align: left;
}

.packprice {
  text-align: left;
}
.card p,
.card h5,
.link {
  color: #2a6049;
}

.link {
  color: #2a6049;
  font-size: 12px;
  margin-left: 10px;
  font-weight: bold;
}

p {
  margin-top: 5px;
  font-size: 15px;
}

.form-check-label {
  font-size: 15px !important;
}

.row {
  margin: 0px !important;
}

.end-align {
  text-align: end;
}
@media (max-width: 767px) {
  .left-in-sm {
    text-align: start !important;
  }
}
.wordcount {
  width: 100px;
  height: auto;
  border-radius: 25px !important;
  height: 36px;
  margin-top: 10px;
  padding: 0.375rem 0.75rem;
}
.wordcount:focus {
  background-color: #fff;
  border-color: #afa68a;
  outline: none;
  box-shadow: 0 0 0 0.25rem rgba(175, 166, 138, 0.3);
}
</style>
