<template>
                <auth-header :title="joinUsContent?.register.title" :text="joinUsContent?.register.subtitle"></auth-header>
    <div style="display: flex;">
    <button class="form-tab-btn g-btn d-flex justify-content-center align-items-center " style="z-index: 1;" @click.prevent="Client"><client color="#fff"></client>{{content.client}}</button>
    <button class="form-tab-btn w-btn d-flex justify-content-center align-items-center" style="margin-left: -36px;z-index: 2;"><translator color="#2a6049"></translator> {{content.translator}}</button></div>
    <div class="tab-form shadow">
        <form v-if="!submitted" class="row" @submit.prevent="submitForm">
             <div class=" col-md-6">
             <div class="input-group mb-3">
              <label class="small d-block"  >{{content.firstName}}</label> <div class="clearfix"></div>
              <input type="text"  class="form-control d-block" v-model="firstName"  autocomplete="off" >
             <div class="clearfix"></div>
              <div class="error-text">
                          {{ firstNameError }}
                        </div>
             </div>
          </div>  
            <div class=" col-md-6">
             <div class="input-group mb-3">
              <label class="small d-block"  >{{content.lastName}}</label> <div class="clearfix"></div>
              <input type="text"  class="form-control d-block" v-model="lastName"  autocomplete="off" >
             <div class="clearfix"></div>
              <div class="error-text">
                          {{ lastNameError }}
                        </div>
             </div>
          </div>  
             <div class=" col-md-6">
             <div class="input-group mb-3">
              <label class="small d-block"  >{{content.phone}}</label> <div class="clearfix"></div>
              <input type="tel"  class="form-control d-block" v-model="phone"   autocomplete="off" >
             <div class="clearfix"></div>
              <div class="error-text">
                          {{ phoneError }}
                        </div>
             </div>
          </div>  
            <div class=" col-md-6">
             <div class="input-group mb-3">
              <label class="small d-block"  >{{content.email}}</label> <div class="clearfix"></div>
              <input type="email"  class="form-control d-block" v-model="email"  autocomplete="off" >
             <div class="clearfix"></div>
              <div class="error-text">
                          {{ emailError }}
                        </div>
             </div>
          </div> 

                     <div class=" col-md-12">
             <div class="input-group mb-3">
              <label class="small d-block"  >{{content.whyWorkWithAro}}</label> <div class="clearfix"></div>
              <textarea type="text"  class="form-control d-block"  rows="5" v-model="reason" ></textarea>
              <div class="clearfix"></div>
              <div class="error-text">
                          {{ reasonError }}
                        </div>
             </div>
          </div>  
        <div class="col-md-12 ">
            <div class="input-group mb-3">
              <div
                        class="d-flex w-100"
                        style="justify-content: space-between"
                      >
                        <label class="small d-block" style="float: right">
                          {{content.uploadCv}}

                        </label>
                        <label
                          class="small d-block"
                          style="
                            color: #2a6049;
                            font-weight: 500;
                            cursor: pointer;
                          "
                          >{{ content.supportedFiles }}( Pdf , Word )</label
                        >
                      </div>
               
                    <div class="clearfix"></div>
                    <input
                      type="file"
                      class="form-control d-block"
                      style="font-size: 14px; padding: 2px 5px"
                      autocomplete="off"
                      accept=".docx,.pdf, .doc"
                      
                      @change="apicall"
                    />
 <div class="clearfix"></div>
                     <div class="error-text">
                          {{ fileError }}
                        </div>
                           <p v-if="file" class="alert alert-success w-100 mt-3">
                          {{ content.uploadedFile }} : <b>{{ file.name }}</b>
                        </p>
                  </div>
          </div> 
                


                
              {{response}}


                <div style="text-align: right;padding: 40px 0px;display:flex;justify-content: flex-end;">
                        <div>
                    <button type="button" class="btn" style="color: #2a6049;" @click="back">{{content.back}}</button>
                    <button type="submit"  class="form-green-btn ">{{content.apply}}</button>
                </div>
            </div>
           
        </form>
             <div class="row mt-5" v-if="submitted">
                <div v-if="!success" style="text-align: center">
                  <h5>
                    {{ content.processingOrder }}
                    <br />
                  </h5>
                  <p>
                  <GreenSpinner /></p>
                </div>
                <div v-if="success" class="w-100 alert alert-success">
                  <h3>
                    Your application submitted.
                  </h3>
                  <p class="mb-0">
                    We will go over your application and contact you shortly.
                    <router-link to="/" class="alert-link">{{
                      content.done
                    }}</router-link>
                  </p>
                </div>
                <div v-if="success == false" class="w-100 alert alert-danger">
                  <h3>  Your application was not submitted.</h3>
                  <p class="mb-0">
                   An Error Occured. Please try again.
                    <router-link to="/joinus" class="alert-link">{{
                      content.back
                    }}</router-link>
                  </p>
                </div>
              </div>
        
    </div>
    
  
</template>

<script>
import AuthHeader from '../../components/layout/AuthHeader'
import Translator from "../../components/Icons/Translator.vue"
import Client from "../../components/Icons/Client.vue"
import GreenSpinner from "../../components/ui/GreenSpinner.vue";
export default {
  data(){
    return{
      joinUsContent:null,
      firstName:null,
      lastName:null, 
      phone:null,
      email:null, 
      reason:null,
      file:null,

      firstNameError:null,
      lastNameError:null,
      phoneError:null, 
      emailError:null,
      reasonError:null, 
      fileError:null,


      success:null,
      response:null,
      submitted:false


    }
  },
    components:{
        AuthHeader,
        Translator,
        Client,
        GreenSpinner
    },
    methods:{

      inputIsValid() {

        if(this.firstName==null|| this.firstName ==''){
          this.firstNameError= this.content.required;
        }
        else{
                    this.firstNameError= null;

        }
         if(this.lastName==null|| this.lastName ==''){
          this.lastNameError= this.content.required;
        }
         else{
                    this.lastNameError= null;

        }
         if(this.phone==null|| this.phone ==''){
          this.phoneError= this.content.required;
        }
         else{
                    this.phoneError= null;

        }
             if(this.email==null|| this.email ==''){
          this.emailError= this.content.required;
        }
         else{
                    this.emailError= null;

        }
            if(this.reason==null|| this.reason ==''){
          this.reasonError= this.content.required;
        }
         else{
                    this.reasonError= null;

        }
             if(this.file==null){
          this.fileError= this.content.required;
        }
         else{
                    this.fileError= null;

        }

        if(this.fileError==null&& this.firstNameError==null&&this.lastNameError==null&&this.phoneError==null&&this.emailError==null&&this.reasonError==null){

          return true
        }
        else{
          return false
        }
        

        
      },
      async submitForm(){
       if(this.inputIsValid()){
         window.scrollTo(0, 250);
         this.submitted = true;

          let form = new FormData();
          form.append("cv", this.file);
          form.append("first_name", this.firstName.toString());
          form.append("last_name", this.lastName.toString());
          form.append("phone", this.phone.toString());
          form.append("email", this.email.toString());
          form.append("reason", this.reason.toString());

           this.response = new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open(
              "POST",
              `${
                this.$store.state.postURL + this.$store.state.apiURL
              }join-us`,
              true
            );

           
            xhr.setRequestHeader("Accept", "application/json");
          
            xhr.send(form);
            xhr.onloadend = () => {
              if (xhr.status >= 200 && xhr.status < 300) {
                resolve(xhr.response);
                this.success = true;
              } else {
                reject(
                  JSON.stringify({ status: xhr.status, response: xhr.response })
                );
                this.success = false;
              }
            };
          });

          
       }
       else{
          window.scrollTo(0, 250);
       }
      
      },

        Client(){
            this.$router.push('/signup');
        }, back(){
            this.$router.back();
        },
         async loadData() {
      await this.$store.dispatch("getAccountData");
      this.joinUsContent = await this.$store.getters.accountContent;
        },
         async apicall(e) {
     
      this.file = e.target.files[0];

     

    },
    },computed:{
  content(){
    this.loadData();
    return this.$store.getters.content;
  } 
},
created () {
    this.loadData();
  }
}
</script>