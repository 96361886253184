<template>
  <div v-if="loginContent">
    <auth-header
      :title="loginContent?.login.title"
      :text="loginContent?.login.subtitle"
    ></auth-header>

    <div class="tab-form shadow mt-5" style="border-radius: 25px !important">
      <div v-if="signInSuccess == false" class="well well-failed">
        <div>
          <h3>{{ content.failed }}</h3>
          <p>
            {{ content.loginFailed }}
          </p>
        </div>
      </div>
      <form class="row" @submit.prevent="submitForm">
        <div class="col-md-12">
          <div class="input-group mb-3">
            <label class="small d-block">{{ content.email }}</label>
            <div class="clearfix"></div>
            <input
              type="text"
              class="form-control d-block"
              autocomplete="off"
              v-model="email"
            />
            <div class="clearfix m-0"></div>
            <p v-if="emailError" class="error-text">{{ emailError }}</p>
          </div>
        </div>

        <div class="col-md-12">
          <div class="input-group mb-3">
            <label class="small d-block">{{ content.password }}</label>
            <div class="clearfix"></div>
            <input
              type="password"
              class="form-control d-block"
              autocomplete="off"
              v-model="password"
            />
            <div class="clearfix m-0"></div>
            <p v-if="passwordError" class="error-text">{{ passwordError }}</p>
          </div>
        </div>

        <div class="mt-4 d-block d-md-flex term">
          <div style="line-height: 40px" class="align-rtl-text">
            <router-link
              to="/forgetpassword"
              style="text-decoration: none; color: #2a6049"
              >{{ content.forgetPassword }}</router-link
            >
          </div>
          <div>
            <button type="button" class="btn termbtn backbtn" @click="back">
              {{ content.back }}
            </button>
            &nbsp; &nbsp;
            <button class="form-green-btn" style="overflow: hidden">
              <Spinner v-if="submitted" />
              <span v-else>
                {{ content.signIn }}
              </span>
            </button>
          </div>
        </div>

        <div
          class="mt-3 align-rtl-text"
          style="
            padding: 15px 12px;
            display: flex;
            justify-content: space-between;
            background-color: #f6f6f6;
            border-radius: 15px;
            margin-top: 15px;
          "
        >
          <div style="line-height: 40px; color: #2a6049">
            {{ content.dontHaveAccount }}
          </div>
          <div>
            <button type="button" class="form-white-btn m-0" @click="Client">
              {{ content.signUp }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <Loader v-else />
</template>


<script>
import AuthHeader from "../../components/layout/AuthHeader";
import Loader from "../../components/ui/Loading.vue";
import Spinner from "../../components/ui/Spinner.vue";

export default {
  data() {
    return {
      loginContent: null,
      email: null,
      password: null,
      submitted: false,
      response: null,
      signInSuccess: null,
      emailError: null,
      passwordError: null,
    };
  },
  components: {
    AuthHeader,
    Loader,
    Spinner,
  },
  methods: {
    validateRequired(inputed) {
      if (inputed === "" || inputed == null) {
        return this.content.required;
      } else {
        return null;
      }
    },
    Client() {
      this.$router.push("/signup");
    },
    back() {
      this.$router.back();
    },
    async loadData() {
      await this.$store.dispatch("getAccountData");
      this.loginContent = await this.$store.getters.accountContent;
    },
    async submitForm() {
      this.submitted = true;
      this.emailError = this.validateRequired(this.email);
      this.passwordError = this.validateRequired(this.password);

      if (!this.emailError && !this.passwordError) {
        this.response = await this.$store.dispatch("signIn", {
          email: this.email,
          password: this.password,
        });

        if (this.response.user && this.response.token) {
          this.submitted = false;
          this.signInSuccess = true;
          this.$store.dispatch("setAuth", {
            token: this.response.token,
            user: this.response.user,
          });

          this.$router.replace("/");
        } else if (this.response.status == "failed") {
          window.scrollTo(0, 0);
          this.signInSuccess = false;
          this.submitted = false;
        }
      } else {
        this.submitted = false;
      }
    },
  },
  computed: {
    content() {
      this.loadData();
      return this.$store.getters.content;
    },
  },
  created() {
    this.loadData();
  },
};
</script>

<style scoped>
.term {
  height: 40px;
}
@media (max-width: 992px) {
  .term {
    height: 100px;
  }
}
</style>