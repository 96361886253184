<template>
  <div
    class="
      col-md-12
      d-flex
      justify-content-space-between
      align-items-center
      mt-3
      mb-3
    "
  >
    <div class="order-view">
      <div class="Order-id">{{ content.myOrder }} #{{ orderId }}</div>
      <div>{{ content.date }} : {{ date }}</div>
      <div style="min-width: 170px;">
        {{ content.totalPrice }} : {{ price }}$

        <span
          v-if="viewPay"
          @click.prevent="onlinePayment"
          class="text-warning cursor-pointer fw-bold mx-2"
        >
          {{ content.pay }}
        </span>
      </div>
      <div class="status" :class="orderStatus">
        {{ status }}
      </div>
      <!-- <div class="buttons"> -->
      <button
        data-toggle="modal"
        data-target="#orderDetails"
        class="btn btn-secondary mx-1"
        @click="viewOrder"
      >
        View
      </button>
      <!-- <button v-if="viewPay" class="btn btn-success" @click="onlinePayment">
          Pay
        </button> -->
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
export default {
  emits: ["view"],
  props: ["orderId", "date", "price", "status", "method"],
  computed: {
    content() {
      return this.$store.getters.content;
    },
    viewPay() {
      return this.method == "online" && (this.status == "confirmed" || this.status == "complete");
    },
    orderStatus() {
      return this.status === "pending"
        ? "bg-warning"
        : this.status == "rejected"
        ? "bg-danger"
        : this.status == "confirmed"
        ? "bg-primary"
        : "btn-success";
    },
  },
  methods: {
    viewOrder() {
      this.$emit("view");
    },
    async onlinePayment() {
      try {
        const response = await this.$store.dispatch(
          "orderPayment",
          this.orderId
        );
        if (response.status == "success" && response.url) {
          window.open(response.url, "_blank");
        } else {
          alert("fail");
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.order-view {
  /* cursor: pointer; */
  display: flex;
  flex-wrap: wrap;
  line-height: 40px;
  padding: 15px 20px;
  border-radius: 15px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #d2d2d2;
}
.Order-id {
  font-weight: 600;
}
.status {
  background: #2a6049;
  padding: 0px 15px;
  border-radius: 25px;
  line-height: 25px;
  height: 25px;
  color: #fff;
  font-size: 10px;
  text-transform: uppercase;
  margin-top: 7px;
}

.buttons {
  min-width: 150px;
}
</style>