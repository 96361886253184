<template>
  <div
    v-if="accountContent"
    class="header-background"
    :style="'background: url(' + accountContent.account?.photo + ')'"
  >
    <div
      class="container-xxl flex-column mb-5 mobile-alignment"
      style="overflow: hidden; padding-bottom: 100px"
    >
      <div class="row">
        <page-title
          :title="accountContent.account?.title"
          style="margin-bottom: 80px"
        ></page-title>
      </div>
      <div
        class="row mt-5 mb-5 justify-content-center"
        style="border-radius: 15px"
      >
        <div
          class="col-12 col-md-4 col-lg-3 justify-content-between account-tab"
        >
          <div class="row justify-content-center w-100">
            <div class="col-2 col-md-12">
              <router-link to="/account/profile" class="account-tab-btn d-flex">
                <img src="/static/images/profileIcon.png" class="setting-ico" />
                <div class="account-btn-text">{{ content.profileSetting }}</div>
              </router-link>
            </div>
            <div class="col-2 col-md-12">
              <router-link
                to="/account/passwordsetting"
                class="account-tab-btn d-flex"
              >
                <img src="/static/images/PasswordIco.png" class="setting-ico" />
                <div class="account-btn-text">
                  {{ content.passwordSetting }}
                </div>
              </router-link>
            </div>
            <div class="col-2 col-md-12">
              <router-link to="/account/orders" class="account-tab-btn d-flex">
                <img src="/static/images/OrderIco.png" class="setting-ico" />
                <div class="account-btn-text">{{ content.orders }}</div>
              </router-link>
            </div>
            <div class="col-2 col-md-12">
              <router-link to="/account/billing" class="account-tab-btn d-flex">
                <img src="/static/images/Bill.png" class="setting-ico" />
                <div class="account-btn-text">{{ content.billing }}</div>
              </router-link>
            </div>
            <div class="col-2 col-md-12">
              <router-link to="/account/bundles" class="account-tab-btn d-flex">
                <img src="/static/images/bundleIco.png" class="setting-ico" />

                <div class="account-btn-text">{{ content.bundles }}</div>
              </router-link>
            </div>
            <div class="col-2 col-md-12">
              <hr class="d-none d-md-block" />
              <button
                class="btn text-danger d-flex w-100 account-tab-btn"
                type="button"
                @click="signOut"
              >
                <img src="/static/images/signoutIco.png" class="signout-ico" />

                &nbsp;
                <div class="account-btn-text">
                  {{ content.logout }}
                </div>
              </button>
            </div>
            <div class="col-2 col-md-12"></div>
          </div>
        </div>

        <div class="col-12 col-md-8 col-lg-9">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
  <Loader v-else />
</template>
<script>
import Loader from "../../components/ui/Loading.vue";
export default {
  components: {
    Loader,
  },
  computed: {
    content() {
      this.loadData();
      return this.$store.getters.content;
    },
  },
  data() {
    return {
      profile: true,
      account: false,
      orders: false,
      billing: false,
      accountContent: null,
    };
  },
  methods: {
    async loadData() {
      await this.$store.dispatch("getAccountData");
      this.accountContent = await this.$store.getters.accountContent;
    },
    async signOut() {
      await this.$store.dispatch("signOut");
      this.$store.commit("unsetAuth");
      if (!this.isAuth) {
        this.$router.replace("/");
      }
    },
  },
  created() {
    this.loadData();
  },
};
</script>
<style scoped>
.account-tab-btn {
  align-self: center;
  align-items: center;
  padding: 20px 15px;
  cursor: pointer;
  border-radius: 15px;
  font-size: 18px;
  margin-bottom: 5px;
  color: #333;
  text-decoration: none;
  text-transform: capitalize;
}
.account-tab-btn:hover {
  background: #f5f5f5 !important;
  color: #2a6049;
  border-radius: 15px;
}

.router-link-active,
.router-link-exact-active {
  color: #2a6049;
  background: #f5f5f5;
}
.account-tab {
  height: auto;
}

.row {
  border-radius: 15px;
}
.setting-ico {
  width: 30px;
  margin-right: 10px;
  align-self: center;
}
.signout-ico {
  width: 20px;
  margin-right: 0px;
  align-self: center;
}

.rtl .setting-ico {
  margin-right: 0px;
  margin-left: 10px;
}

@media (max-width: 767px) {
  .account-tab-btn {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .account-tab {
    display: flex;
    justify-content: space-between;
    margin: 10px;
    padding: 0px !important;
  }
  .setting-ico {
    margin: 0px !important;
  }
  .mobile-alignment {
    align-items: center;
  }
  .row {
    margin: 0px !important;
  }
}
</style>