import { createRouter, createWebHistory } from 'vue-router';

import store from '../store/index.js'

import clientRoutes from './clientRoutes';
import commonRoutes from './commonRoutes';
import authRoutes from './authRoutes';
import StartPage from '../pages/Start.vue';
import AuthPage from '../pages/Auth.vue';
import NotFound from '../pages/NotFound.vue';
import PaymentSuccess from '../pages/payment/Success.vue'
import PaymentFail from '../pages/payment/Fail.vue'

let router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      component: StartPage,
      children: [
        ...clientRoutes,
        ...commonRoutes,
      ]
    },
    {
      path: '/auth',
      component: AuthPage,
      children: authRoutes
    },
    {
      path: '/:notFound(.*)',
      component: NotFound
    },
    {
        path: '/payment/success/:type',
        component: PaymentSuccess,
        props: true,
        // meta: { fromBackend: true }
    },
    {
        path: '/payment/fail/:type/:statusCode/:responseCode',
        component: PaymentFail,
        props: true,
        // meta: { fromBackend: true }
    },
  ],
  scrollBehavior(from, to, savedPosition) {

    // @M: we need to configure this based on routes

    if (from.fullPath.startsWith('/account')) {
      return savedPosition;
    }
    if (savedPosition) {
      return savedPosition;
    }
    return { top: 0, left: 0 };
  }
});
router.beforeEach(function (to, _, next) {
  // console.log("to: " + to.path);
  // console.log(from);
  // console.log(to);
  // console.log(from);

  if (to.meta.requiresAuth && store.getters.isAuth) {
    next();
  }
  else if (to.meta.requiresUnAuth && store.getters.isAuth) {
    next('/');
  }
  else if (to.meta.requiresAuth && !store.getters.isAuth) {
    next('/signin');
  }
  else {
    next();
  }


});

export default router;