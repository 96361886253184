<template>
  <div v-if="aboutContent != null" class="content pt-0 pb-0">
    <div
      class="header-image-about"
      :style="'background-image: url(' + aboutContent.banner.photo + ');'"
    >
      <div class="container">
        <page-title :title="aboutContent.banner.title"></page-title>
      </div>
    </div>

    <div class="container-xxl">
      <div class="row">
        <div class="col-lg-12 mt-5">
          <p class="line-1">{{ content.whatIsAro }}</p>
        </div>
        <div class="col-lg-12">
          <div
            v-html="aboutContent.main.content"
            style="padding-top: 25px"
          ></div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row mt-5 pt-5 pb-5">
        <div class="col-12 col-md-6">
          <div class="at-icon-box">
            <div class="at-icon-box-text">
              <h4>{{ aboutContent.mini[0].title }}</h4>
              <p class="mr-3">
                {{ aboutContent.mini[0].content }}
              </p>
            </div>
            <div class="at-icon-box-icon icon-right">
              <i
                style="font-size: 100px; opacity: 0.4; position: relative"
                class="fa fa-bullseye"
              ></i>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="at-icon-box" style="background-color: #2a6049">
            <div class="at-icon-box-text">
              <h4>{{ aboutContent.mini[1].title }}</h4>
              <p>
                {{ aboutContent.mini[1].content }}
              </p>
            </div>
            <div class="at-icon-box-icon icon-right">
              <i
                style="font-size: 100px; opacity: 0.4; position: relative"
                class="fa fa-dot-circle"
              ></i>
            </div>
            <a class="link" href=""></a>
          </div>
        </div>

        <hr class="mt-5 mb-5" />
        <div class="col-lg-6" style="padding-top: 2em; padding-bottom: 80px">
          <b class="sub">
            <h2>{{ aboutContent.content[0].title }}</h2>
            <span></span>
          </b>
          <div
            style="max-width: 550px; line-height: 25px"
            v-html="aboutContent.content[0].content"
          ></div>
          <br />
          <h5>
            {{ content.WantToWorkWithUs }}
            <a class="link" href="/">{{ content.joinUs }}</a>
          </h5>
        </div>
        <div class="col-lg-6" style="padding-top: 10px">
          <img
            :src="aboutContent.content[0].photo"
            class="img-fluid"
            style="border-radius: 20px"
          />
        </div>
      </div>
    </div>
    <div
      class="img-our-value"
      :style="
        'background-image: url(' +
        aboutContent.content[1].photo +
        ');min-height: 600px; width: 100%;background-size: cover;background-position: right; margin-top: 0; background-attachment: fixed;'
      "
    >
      <div class="container-xxl">
        <div class="row">
          <div class="col-lg-7"></div>
          <div class="col-lg-4 white-bg">
            <b class="sub">
              <h2 style="padding-top: 150px; color: #363636 !important">
                {{ aboutContent.content[1].title }}
              </h2>
              <span></span>
            </b>
            <p style="padding-top: 25px">
              {{ aboutContent.content[1].content }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div style="background-color: #2a6049; overflow: hidden">
      <div class="container-xxl mobile-alignment">
        <h5
          style="
            color: #fff;
            line-height: 120px;
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            flex-wrap: wrap;
          "
        >
          {{ content.haveQuestions }}

          <button
            class="btn btn-outline-light large-btn"
            style="float: right; margin-top: 35px"
            @click="$router.push('/contact')"
          >
            {{ content.contactUsU }}
          </button>
        </h5>
      </div>
    </div>
  </div>

  <loader v-else></loader>
</template>
<script>
import Loader from "../../components/ui/Loading.vue";

export default {
  components: {
    Loader,
  },
  data() {
    return {
      aboutContent: null,
    };
  },
  methods: {
    async loadData() {
      await this.$store.dispatch("getData");
      this.aboutContent = await this.$store.getters.aboutContent;
    },
  },
  created() {
    this.loadData();
  },

  computed: {
    content() {
      this.loadData();
      return this.$store.getters.content;
    },
  },
};
</script>

<style scoped>
@media (max-width: 992px) {
  .white-bg {
    background: #fff !important;
    margin-top: 350px;
    padding-bottom: 50px;
  }
  .white-bg h2 {
    padding-top: 50px !important;
  }
  .img-our-value {
    background-position: left !important;
    background-size: cover !important;
    background-repeat: no-repeat;
    background-attachment: fixed !important;
  }
}
</style>